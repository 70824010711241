import React, { Fragment } from "react";
import { ModalCard, Pill } from "@darktrace/ui-components";
import styled from "styled-components";
import { fetchUserOwnRoles } from "../../logic/api.js";
import { useSelector } from "react-redux";
import { useQueries } from "@tanstack/react-query";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    .dt-ui-card__contents {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }

    .row {
      span {
        font-weight: 600;
      }

      .role-container {
        width: 100%;
        display: flex;
        gap: 0.8rem;
        flex-wrap: wrap;
        padding: 0.8rem 0;
      }
    }
  }
`;

export function UserOverviewModal({ open, user = {}, onClose = () => {} }) {
  // clients
  const clients = useSelector((state) => state.app.clients);

  const { data: clientRoles, isLoading } = useQueries({
    queries: clients.map((client) => ({
      queryFn: async () => fetchUserOwnRoles({ clientId: client.id }),
      queryKey: ["user-profile-roles", { clientId: client.id }],
    })),
    combine: (results) => {
      return {
        data: results.map((result, index) => ({ client: clients[index], roles: result.data })),
        isLoading: results.some((result) => result.isLoading),
      };
    },
  });

  return (
    <StyledModalCard title={t(`Profile: {0}`, user.name)} open={open} onClose={onClose}>
      <p className="row">
        <span>{t(`Name`)}: </span>
        {user.name}
      </p>
      <p className="row">
        <span>{t(`Email`)}: </span>
        {user.email}
      </p>
      <div className="row">
        <span>{t(`Roles`)}:</span>
        {isLoading ? (
          <span>{t(`Loading...`)}</span>
        ) : (
          clientRoles.map(({ client, roles }) => (
            <Fragment key={client.id}>
              <div className="client-container">{client.label}</div>
              <div className="role-container">
                {roles?.map((role, index) => (
                  <Pill key={index}>{role.name}</Pill>
                ))}
              </div>
            </Fragment>
          ))
        )}
      </div>
    </StyledModalCard>
  );
}
