import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "../components/login/Login.jsx";
import { useSelector } from "react-redux";
import { useInitialiseApp, useMutliClientUserAccessFlags } from "./hooks.js";
import { getApplicationPages } from "./util.js";
import { App } from "../components/App.jsx";
import { ErrorPage } from "../components/ErrorPage.jsx";
import { Toaster } from "@darktrace/ui-components";
import { useQueryClient } from "@tanstack/react-query";

export function AuthenticatedRouter() {
  const initialiseApp = useInitialiseApp();
  const isUserVerified = useSelector((state) => state.app.isUserVerified);
  const isLoadingUserVerificationStatus = isUserVerified === undefined;

  const { data: multiClientUserAccessFlags = [], isLoading: isLoadingMultiClientUserAccessFlags } = useMutliClientUserAccessFlags();

  const uniqueFlags = multiClientUserAccessFlags
    .map(({ flags }) => flags)
    .reduce((prevUniqueFlags, flags = []) => {
      flags.forEach((flag) => {
        if (!prevUniqueFlags.includes(flag)) prevUniqueFlags.push(flag);
      });
      return prevUniqueFlags;
    }, []);

  const appPages = isLoadingMultiClientUserAccessFlags ? [] : getApplicationPages({ userAccessFlags: uniqueFlags });
  const toasts = useSelector((state) => state.app.toasts);

  const showTimeoutOverlay = useSelector((state) => state.app.showTimeoutOverlay);
  const queryClient = useQueryClient();

  // initial requests
  useEffect(() => {
    initialiseApp();
  }, []);

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        refetchOnWindowFocus: !showTimeoutOverlay,
        retry: showTimeoutOverlay ? false : 3,
      },
    });
  }, [showTimeoutOverlay]);

  return (
    <>
      <Routes>
        <Route path="/login" element={isUserVerified ? <Navigate replace to="/dashboard" /> : <Login />} />
        <Route path="/400" name="Bad Request" element={<ErrorPage errorCode={400} />} />
        <Route path="/403" name="Forbidden" element={<ErrorPage errorCode={403} />} />
        <Route path="/404" name="Not Found" element={<ErrorPage errorCode={404} />} />
        <Route path="/500" name="Something went wrong" element={<ErrorPage errorCode={500} />} />

        {
          <Route
            path="/"
            element={isLoadingUserVerificationStatus ? null : isUserVerified ? <App pages={appPages} /> : <Navigate replace to="/login" />}
          >
            {appPages.map((page) => (
              <Route
                key={page.id}
                path={page.id}
                element={
                  page.available === undefined ? null : page.available ? (
                    page.element
                  ) : isLoadingMultiClientUserAccessFlags ? null : (
                    <Navigate replace to="/dashboard" />
                  )
                }
              />
            ))}
          </Route>
        }

        <Route
          path="*"
          name="Page not found"
          element={
            isLoadingUserVerificationStatus ? null : isUserVerified ? (
              isLoadingMultiClientUserAccessFlags ? null : (
                <ErrorPage errorCode={404} />
              )
            ) : (
              <Navigate replace to="/login" />
            )
          }
        />
      </Routes>
      <Toaster toasts={toasts} />
    </>
  );
}
