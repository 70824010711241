import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { IconButton, LoadingContainer, Pill, Table, TableWrapper, Tooltip } from "@darktrace/ui-components";
import { t } from "../../logic/translator.js";
import { UsersTableActionBar } from "./UsersTableActionBar.jsx";
import { useUserProfileInfo } from "../../logic/api.js";
import { UserOptionsButton } from "./UserOptionsButton.jsx";
import { Status } from "./Status.jsx";
import { UsersFilters } from "./UsersFilters.jsx";
import { ProductAccess } from "./ProductAccess.jsx";
import { Username } from "../shared/Username.jsx";
import { useActiveClientId } from "../../logic/hooks.js";
import { Tenants } from "./Tenants.jsx";
import { AssignRolesModal } from "./AssignRolesModal.jsx";
import { useSelector } from "react-redux";

const StyledUsersTableCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 52rem;
  max-height: 100%;

  .table-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .dt-ui-loading-container {
      flex-grow: 1;
      flex-basis: 0;
      overflow: hidden;

      .dt-ui-table__wrapper {
        height: 100%;
      }

      .dt-ui-table {
        :is(td, th):is(:nth-child(1), :nth-child(2), :nth-child(3), :nth-child(6)) {
          width: 0;
        }

        .dt-ui-table__column-header {
          white-space: nowrap;
        }

        th.roles-col,
        th.product-access-col {
          /* width: 100%; */
        }
        .roles,
        .product-access {
          display: flex;
          /* flex-wrap: wrap; */
          gap: 0.4rem;
        }

        tbody .dt-ui-table__td {
          vertical-align: middle;

          &.roles-col,
          &.product-access {
            vertical-align: top;
          }
        }

        tbody .dt-ui-table__td:not(:first-child) {
          padding: 0.8rem 1.2rem;
        }
        td:last-child,
        th:last-child {
          width: 0;
          padding: 1.2rem;

          .dt-ui-table__td-content {
            height: 100%;
            display: grid;
            place-items: center;
          }
        }
      }
    }

    .no-users {
      padding-block: 1.2rem;
      text-align: center;
    }
  }
`;

function generateUsersTableRows({
  activeClientId,
  users,
  clients,
  activeClientAvailableRoles,
  parentClientAvailableRoles,
  userProfileInfo,
  parentClientName,
}) {
  return users.map((rowData, i) => {
    const {
      id,
      clientId,
      jwksEnabled,
      registrationStatus,
      expiry,
      clients: userClients,
      name,
      email,
      roles = [],
      childRoles = [],
      parentRoles = [],
    } = rowData;
    const isUserFromParentClient = activeClientId !== clientId;
    const isYou = userProfileInfo?.id && userProfileInfo.id === id;

    const userAvailableRoles = isUserFromParentClient ? parentClientAvailableRoles : activeClientAvailableRoles;

    return {
      rowId: i,
      data: [
        // name
        <Username key="username" username={name} email={email} isYou={isYou} />,

        // tenants
        ...(clients?.length > 1
          ? [
              <Tenants
                key="tenants"
                userId={id}
                clientId={clientId}
                username={name}
                email={email}
                isYou={isYou}
                userClients={userClients}
              />,
            ]
          : []),

        // registration status
        <Status
          key="registration"
          registrationStatus={registrationStatus}
          expiry={expiry}
          jwksEnabled={jwksEnabled}
          fromParentClient={isUserFromParentClient}
          parentClientName={parentClientName}
        />,

        // roles
        <Roles
          key="roles"
          availableRoles={userAvailableRoles}
          userId={id}
          name={name}
          email={email}
          clientId={clientId}
          isYou={isYou}
          roles={roles}
          childRoles={childRoles}
          parentRoles={parentRoles}
          isUserFromParentClient={isUserFromParentClient}
        />,

        // product access
        <ProductAccess key="product-access" userId={id} name={name} email={email} clientId={clientId} isYou={isYou} />,

        // options
        <UserOptionsButton
          key="options-button"
          name={name}
          email={email}
          id={id}
          clientId={clientId}
          roles={roles}
          registrationStatus={registrationStatus}
          jwksEnabled={jwksEnabled}
          availableRoles={userAvailableRoles}
          isUserFromParentClient={isUserFromParentClient}
          isYou={isYou}
        />,
      ],
    };
  });
}

export function UsersTableCard({
  users = [],
  orderDir,
  groupedAvailableRoles,
  activeClientAvailableRoles,
  parentClientAvailableRoles,
  currentPage,
  pageSize,
  searchValue,
  selectedStatusFilterIds,
  selectedRoleFilterIds,
  isLoading,
  numTotalUsers = 0,
  updateQueryParams,
  parentClientName,
}) {
  const { data = {} } = useUserProfileInfo();
  const userProfileInfo = data.user;
  const activeClientId = useActiveClientId();

  const clients = useSelector((state) => state.app.clients);
  const allAvailableRoles = [...activeClientAvailableRoles, ...parentClientAvailableRoles];

  const tableHeaders = [
    {
      title: t(`User`),
      sortable: true,
      sortFn: null,
      onClick: () => updateQueryParams({ orderDir: orderDir === "asc" ? "desc" : "asc" }),
      sortDirection: orderDir,
    },
    ...(clients?.length > 1 ? [{ title: t(`Tenants`), sortable: false }] : []),
    { title: t(`Registration Status`), sortable: false },
    { title: t(`ActiveAI Portal Roles`), sortable: false, className: "roles-col" },
    { title: t(`Product Access`), sortable: false, className: "product-access-col" },
    { title: "", sortable: false },
  ];

  const tableRows = useMemo(
    () =>
      generateUsersTableRows({
        users,
        clients,
        activeClientId,
        activeClientAvailableRoles,
        parentClientAvailableRoles,
        userProfileInfo,
        parentClientName,
      }),
    [JSON.stringify(users)],
  );

  return (
    <>
      <StyledUsersTableCard>
        <UsersFilters
          groupedAvailableRoles={groupedAvailableRoles}
          searchValue={searchValue}
          updateQueryParams={updateQueryParams}
          selectedStatusFilterIds={selectedStatusFilterIds}
          selectedRoleFilterIds={selectedRoleFilterIds}
          availableRoles={allAvailableRoles}
        />
        <div className="table-area">
          <UsersTableActionBar
            isLoading={isLoading}
            currentPage={currentPage}
            numTotalUsers={numTotalUsers}
            pageSize={pageSize}
            searchValue={searchValue}
            updateQueryParams={updateQueryParams}
            parentClientAvailableRoles={parentClientAvailableRoles}
          />
          <LoadingContainer loading={isLoading}>
            <TableWrapper>
              <Table small headers={tableHeaders} rows={tableRows} style={{ width: "100%" }} />
            </TableWrapper>
          </LoadingContainer>
          {!tableRows?.length && (
            <div className="no-users">
              {isLoading ? t(`Loading users`) + "..." : searchValue ? t(`No users to matching search`) : t(`No users to display`)}{" "}
            </div>
          )}
        </div>
      </StyledUsersTableCard>
    </>
  );
}

const StyledRoles = styled.div`
  display: flex;
  gap: 0.8rem;
`;

function Roles({ userId, email, name, roles, childRoles, parentRoles, clientId, isYou, isUserFromParentClient }) {
  const [isAssignRolesModalOpen, setIsAssignRolesModalOpen] = useState(false);
  const user = { id: userId, email, name, roles, childRoles, parentRoles, clientId };

  const rolesToShow = isUserFromParentClient ? childRoles : roles;

  return (
    <StyledRoles>
      <Tooltip text={t(`Assign ActiveAI Portal Roles`)}>
        <IconButton
          className="plus-btn"
          size="small"
          icon="plus"
          variant="secondary"
          onClick={() => setIsAssignRolesModalOpen((prev) => !prev)}
        />
      </Tooltip>

      <div className="roles">
        {rolesToShow.map((role, index) => (
          <Pill key={index}>{role.name}</Pill>
        ))}
      </div>

      {isAssignRolesModalOpen && (
        <AssignRolesModal user={user} isYou={isYou} open={isAssignRolesModalOpen} onClose={() => setIsAssignRolesModalOpen(false)} />
      )}
    </StyledRoles>
  );
}
