import React from "react";
import styled from "styled-components";
import { Card, CollapsableCard, Dropdown, GhostElement, IconButton, Tile, TileContainer } from "@darktrace/ui-components";
import { ProductTile } from "./ProductTile.jsx";
import { useMyProductAccessInstancesStream, useProductAccessInstances, useUserAccessFlags } from "../../logic/api.js";
import { useActiveClientId, useQueryParams } from "../../logic/hooks.js";
import { useDispatch, useSelector } from "react-redux";
import { setIsSidepanelCollapsed } from "../../logic/store.js";

const StyledDashboard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  gap: 2.4rem;
  max-height: calc(100vh - var(--header-height));

  .title-section {
    background-color: var(--dt-ui-field-bg);
    padding: 2.4rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border-radius: 0.4rem;

    .bold {
      font-weight: 700;
    }

    .name {
      font-size: 2.8rem;
      line-height: 1;
      font-weight: 500;
    }

    .tm {
      vertical-align: super;
      font-size: 1rem;
    }
  }

  .main-section {
    display: flex;
    flex-grow: 1;
    height: 0;
    gap: 2rem;
    align-items: stretch;

    .content {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .inner-card {
        width: 36.8rem;

        .tenant-card-contents {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 0.8rem;

          .dt-ui-dropdown-button:disabled {
            opacity: 100%;
            cursor: default;

            &:after {
              content: none;
            }
          }
        }

        .deployment-tile {
          .dt-ui-tile__header {
            padding: 0.8rem 1.2rem;
          }
        }
      }
    }
  }

  .products-card {
    flex: 1;
    /* height: 0; */

    .dt-ui-card__title {
      padding: 1.2rem;
    }

    .products {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      .product-section {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
        gap: 1.6rem;
        overflow: auto;

        .full-span {
          grid-column: span 2;
        }
      }
    }
  }

  .instances-card {
    .instances {
      .instance-statuses {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        .instance-details {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;

          .instance-name {
          }
          .instance-status {
          }
          .status-type {
            font-weight: 700;
          }
        }
      }
    }
  }
`;

export function Dashboard() {
  const dispatch = useDispatch();
  const activeClientId = useActiveClientId();
  const _clients = useSelector((state) => state.app.clients);
  const clients = _clients.map((client) => ({ id: client.id, label: client.label, selected: client.id === activeClientId }));
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const defaultClientLabel = _clients.find((client) => client.id === defaultClientId)?.label;
  const showTimeoutOverlay = useSelector((state) => state.app.showTimeoutOverlay);

  const { updateQueryParams } = useQueryParams({ defaultQueryParams: { clientId: defaultClientId } });

  const { data: availableInstancesForUser, isLoading: isStreamingProducts } = useMyProductAccessInstancesStream({
    clientId: activeClientId,
    enabled: true,
  });

  const instancesForSelectedDeployment = availableInstancesForUser.map((instance) => ({
    ...instance,
    serviceId: instance.serviceId ?? instance.instanceId, // some instances do not have an associated serviceId
  })); // TODO: filter to only instances for selected deployment
  const servicesForSelectedDeployment = instancesForSelectedDeployment.reduce((prevServices, currentInstance) => {
    const service = prevServices.find((prevService) => prevService.serviceId === currentInstance.serviceId);
    if (service) service.instances.push(currentInstance);
    else {
      const serviceLabel = currentInstance.serviceName ?? currentInstance.instanceName;
      prevServices.push({
        serviceId: currentInstance.serviceId,
        serviceType: currentInstance.serviceType,
        serviceLabel,
        instances: [currentInstance],
      });
    }

    return prevServices;
  }, []);

  // TODO: remove
  const deployments = [
    // { id: "test-id-1", label: "Deployment 1" },
    // { id: "test-id-2", label: "Deployment 2" },
  ];
  const hasMultipleDeployments = deployments.length > 1;
  const hasMultipleClients = clients.length > 1;

  const isSidepanelCollapsed = useSelector((state) => state.app.isSidepanelCollapsed);

  const { data: userAccessFlags = [] } = useUserAccessFlags({ clientId: activeClientId });
  const canUserSeeAllInstances = userAccessFlags.includes("product-access-management");

  const { data: accessInstances = [] } = useProductAccessInstances({
    clientId: activeClientId,
    enabled: canUserSeeAllInstances,
  });

  const instancesThatAreDown = canUserSeeAllInstances
    ? accessInstances
        .filter((instance) => instance.state !== "UP")
        .filter((instance) => instance.type === "sabre-threat-visualiser-product-access") // TODO: this should not need to filter by type if API returns a state for all instances
    : null;

  return (
    <StyledDashboard>
      <div className="title-section">
        <span className="name" data-test-id="title-text">
          <span className="bold">{t(`Darktrace`)}</span> {t(`ActiveAI Portal`)}
          <span className="tm">{t(`TM`)}</span>
        </span>
      </div>

      <div className="main-section">
        {(hasMultipleDeployments || hasMultipleClients) && (
          <CollapsableCard
            title={t(`ActiveAI Portal`)}
            width={"40rem"}
            style={{ flexShrink: "0" }}
            container={"strong"}
            controlled
            collapsed={isSidepanelCollapsed}
            onClickCollapsedToggle={() => dispatch(setIsSidepanelCollapsed(!isSidepanelCollapsed))}
          >
            <div className="content">
              {isSidepanelCollapsed ? (
                <Tile
                  className="collapsed-filter-tile"
                  icon="buildings"
                  onClick={() => dispatch(setIsSidepanelCollapsed(false))}
                  noColorBar
                />
              ) : (
                <Card className="inner-card" title={t(`Tenant`)} icon={"buildings"}>
                  <div className="tenant-card-contents">
                    <Dropdown displayValue={defaultClientLabel} disabled />
                    {clients.length > 1 && (
                      <Dropdown
                        label={`${t(`Your Products for`)}:`}
                        items={clients}
                        onSelect={(id) => updateQueryParams({ clientId: id })}
                        hasSearch
                      />
                    )}
                  </div>
                </Card>
              )}

              {hasMultipleDeployments &&
                (isSidepanelCollapsed ? (
                  <Tile
                    className="collapsed-filter-tile"
                    icon="boxes-stacked"
                    onClick={() => dispatch(setIsSidepanelCollapsed(false))}
                    noColorBar
                  />
                ) : (
                  <Card className="inner-card" icon="boxes-stacked" title={t(`Deployment`)}>
                    <TileContainer>
                      <Tile
                        className="deployment-tile"
                        noColorBar
                        header={t(`All Deployments`)}
                        headerRight={<IconButton icon="arrow-right" variant="tertiary" />}
                        active
                        onClick={() => {}}
                      />
                      {deployments.map((deployment) => {
                        // const quickFilterId = `role-${role.id}`;
                        return (
                          <Tile
                            key={deployment.id}
                            className="deployment-tile"
                            noColorBar
                            header={deployment.label}
                            headerRight={<IconButton icon="arrow-right" variant="tertiary" />}
                            onClick={() => {}}
                          />
                        );
                      })}
                    </TileContainer>
                  </Card>
                ))}
            </div>
          </CollapsableCard>
        )}

        <Card className="products-card" title={"Product List"} container>
          <div className="products">
            <div className="product-section your-products">
              {servicesForSelectedDeployment.length > 0 || isStreamingProducts ? (
                <>
                  {servicesForSelectedDeployment.map((product, index) => {
                    return <ProductTile key={index} {...product} />;
                  })}
                  {isStreamingProducts && (
                    <GhostElement style={{ aspectRatio: "5 / 3" }}>
                      <ProductTile />
                    </GhostElement>
                  )}
                </>
              ) : (
                !showTimeoutOverlay && (
                  <span className="full-span">
                    {t(`You don't have permission to access any products. Please contact your administrator`)}.
                  </span>
                )
              )}
            </div>
          </div>
        </Card>

        {canUserSeeAllInstances && instancesThatAreDown.length > 0 && (
          <Card className="instances-card" title={"Product Connection Status Information"} container>
            <div className="instances">
              <div className="instance-statuses">
                {instancesThatAreDown.map((instance) => {
                  return (
                    <div className="instance-details" key={instance.instanceId}>
                      <span className="instance-name">{instance.displayName}:</span>
                      <span className="instance-status">
                        {t(`Status`)} <span className="status-type">{instance.state ?? t(`Unknown`)}</span>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </Card>
        )}
      </div>

      {!isStreamingProducts && clients.length > 1 && (
        <span>
          {t(`Note`)}:{" "}
          {availableInstancesForUser.length > 0
            ? t(`You may be able to access additional Darktrace products in the other`)
            : t(`You may be able to access Darktrace products in the other`)}{" "}
          {clients.length === 2 ? t(`tenant you have access to`) : t(`tenants you have access to`)}.
        </span>
      )}
    </StyledDashboard>
  );
}
