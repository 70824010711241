import React, { useState } from "react";
import styled from "styled-components";
import { ModalCard, useDebouncedEffect, DropdownDropper } from "@darktrace/ui-components";
import { useParentClientUsers } from "../../logic/hooks.js";
import { registrationStatuses } from "../../logic/util.js";
import { useUserProfileInfo } from "../../logic/api.js";
import { AssignRolesModal } from "./AssignRolesModal.jsx";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    display: flex;
    flex-direction: column;

    .dt-ui-card__contents {
      flex-grow: 1;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .content {
        padding-bottom: 0.4rem;
      }

      .dt-ui-dropdown {
        width: 100%;
        border: none;
        border-radius: 0;
        box-shadow: none;

        .dt-ui-dropdown__description {
          margin: 0;
        }
      }
    }
  }
`;

export function AddExistingUserModal({ open, onClose, activeClientId, defaultClientLabel, isViewingChildClient }) {
  const modalRoot = document.querySelector("#modal-root");

  const [parentUsersSearchQuery, setParentUsersSearchQuery] = useState("");
  const [debouncedParentUsersSearchQuery, setDebouncedParentUsersSearchQuery] = useState("");

  useDebouncedEffect(() => setDebouncedParentUsersSearchQuery(parentUsersSearchQuery), 1000, [parentUsersSearchQuery]);

  const { users: parentClientUsers, isLoading } = useParentClientUsers({
    clientId: activeClientId,
    page: 1, // TODO: check required?
    search: debouncedParentUsersSearchQuery,
    registrationStatus: nonDisabledStatusIds,
    roles: ["none"],
    disabled: !isViewingChildClient || !open,
  });

  const [selectedUser, setSelectedUser] = useState(null);
  const isAddExistingUserModalOpen = open && !selectedUser;
  const isEditRolesModalOpen = open && selectedUser;
  const nonDisabledStatusIds = Object.keys(registrationStatuses).filter((statusId) => statusId !== "disabled");

  const usersDropdownItems = parentClientUsers
    .filter((user) => user.childRoles.length === 0) // TODO: should be doable on the backend by filtering on roles
    .map((user) => ({
      id: user.id,
      label: user.name,
      description: user.email,
      selected: selectedUser?.id === user.id,
    }));

  function handleSelectUser(userId) {
    setSelectedUser(parentClientUsers.find((user) => user.id === userId));
  }

  const { data: userProfileInfo } = useUserProfileInfo();

  return (
    // TODO: add user from "parent client name"
    <>
      <StyledModalCard
        icon="user-plus"
        title={t(`Add User from {0}`, defaultClientLabel)}
        modalRoot={modalRoot}
        open={isAddExistingUserModalOpen}
        onClose={onClose}
        closeOnClickBackdrop={false}
      >
        <div className="content">
          <DropdownDropper
            usePopper={false}
            hasSearch
            customSearchFiltering
            items={usersDropdownItems}
            onSelect={handleSelectUser}
            searchValue={parentUsersSearchQuery}
            onChangeSearch={setParentUsersSearchQuery}
            hideSelectAll
            noItemsText={isLoading ? t(`Loading...`) : parentUsersSearchQuery ? t(`No users matching search`) : t(`No users`)}
          />
        </div>
      </StyledModalCard>

      <AssignRolesModal
        open={isEditRolesModalOpen}
        onClose={() => {
          onClose();
          setTimeout(() => setSelectedUser(null), 1000);
        }}
        user={selectedUser}
        isYou={userProfileInfo?.user.id === selectedUser?.id}
      />
    </>
  );
}
