import React from "react";
import styled from "styled-components";
import { GhostElement, ModalCard } from "@darktrace/ui-components";
import { usePTNAlertsComponents } from "../../../logic/api";
import { useSelector } from "react-redux";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    width: 50vw;
    min-width: 45rem;
    display: flex;
    flex-direction: column;
    height: 60vh;
    .dt-ui-card__contents {
      .filters {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.2rem;
        padding-bottom: 1.2rem;
      }
      .dt-ui-chat__messages {
        .dt-ui-chat__bubble {
          background-color: var(--dt-ui-button-secondary-active-bg);
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
`;

export function ALertDataModal({ onClose = () => {}, open, breachId, ...attrs }) {
  const defaultClientId = useSelector((state) => state.app.defaultClientId);

  const modalRoot = document.querySelector("#modal-root");

  const { data: components, isLoading, isError, isSuccess } = usePTNAlertsComponents({ breachId, clientId: defaultClientId });

  function handleClose() {
    onClose();
  }

  return (
    <StyledModalCard modalRoot={modalRoot} onClose={handleClose} open={open} title={t("Alert Data")} {...attrs}>
      {(() => {
        if (isLoading) {
          return <GhostElement />;
        }

        if (isError) {
          return <div>Failed to load intel.</div>;
        }
        if (isSuccess) {
          if (!components?.length) {
            return <div>No data to show.</div>;
          }

          return;
        }

        return null;
      })()}
    </StyledModalCard>
  );
}
