import { Button, DropdownDropper, Popup } from "@darktrace/ui-components";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  useCreateParentClientSabreUserMutation,
  useCreateSabreUserMutation,
  useLinkUserMutation,
  useProductUsers,
} from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { useSelector } from "react-redux";

const StyledLinkUserPopup = styled(Popup)`
  .dt-ui-popup__contents {
    padding: 0;
  }

  .dt-ui-dropdown {
    box-shadow: none;
    border-radius: 0;
    border: none;
    width: 100%;

    .create-new-user-btn {
      width: 100%;
    }
  }

  .action-buttons {
    padding: 1.2rem 1.6rem;
    border-top: 1px solid var(--dt-ui-card-border);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem;

    > * {
      width: 100%;
    }
  }
`;

export function LinkUserButton({ instanceId, userId, email, userClientId }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { data = {}, isLoading } = useProductUsers({ instanceId, enabled: isPopupOpen });
  const users = data.users ?? [];
  const unlinkedUsers = data.unlinkedUsers ?? [];
  const userAlreadyExists = users.some((user) => user.linkedName === email) || unlinkedUsers.includes(email);

  const [selectedSabreUsername, setSelectedSabreUsername] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const dropdownTriggerRef = useRef(null);

  const activeClientId = useActiveClientId();
  const defaultClientId = useSelector((state) => state.app.defaultClientId);

  const dropdownItems = unlinkedUsers
    .map((sabreUsername) => ({
      id: sabreUsername,
      label: sabreUsername,
      selected: selectedSabreUsername === sabreUsername,
    }))
    .filter((item) => item.label.includes(searchValue));

  const { mutate: createSabreUserMutation } = useCreateSabreUserMutation({ userId, instanceId, groups: [] });
  const { mutate: createParentClientSabreUserMutation } = useCreateParentClientSabreUserMutation({ userId, instanceId, groups: [] });
  const { mutate: linkUserMutation } = useLinkUserMutation({
    instanceId,
    userId,
    sabreUsername: selectedSabreUsername,
  });

  function createSabreUser() {
    const isUserFromParentClient = userClientId !== activeClientId && userClientId === defaultClientId;
    if (isUserFromParentClient) createParentClientSabreUserMutation();
    else createSabreUserMutation();
  }
  return (
    <StyledLinkUserPopup
      title="Link User"
      placement="right"
      content={
        <div>
          <DropdownDropper
            usePopper={false}
            noItemsText={t(`A user with this user's username already exists on this instance and is linked to another user.`)}
            items={[
              ...(isLoading || userAlreadyExists
                ? []
                : [
                    {
                      id: `create-new-user-${instanceId}-${userId}`,
                      label: (
                        <Button
                          className="create-new-user-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            createSabreUser();
                          }}
                        >
                          Create New User
                        </Button>
                      ),
                    },
                  ]),
              ...(isLoading ? [{ id: `loading-${instanceId}-${userId}`, label: t(`Loading...`), disabled: true }] : []),
              ...dropdownItems,
            ]}
            hasSearch
            customSearchFiltering
            searchValue={searchValue}
            onChangeSearch={setSearchValue}
            onSelect={(id) => {
              if (id === `create-new-user-${instanceId}-${userId}` || id === `loading-${instanceId}-${userId}`) return;

              setSelectedSabreUsername((prev) => (id === prev ? null : id));
              setIsPopupOpen(false);
            }}
          />
          <div className="action-buttons">
            <Button size="small" variant="secondary" onClick={() => setIsPopupOpen(false)}>
              {t(`Cancel`)}
            </Button>

            <Button size="small" onClick={() => linkUserMutation()} disabled={!selectedSabreUsername}>
              {t(`Link User`)}
            </Button>
          </div>
        </div>
      }
    >
      <Button ref={dropdownTriggerRef} icon="link" size="small" variant="secondary" onClick={() => setIsPopupOpen((prev) => !prev)}>
        {t(`User Not Linked`)}
      </Button>
    </StyledLinkUserPopup>
  );
}
