import { IconButton, ModalCard, Table, TableWrapper } from "@darktrace/ui-components";
import React from "react";
import styled from "styled-components";
import { useProductLoginMutation, useUserProfileInfo } from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { UserInfoSubheader } from "../user-management/UserInfoSubheader.jsx";

const StyledProductLoginModal = styled(ModalCard)`
  .dt-ui-card {
    overflow: hidden;

    .dt-ui-card__contents {
      padding: 0;

      .dt-ui-table {
        tbody td:last-child {
          width: 0;
          padding: 0.8rem;
        }

        .instance-info {
          display: flex;
          flex-direction: column;

          .bold {
            font-weight: 500;
          }
        }
      }
    }
  }
`;

export function ProductLoginModal({ open, onClose, serviceId, serviceLabel, instances = [] }) {
  const { data: userProfileInfo } = useUserProfileInfo();
  const { email, name } = userProfileInfo?.user ?? {};
  const activeClientId = useActiveClientId();

  const { mutate: loginMutation, isPending } = useProductLoginMutation({ clientId: activeClientId, serviceId });

  const tableData = instances.map((instance) => [
    // Instance Info
    <div key="instance-info" className="instance-info">
      <span className="bold">{serviceLabel}</span>
      <span>{instance.instanceName}</span>
    </div>,

    // Login button
    <IconButton
      key="login-btn"
      onClick={() => loginMutation({ instanceId: instance.instanceId })}
      variant="secondary"
      icon="eye"
      disabled={isPending}
    />,
  ]);

  return (
    <StyledProductLoginModal
      open={open}
      onClose={onClose}
      title={`Product Login / ${serviceLabel}`}
      subheaders={[{ size: "large", component: <UserInfoSubheader email={email} name={name} /> }]}
    >
      <TableWrapper>
        <Table small headers={[{ title: "Instance" }, { title: "" }]} data={tableData} />
      </TableWrapper>
    </StyledProductLoginModal>
  );
}
