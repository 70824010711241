import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Accordion, Button, Input, Toast, useColorTheme, useDebounce, useDebouncedEffect } from "@darktrace/ui-components";
import dtImageDark from "../../../public/dt-assets/logo/0.5x/dt-logo-black.png";
import dtImageLight from "../../../public/dt-assets/logo/0.5x/dt-logo-grey.png";

import { useQueryParams } from "../../logic/hooks.js";
import loginBgDark from "../../../public/images/bkgnd-polar-dark-X.jpg";
import loginBgLight from "../../../public/images/bkgnd-polar-light-X.jpg";

import { z } from "zod";
import { lookupEmailAddress } from "../../logic/api.js";
import { useSearchParams } from "react-router-dom";

const StyledLogin = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  position: relative;
  background-color: var(--dt-ui-page-bg);
  background-image: url(${(props) => props.$loginBg});
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: black;
    opacity: 0.05;
    z-index: 1;
  }

  .login-box {
    position: relative;
    z-index: 2;
    width: min(44rem, 90%);
    min-height: 20rem;
    border-radius: 0.4rem;
    background-color: var(--dt-ui-card-bg);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;

      border-bottom: 1px solid var(--dt-ui-field-border);

      img.dt-ui-logo {
        width: 20rem;
        padding-block: 3.6rem;
      }
    }

    .credentials {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-block: 3.6rem;

      form.original {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: min(40rem, 80%);
      }

      .field-input {
        display: flex;
        flex-direction: column;
        width: 100%;

        > label.input-label {
          color: var(--dt-ui-text-color);
          font-size: 1.2rem;
          font-weight: 600;
        }

        .dt-ui-input__wrapper {
          width: 100%;
        }
      }

      .login-options {
        display: flex;
        flex-direction: column;
        padding-inline: 4.8rem;
        align-items: stretch;
        gap: 2rem;
        flex-grow: 1;
        align-self: stretch;

        .jwks-login {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .jwks-inputs {
            display: flex;
            flex-direction: column;
            gap: 0.8rem;

            .dt-ui-input__wrapper {
              width: 100%;
            }
          }

          .action-buttons {
            display: flex;
            gap: 1.2rem;

            .dt-ui-button {
              flex: 1;
            }

            a {
              flex: 1;

              .dt-ui-button {
                width: 100%;
              }
            }
          }
        }

        .or-line {
          display: flex;
          gap: 0.6rem;
          align-items: center;

          .or-line__line {
            flex-grow: 1;
            height: 0;
            border: 1px solid var(--dt-ui-field-border);
          }
        }

        .saml-providers {
          display: flex;
          align-items: stretch;
          flex-direction: column;
          gap: 0.8rem;

          a .dt-ui-button {
            width: 100%;
          }
        }
      }
    }

    .redirecting {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      span {
        color: var(--dt-ui-text-color);
      }
    }

    .dt-ui-button.continue-btn {
      width: 100%;
    }

    .dt-ui-accordion {
      width: 100%;

      .toasts {
        padding: 1.2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        .dt-ui-toast {
          width: 100%;
        }
      }
    }
  }
`;

export function Login() {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [hasEmailBeenEntered, setHasEmailBeenEntered] = useState(false);
  const [hasEmailBeenAccepted, setHasEmailBeenAccepted] = useState(false);
  const [samlProviders, setSamlProviders] = useState([]);
  const samlProvider = samlProviders[0];
  const { queryParams } = useQueryParams();

  const [toasts, setToasts] = useState([]);

  const isInputValid = useMemo(() => {
    if (!hasEmailBeenEntered) return undefined;
    return z.string().email().safeParse(emailAddress).success;
  }, [emailAddress, hasEmailBeenEntered]);

  const [searchParams] = useSearchParams();
  const emailSearchParam = searchParams.get("email") || searchParams.get("username");
  const productSearchParam = searchParams.get("product");
  const productPathSearchParam = searchParams.get("product_path");
  const canLogin = emailAddress && password;

  useEffect(() => {
    if (emailSearchParam) setEmailAddress(emailSearchParam);
  }, [emailSearchParam]);

  useDebouncedEffect(() => {
    if (productSearchParam) localStorage.setItem("product", productSearchParam);
    else localStorage.removeItem("product");

    if (productPathSearchParam) localStorage.setItem("product_path", productPathSearchParam);
    else localStorage.removeItem("product_path");
  }, []);

  async function handleSendEmail() {
    setToasts([]);
    setHasEmailBeenEntered(true);

    if (!z.string().email().safeParse(emailAddress).success) return;

    lookupEmailAddress({ email: emailAddress })
      .then((response) => {
        const samlProviders = response.data.samlProviders;
        setHasEmailBeenAccepted(true);
        setSamlProviders(samlProviders ?? []);
      })
      .catch(() => setToasts((prevToasts) => [...prevToasts, { delay: 5000, title: t(`Unable to connect.`), variant: "error" }]));
  }

  const samlProviderIcons = {
    "0000": "microsoft fa-brands",
    "darktrace-jwks": "dt-icon-template fa-kit",
  };

  const loginErrorMessages = {
    userDisabled: t(`Access Restricted: your account is currently disabled`),
  };

  useEffect(() => {
    // set initial document title
    document.title = t(`Login | Darktrace ActiveAI Security Portal | Darktrace`);

    // prevent default validation error bubble
    const preventDefaultValidationErrorBubble = (e) => e.preventDefault();
    document.addEventListener("invalid", preventDefaultValidationErrorBubble, true);
    return () => document.removeEventListener("invalid", preventDefaultValidationErrorBubble, true);
  }, []);

  const isOnLocalhost = window.origin.includes("localhost");
  const redirect_uri = isOnLocalhost ? `${window.origin}/api/oid/callback` : "";

  useDebounce(
    () => {
      if (queryParams["failure"]) {
        const message = loginErrorMessages[queryParams["failure"]] ?? "Incorrect Credentials";
        setToasts((prevToasts) => [...prevToasts, { delay: 5000, title: message, variant: "error" }]);
      }
    },
    200,
    [queryParams["failure"]],
  );

  const [colorTheme] = useColorTheme();

  const loginBg = colorTheme === "polar-light" ? loginBgLight : loginBgDark;
  const dtImage = colorTheme === "polar-light" ? dtImageDark : dtImageLight;

  return (
    <StyledLogin $loginBg={loginBg}>
      <div className="login-box">
        <div className="logo">
          <img className="dt-ui-logo" src={dtImage} />
        </div>

        <div className="credentials">
          {!hasEmailBeenAccepted ? (
            <form className="original" onSubmit={(e) => e.preventDefault()}>
              <div className="field-input">
                <label className="input-label" htmlFor="email">
                  {t(`Email Address`)}
                </label>
                <Input
                  id="email"
                  type="email"
                  data-test-id="email-input"
                  valid={isInputValid}
                  invalidMsg={t(`Invalid Email Address`)}
                  value={emailAddress}
                  onChange={(value) => {
                    setEmailAddress(value);
                    setHasEmailBeenEntered(false);
                  }}
                />
              </div>

              <Button
                className="continue-btn"
                data-test-id="continue-btn"
                iconTrailing="arrow-right"
                onClick={() => handleSendEmail()}
                size="small"
              >
                {t(`Continue`)}
              </Button>
            </form>
          ) : (
            <div className="login-options">
              <form action="/api/oid/login" method="POST">
                <div className="jwks-login">
                  <div className="jwks-inputs">
                    {/* JWKs Login */}

                    <input name="redirect_uri" hidden value={redirect_uri} readOnly />

                    {/* Required as disabled input not sent in the form */}
                    <input name="username" hidden value={emailAddress} readOnly />

                    <div className="field-input">
                      <label className="input-label" htmlFor="email">
                        {t(`Email Address`)}
                      </label>
                      <Input value={emailAddress} disabled inputAttrs={{ readOnly: true }} />
                    </div>

                    <div className="field-input">
                      <label className="input-label" htmlFor="email">
                        {t(`Password`)}
                      </label>
                      <Input
                        id="password"
                        inputAttrs={{ name: "password" }}
                        value={password}
                        type="password"
                        onChange={(value) => setPassword(value)}
                      />
                    </div>
                  </div>

                  <div className="action-buttons">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        setHasEmailBeenEntered(false);
                        setHasEmailBeenAccepted(false);
                      }}
                    >
                      {t(`Cancel`)}
                    </Button>

                    <Button disabled={!canLogin} iconTrailing="arrow-right">
                      {t(`Continue`)}
                    </Button>
                  </div>
                </div>
              </form>

              {/* SAML Login */}
              <>
                {samlProviders.length > 0 && (
                  <div className="or-line">
                    <div className="or-line__line" />
                    <span>OR</span>
                    <div className="or-line__line" />
                  </div>
                )}

                <div className="saml-providers">
                  {!!samlProvider && (
                    <a key={samlProvider.id} href={`/api/saml/login?provider=${samlProvider.id}`} disabled={password.length > 0}>
                      <Button disabled={password.length > 0} icon={samlProviderIcons[samlProvider.id] ?? "right-to-bracket"}>
                        {t(`Log in with SSO`)}
                      </Button>
                    </a>
                  )}
                </div>
              </>
            </div>
          )}
        </div>

        <Accordion collapsed={toasts.length == 0}>
          <div className="toasts">
            {toasts.map((toast) => (
              <Toast key={toast.id} {...toast} />
            ))}
          </div>
        </Accordion>
      </div>
    </StyledLogin>
  );
}
