import { ClickAwayListener, DropdownDropper, IconButton, OverflowTooltip } from "@darktrace/ui-components";
import React, { Fragment, useRef, useState } from "react";
import { resetMyMfa } from "../logic/api.js";
import { useDispatch } from "react-redux";
import { newToast } from "../logic/store.js";
import { useLogout } from "../logic/hooks.js";
import styled from "styled-components";

const StyledDropdownDropper = styled(DropdownDropper)`
  .logout {
    border-top: 1px solid var(--dt-ui-field-border);
  }
  .dt-ui-dropdown__title {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: flex-start;
    > .dt-ui-overflow-tooltip {
      width: 100%;
    }
  }
`;

export function UserSettingsDropdown({ user = {}, setIsUserOverviewModalOpen, setIsPasswordChangeModalOpen, SAMLAlias }) {
  const dispatch = useDispatch();
  const logout = useLogout();
  const userSettingsDropdownButtonRef = useRef(null);
  const [isUserSettingsDropdownOpen, setIsUserSettingsDropdownOpen] = useState();

  const userSettingsDropdownItems = [
    { id: "my-profile", icon: "user", label: "My Profile", disabled: !user },
    ...(SAMLAlias ? [] : [{ id: "reset-password", icon: "key", label: "Update Password" }]),
    ...(SAMLAlias ? [] : [{ id: "reset-mfa", icon: "rotate-right", label: "Reset MFA" }]),
    { id: "logout", className: "logout", icon: "right-from-bracket", label: "Logout" },
  ];

  async function handleSelectUserSetting(selectedItemId) {
    switch (selectedItemId) {
      case "my-profile":
        setIsUserOverviewModalOpen(true);
        return;
      case "reset-password":
        setIsPasswordChangeModalOpen(true);
        return;
      case "reset-mfa":
        resetMyMfa()
          .then(() => dispatch(newToast({ variant: "success", title: t(`MFA reset`) })))
          .catch(() => dispatch(newToast({ variant: "error", title: t(`Unable to reset MFA`) })));
        return;
      case "logout":
        await logout();
        return;
    }
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsUserSettingsDropdownOpen(false)}>
        <IconButton
          icon="user"
          ref={userSettingsDropdownButtonRef}
          variant="secondary"
          active={isUserSettingsDropdownOpen}
          onClick={() => setIsUserSettingsDropdownOpen((prev) => !prev)}
        />
      </ClickAwayListener>

      <StyledDropdownDropper
        title={
          user ? (
            <Fragment>
              <OverflowTooltip>{t(`User: {0}`, user.name)}</OverflowTooltip>
              {SAMLAlias && <OverflowTooltip>{t(`SSO: {0}`, SAMLAlias)}</OverflowTooltip>}
            </Fragment>
          ) : undefined
        }
        open={isUserSettingsDropdownOpen}
        anchorEl={userSettingsDropdownButtonRef?.current}
        items={userSettingsDropdownItems}
        width={200}
        onSelect={handleSelectUserSetting}
        dropdownArrow
        placement="bottom-end"
      />
    </>
  );
}
