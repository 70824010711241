import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  fetchClientSAMLProviderGroupMappings,
  useClientSAMLProviderAccessMethods,
  useClientSAMLProviderGroupMappings,
  useClientSAMLProviderProfiles,
  useCreateClientSAMLProviderProfileMutation,
  useEditClientSAMLProviderGroupMappingsMutation,
  useMultiInstanceProductGroups,
  useProductAccessInstances,
  useProductGroups,
} from "../../logic/api.js";
import { Button, Card, Dropdown, Input, ModalCard, Table, TableWrapper } from "@darktrace/ui-components";
import { useActiveClientId } from "../../logic/hooks.js";
import { Mappings } from "./Mappings.jsx";
import { useQueries } from "@tanstack/react-query";

const StyledSAMLGroupsCard = styled(Card)`
  .dt-ui-card__contents {
    padding: 0;
  }
`;

export function SAMLGroupsCard({ clientSamlProviderId, alias }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: clientSAMLProviderProfiles = [], isLoading: isLoadingClientSAMLProviderProfiles } = useClientSAMLProviderProfiles({
    clientSamlProviderId,
  });

  const { data: groupMappings = [], isLoading: isLoadingGroupMappings } = useQueries({
    queries: clientSAMLProviderProfiles.map((profile) => ({
      queryKey: ["client-saml-provider-group-mappings", { profileId: profile.id }],
      queryFn: async () => fetchClientSAMLProviderGroupMappings({ profileId: profile.id }),
    })),
    combine: (results) => {
      return {
        data: results.map((result, index) => ({
          profileId: clientSAMLProviderProfiles[index].id,
          group: clientSAMLProviderProfiles[index].group,
          instanceGroupIds: result.data ?? [],
        })),
        isLoading: results.some((result) => result.isLoading),
      };
    },
  });

  const activeClientId = useActiveClientId();
  const { data: accessInstances = [] } = useProductAccessInstances({ clientId: activeClientId });
  const { data: samlProviderAccessMethods = [] } = useClientSAMLProviderAccessMethods({ clientSamlProviderId });

  const transparentSAMLGroupAccessTypes = samlProviderAccessMethods
    .filter(({ method }) => method === "saml-whitelist-groups")
    .map(({ accessType }) => accessType);

  // // get (product) groups for each instance
  const { data: productGroups, isLoading: isLoadingProductGroups } = useMultiInstanceProductGroups({
    clientId: activeClientId,
    state: "UP",
  });

  const formattedProductGroups = productGroups.map((group) => {
    const accessInstance = accessInstances.find((instance) => instance.instanceId === group.instanceId);
    return {
      ...group,
      disabled: transparentSAMLGroupAccessTypes.includes(accessInstance.type),
    };
  });

  const formattedGroupMappings = groupMappings.map(({ profileId, group: samlGroup, instanceGroupIds }) => ({
    name: samlGroup,
    profileId: profileId,
    items:
      instanceGroupIds
        ?.map(({ accessInstanceId, groups }) =>
          groups.map((groupId) => ({
            instanceId: accessInstanceId,
            id: groupId,
            text: accessInstances.find((instance) => instance.instanceId === accessInstanceId)?.displayName ?? "Unknown Product",
            scopedText:
              formattedProductGroups.find((group) => group.id === groupId)?.displayName ??
              (isLoadingProductGroups ? "Loading..." : "Unknown Group"),
            disabled: formattedProductGroups.find((group) => group.id === groupId)?.disabled,
          })),
        )
        .flat() ?? [],
  }));

  const isLoading = isLoadingClientSAMLProviderProfiles || isLoadingGroupMappings;

  return (
    <>
      <StyledSAMLGroupsCard
        title={alias ? t(`Groups ({0})`, alias) : t(`Groups`)}
        titleRight={
          <Button icon="plus" size="small" onClick={() => setIsModalOpen((prev) => !prev)}>
            {t(`Create SAML Group Mappings`)}
          </Button>
        }
      >
        <Mappings
          mappings={formattedGroupMappings}
          type="group"
          isLoadingMappings={isLoading}
          clientSamlProviderId={clientSamlProviderId}
        />
      </StyledSAMLGroupsCard>

      <SAMLGroupMappingsModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mappings={formattedGroupMappings}
        clientSamlProviderId={clientSamlProviderId}
      />
    </>
  );
}

const StyledModalCard = styled(ModalCard)`
  display: flex;
  flex-direction: column;
  .dt-ui-card__contents {
    padding: 0;
  }
`;

export function SAMLGroupMappingsModal({ open, onClose, profileId, samlGroup, clientSamlProviderId }) {
  return (
    <StyledModalCard
      open={open}
      title={samlGroup ? t(`Edit Group Mapping`) : t(`Create New Group Mapping`)}
      onClose={onClose}
      keepMounted={false}
    >
      <GroupMappings
        profileId={profileId}
        samlGroup={samlGroup}
        open={open}
        clientSamlProviderId={clientSamlProviderId}
        onClose={onClose}
      />
    </StyledModalCard>
  );
}

const StyledGroupMappings = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr auto;

  .saml-group-name {
    padding: 0.8rem 1.6rem;
    display: flex;
    gap: 2rem;
    align-items: flex-start;

    > span {
      position: relative;
      top: 0.6rem;
      white-space: nowrap;
    }

    .dt-ui-input__wrapper {
      width: 100%;
    }
  }

  .select-groups {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
  }

  .action-buttons {
    padding: 0.8rem;
    display: flex;
    gap: 1.2rem;

    > * {
      flex: 1;
    }
  }
`;

function GroupMappings({ profileId, samlGroup, open, onClose, clientSamlProviderId }) {
  const isNew = !profileId;

  const activeClientId = useActiveClientId();
  const { data: accessInstances = [] } = useProductAccessInstances({ clientId: activeClientId, enabled: open });

  const [samlGroupName, setSamlGroupName] = useState(samlGroup);
  const { data: clientSAMLProviderProfiles = [] } = useClientSAMLProviderProfiles({ clientSamlProviderId });
  const isExistingProfileWithSameGroupName = clientSAMLProviderProfiles.some((profile) => profile.group === samlGroupName);

  const { data: groupMappings = [] } = useClientSAMLProviderGroupMappings({ profileId });
  const initialInstanceSelectedGroupIds = Object.fromEntries(
    groupMappings.map(({ accessInstanceId, groups }) => [accessInstanceId, groups]),
  );

  const { data: samlProviderAccessMethods = [] } = useClientSAMLProviderAccessMethods({ clientSamlProviderId });

  const transparentSAMLGroupAccessTypes = samlProviderAccessMethods
    .filter(({ method }) => method === "saml-whitelist-groups")
    .map(({ accessType }) => accessType);

  const [instanceSelectedGroupIds, setInstanceSelectedGroupIds] = useState({});
  const [_, setForceRenderCount] = useState(0); // eslint-disable-line
  const instanceGroupIdsToAdd = Object.entries(instanceSelectedGroupIds)
    .map(([instanceId, groupIds]) => {
      return (
        groupIds
          ?.map((groupId) => ({ instanceId, groupId }))
          .flat()
          .filter(({ instanceId, groupId }) => !initialInstanceSelectedGroupIds[instanceId]?.includes(groupId)) ?? []
      );
    })
    .flat();

  const instanceGroupIdsToRemove = Object.entries(initialInstanceSelectedGroupIds)
    .map(([instanceId, groupIds]) => {
      return (
        groupIds
          ?.map((groupId) => ({ instanceId, groupId }))
          .flat()
          .filter(({ instanceId, groupId }) => !instanceSelectedGroupIds[instanceId]?.includes(groupId)) ?? []
      );
    })
    .flat();

  function handleChangeSelectedGroups(instanceId, selectedGroupIds) {
    setInstanceSelectedGroupIds((prevInstanceSelectedGroupIds) => {
      prevInstanceSelectedGroupIds[instanceId] = selectedGroupIds;
      return prevInstanceSelectedGroupIds;
    });
    setForceRenderCount((prev) => prev + 1);
  }

  const tableData = accessInstances
    .toSorted((instanceA, instanceB) => {
      if (instanceA.type === "sabre-threat-visualiser-product-access") return 1;
      if (instanceB.type === "sabre-threat-visualiser-product-access") return -1;

      return 0;
    })
    .map((instance) => {
      const isInstanceDown = instance.state !== "UP" && instance.type === "sabre-threat-visualiser-product-access";
      const isInstancePassingSAMLGroupsTransparently = transparentSAMLGroupAccessTypes?.includes(instance.type);

      return [
        // INSTANCE NAME
        instance.displayName,

        // AVAILABLE GROUPS FOR INSTANCE
        <AvailableGroupsDropdown
          key={instance.instanceId}
          instanceId={instance.instanceId}
          isInstanceDown={isInstanceDown}
          isInstancePassingSAMLGroupsTransparently={isInstancePassingSAMLGroupsTransparently}
          onChange={(selectedGroupIds) => handleChangeSelectedGroups(instance.instanceId, selectedGroupIds)}
          initialSelectedGroupIds={initialInstanceSelectedGroupIds[instance.instanceId]}
        />,
      ];
    });

  const { mutateAsync: editSamlProfileGroupMappings, isPending: isEditingMappings } = useEditClientSAMLProviderGroupMappingsMutation({
    profileId,
  });
  const { mutateAsync: createSAMLProviderProfile, isPending: isCreatingSAMLProviderProfile } = useCreateClientSAMLProviderProfileMutation({
    clientSamlProviderId,
  });

  function handleCreateProfile() {
    createSAMLProviderProfile(samlGroupName).then((res) => {
      if (!res) return;
      const responseData = res.data.profile;
      handleSaveMappings({ newProfileId: responseData.id });
    });
  }

  function handleSaveMappings({ newProfileId } = {}) {
    const groupIdsToEdit = {};

    instanceGroupIdsToAdd.forEach(({ instanceId, groupId }) => {
      if (groupIdsToEdit[instanceId]) groupIdsToEdit[instanceId].add.push(groupId);
      else groupIdsToEdit[instanceId] = { add: [groupId], remove: [] };
    });
    instanceGroupIdsToRemove.forEach(({ instanceId, groupId }) => {
      if (groupIdsToEdit[instanceId]) groupIdsToEdit[instanceId].remove.push(groupId);
      else groupIdsToEdit[instanceId] = { add: [], remove: [groupId] };
    });

    const allEditsPromises = Object.entries(groupIdsToEdit).map(([instanceId, { add, remove }]) =>
      editSamlProfileGroupMappings({ profileId: newProfileId ?? profileId, accessInstanceId: instanceId, add, remove }),
    );

    Promise.allSettled(allEditsPromises).then(onClose);
  }

  const reusedGroupName = isNew && isExistingProfileWithSameGroupName;

  const disabled =
    !samlGroupName ||
    isCreatingSAMLProviderProfile ||
    isEditingMappings ||
    reusedGroupName ||
    (!instanceGroupIdsToAdd.length > 0 && !instanceGroupIdsToRemove.length > 0);

  return (
    <StyledGroupMappings>
      <div className="saml-group-name">
        <span>{t(`SAML Group Name`)}:</span>
        <Input
          value={samlGroupName}
          onChange={isNew ? setSamlGroupName : undefined}
          disabled={!isNew}
          placeholder="Type here..."
          valid={reusedGroupName ? false : undefined}
          invalidMsg={t(`Group name already in use`)}
        />
      </div>

      <div className="select-groups">
        <TableWrapper>
          <Table headers={[{ title: "Product" }, { title: "Groups" }]} data={tableData} />
        </TableWrapper>
      </div>

      <div className="action-buttons">
        <Button variant="secondary" onClick={onClose}>
          {t(`Cancel`)}
        </Button>
        <Button disabled={disabled} onClick={isNew ? handleCreateProfile : handleSaveMappings}>
          {t(`Save`)}
        </Button>
      </div>
    </StyledGroupMappings>
  );
}

const StyledDropdown = styled(Dropdown)`
  width: 32rem;
`;

function AvailableGroupsDropdown({
  instanceId,
  isInstanceDown,
  isInstancePassingSAMLGroupsTransparently,
  initialSelectedGroupIds,
  onChange,
}) {
  const { data: _availableGroups = [], isLoading, isError } = useProductGroups({ instanceId, enabled: !isInstanceDown });
  const availableGroups = Object.values(_availableGroups);
  const [selectedGroupIds, setSelectedGroupIds] = useState(initialSelectedGroupIds);

  useEffect(() => {
    setSelectedGroupIds(initialSelectedGroupIds);
  }, [initialSelectedGroupIds]);

  const dropdownItems = availableGroups.map((group) => ({
    id: group.id,
    label: group.name ?? group.displayName,
    selected: selectedGroupIds?.includes(group.id),
  }));

  useEffect(() => {
    onChange(selectedGroupIds);
  }, [selectedGroupIds]);

  function handleSelectItem(id) {
    setSelectedGroupIds((prevSelectedGroupIds) => {
      if (prevSelectedGroupIds?.includes(id)) return prevSelectedGroupIds.filter((groupId) => groupId !== id);
      else return [...(prevSelectedGroupIds ?? []), id];
    });
  }

  function handleSelectAllItems() {
    setSelectedGroupIds((prevSelectedGroupIds) => {
      const areAllItemsAlreadySelected = (prevSelectedGroupIds?.length ?? 0) === availableGroups.length;
      if (areAllItemsAlreadySelected) return [];
      else return availableGroups.map((group) => group.id);
    });
  }

  return (
    <StyledDropdown
      placeholder={t(`No groups selected`)}
      displayValue={
        isInstanceDown
          ? t(`Unable to contact product`)
          : isInstancePassingSAMLGroupsTransparently
            ? t(`Passing SAML Groups Transparently`)
            : isError
              ? t(`Unable to fetch product groups`)
              : isLoading
                ? t(`Loading...`)
                : undefined
      }
      disabled={isInstanceDown || isInstancePassingSAMLGroupsTransparently || isError || isLoading}
      items={dropdownItems}
      checkbox
      onSelect={handleSelectItem}
      onSelectAll={handleSelectAllItems}
    />
  );
}
