import React, { useState } from "react";
import styled from "styled-components";
import { useProductLoginMutation } from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { Tooltip, useColorTheme } from "@darktrace/ui-components";
import { ProductLoginModal } from "./ProductLoginModal.jsx";
import asm from "../../../public/dt-assets/icon/4x/dt-icon-attack-surface-management-gradient.png";
import cloud from "../../../public/dt-assets/icon/4x/dt-icon-cloud-gradient.png";
// import emailDataLossPrevention from "../../../public/dt-assets/icon/4x/dt-icon-email-data-loss-prevention-gradient.png";
import dmarc from "../../../public/dt-assets/icon/4x/dt-icon-email-dmarc-gradient.png";
import dmarcDark from "../../../public/images/dt-icon-email-dmarc-gradient-dark.png";
import email from "../../../public/dt-assets/icon/4x/dt-icon-email-gradient.png";
// import sat from "../../../public/dt-assets/icon/4x/dt-icon-email-sat-gradient.png";
// import emailTeams from "../../../public/dt-assets/icon/4x/dt-icon-email-teams-gradient.png";
// import endpoint from "../../../public/dt-assets/icon/4x/dt-icon-endpoint-gradient.png";
// import identity from "../../../public/dt-assets/icon/4x/dt-icon-identity-gradient.png";
// import network from "../../../public/dt-assets/icon/4x/dt-icon-network-gradient.png";
import ot from "../../../public/dt-assets/icon/4x/dt-icon-ot-gradient.png";
import e2e from "../../../public/dt-assets/icon/4x/dt-icon-proactive-exposure-management-gradient.png";
import visualiserNew from "../../../public/dt-assets/icon/2x/dt-icon-threat-visualizer-gradient.png";
// import visualiserNew from "../../../public/images/dt-threat-vis-new.svg";
import darktraceLogoTextDark from "../../../public/dt-assets/logo/1x/dt-logo-black.png";
import darktraceLogoTextLight from "../../../public/dt-assets/logo/1x/dt-logo-grey.png";
// import activeAiPortalLogoIcon from "../../../public/dt-assets/icon/4x/dt-icon-activeai-gradient.png";
import { PRODUCT_DESCRIPTIONS } from "../../logic/util.js";

const StyledProductTile = styled.button`
  position: relative;
  > .dt-ui-tooltip-target {
    z-index: 1;
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
  }

  > .product-status-icon {
    z-index: 1;
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;

    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.8rem;

    background-color: var(--dt-ui-suppressed);

    &.UP {
      display: none;
      background-color: var(--dt-ui-resolved);
    }

    &.DOWN {
      background-color: var(--dt-ui-critical);
    }
  }

  background-color: var(--dt-ui-field-bg-muted);
  border-radius: 6px;
  border: 1px solid var(--dt-ui-field-border);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: fit-content;
  min-width: 20rem;
  border: 1px solid var(--dt-ui-field-border);
  aspect-ratio: 5 / 3;
  flex: 1;
  overflow: hidden;

  &:hover {
    background-color: var(--dt-ui-field-hover-bg);
  }

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    .dt-ui-tooltip-target {
      position: absolute;
      top: 0.8rem;
      left: 0.8rem;
    }

    img.product-icon {
      max-width: 65%;
      height: auto;
      max-height: 6rem;
    }
  }

  .footer {
    align-self: stretch;
    border-top: 1px solid var(--dt-ui-field-border);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 600;
    height: 4.8rem;
    padding-inline: 2rem;

    .darktrace-text {
      font-weight: 700;
    }

    &:disabled {
      cursor: progress;
    }
  }
`;

const PRODUCT_INFO_MAP = {
  // standard welcome products
  "dmarc-service-type": { logoLight: dmarcDark, logoDark: dmarc, name: "/ EMAIL - DMARC" },
  "asm-service-type": { logoLight: asm, logoDark: asm, name: "/ Attack Surface Management" },
  "customer-portal-service-type": { logoLight: darktraceLogoTextDark, logoDark: darktraceLogoTextLight, name: "/ Customer Portal" }, // TODO: update logo
  "sabre-service-type": { logoLight: visualiserNew, logoDark: visualiserNew, name: "/ Threat Visualizer" },
  "ptn-service-type": { logoLight: darktraceLogoTextDark, logoDark: darktraceLogoTextLight, name: "/ PTN" }, // TODO: update logo

  // sabre products
  "sabre-threat-visualiser": { logoLight: visualiserNew, logoDark: visualiserNew, name: "/ Threat Visualizer" },
  "sabre-email-console": { logoLight: email, logoDark: email, name: "/ EMAIL" },
  "sabre-platforms-accounts-console": { logoLight: visualiserNew, logoDark: visualiserNew, name: "/ Threat Visualizer" }, // TODO: update logo
  "sabre-cloud-security": { logoLight: cloud, logoDark: cloud, name: "/ CLOUD" },
  "sabre-e2e": { logoLight: e2e, logoDark: e2e, name: "/ Proactive Exposure Management" },
  "sabre-otrm": { logoLight: ot, logoDark: ot, name: "/ OT" },
};

export function ProductTile({ serviceType, serviceId, serviceLabel, instances = [] }) {
  const [colorTheme] = useColorTheme();
  const activeClientId = useActiveClientId();
  const { mutate: loginMutation, isPending } = useProductLoginMutation({ clientId: activeClientId, serviceId });
  const [showProductLoginModal, setShowProductLoginModal] = useState();

  const productLogoImage = PRODUCT_INFO_MAP[serviceType]?.[colorTheme === "polar-light" ? "logoLight" : "logoDark"] || visualiserNew;
  const productName = PRODUCT_INFO_MAP[serviceType]?.name;
  const productTooltip = PRODUCT_DESCRIPTIONS?.[serviceType];
  const productStatus = instances.reduce((prevStatus, instance) => {
    if (prevStatus === "MIX") return prevStatus;

    let instanceState;
    if (!instance.state) {
      if (instance.type === "sabre-threat-visualiser-product-access") instanceState = "DOWN";
      else instanceState = "UP";
    } else {
      instanceState = instance.state;
    }

    if (!prevStatus) return instanceState;
    else if (prevStatus === instanceState) return instanceState;
    else return "MIX";
  }, null);

  const shouldUseProductLoginModal = instances.length > 1;

  function handleLogin() {
    // show modal if there are multiple instances
    if (shouldUseProductLoginModal) {
      setShowProductLoginModal((prev) => !prev);
      return;
    }

    loginMutation({ instanceId: instances[0].instanceId });
  }

  return (
    <>
      <StyledProductTile onClick={handleLogin}>
        {productTooltip && <Tooltip text={productTooltip}></Tooltip>}
        {productStatus && <div className={`product-status-icon ${productStatus}`} />}
        <div className="header">
          <img className="product-icon" src={productLogoImage} />
        </div>
        <div className="footer" disabled={isPending}>
          <span className="darktrace-text">Darktrace</span>
          <span className="product-name">{productName}</span>
        </div>
      </StyledProductTile>

      {shouldUseProductLoginModal && (
        <ProductLoginModal
          open={showProductLoginModal}
          onClose={() => setShowProductLoginModal(false)}
          serviceId={serviceId}
          serviceLabel={serviceLabel}
          instances={instances}
        />
      )}
    </>
  );
}
