import React, { useEffect, useMemo, useTransition } from "react";
import styled from "styled-components";
import { Button, Input, ModalCard, Table } from "@darktrace/ui-components";
import { useEditUsersReducer } from "./editUsersReducer.js";
import { addChildRoleToUser, addRoleToUser, removeChildRoleFromUser, removeRoleFromUser, updateUserInfo } from "../../logic/api.js";
import { useDispatch } from "react-redux";
import { newToast } from "../../logic/store.js";
import { queryClient } from "../../logic/index.jsx";
import { useActiveClientId } from "../../logic/hooks.js";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    display: flex;
    flex-direction: column;

    .dt-ui-card__contents {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      flex-grow: 1;
      height: 100%;

      .action-buttons {
        align-self: flex-end;
        display: flex;
        gap: 0.8rem;
        padding: 1.2rem 1.6rem;
      }

      .table-wrapper {
        overflow-x: auto;
        width: 100%;

        .dt-ui-table {
          width: 100%;
        }
      }

      td {
        .changed-field .dt-ui-dropdown-button {
          border-color: var(--dt-input-valid-border);
        }

        .dt-ui-dropdown-button,
        .dt-input__wrapper {
          width: 100%;
          min-width: 12rem;
        }
      }
    }
  }
`;

export function EditUsersModal({ open, users: _users = [], /* availableRoles = [], */ onClose = () => {}, isUserFromParentClient }) {
  const users = _users.map((user) => ({ ...user, roles: user.roles?.map((role) => role.id) }));

  const modalRoot = document.querySelector("#modal-root");
  const dispatch = useDispatch();
  const activeClientId = useActiveClientId();
  const [state, localDispatch] = useEditUsersReducer(users);
  const [isPendingTransition, startTransition] = useTransition(); // just a performance thing

  useEffect(() => {
    startTransition(() => localDispatch({ type: "reset-users" }));
  }, []);

  const tableHeaders = [
    { title: t(`Name`), sortable: false },
    { title: t(`Email`), sortable: false },
    // ...(isYou ? [] : [{ title: t(`Roles`), sortable: false }]),
  ];

  const usersWithChanges = users.map((user) => {
    const changesToUser = state.find((stateUser) => stateUser.id === user.id);
    const updatedUser = { ...user };
    Object.entries(changesToUser ?? {}).forEach(([field, value]) => {
      if (value !== undefined) updatedUser[field] = value;
    });
    return updatedUser;
  });

  const allUserUpdates = useMemo(() => {
    const updates = [];
    // only keep updates that are not undefined
    state.forEach((userUpdate) => {
      const filteredUserUpdate = {};
      Object.entries(userUpdate).forEach(([field, value]) => {
        if (value !== undefined) {
          if (field === "roles") {
            // separate into rolesToAdd and rolesToRemove
            const newRoles = value;
            const prevRoles = users.find((user) => userUpdate.id === user.id).roles;
            const rolesToAdd = newRoles.filter((role) => !prevRoles.includes(role));
            const rolesToRemove = prevRoles.filter((role) => !newRoles.includes(role));
            if (rolesToAdd.length > 0) filteredUserUpdate.rolesToAdd = rolesToAdd;
            if (rolesToRemove.length > 0) filteredUserUpdate.rolesToRemove = rolesToRemove;
          } else {
            filteredUserUpdate[field] = value;
          }
        }
      });
      // only include if more than one item (not just `id` field)
      if (Object.keys(filteredUserUpdate).length > 1) updates.push(filteredUserUpdate);
    });
    return updates;
  }, [state]);

  async function handleSaveChanges() {
    try {
      const allUserDetailsUpdates = allUserUpdates
        .filter(({ rolesToAdd, rolesToRemove, ...userUpdates }) => Object.keys(userUpdates).length > 1)
        .map(({ rolesToAdd, rolesToRemove, ...userUpdates }) => userUpdates);

      const allUserRolesAdditions = allUserUpdates
        .filter(({ rolesToAdd }) => rolesToAdd)
        .map(({ id, rolesToAdd }) => rolesToAdd.map((roleId) => ({ id, roleId })))
        .flat();

      const allUserRolesRemovals = allUserUpdates
        .filter(({ rolesToRemove }) => rolesToRemove)
        .map(({ id, rolesToRemove }) => rolesToRemove.map((roleId) => ({ id, roleId })))
        .flat();

      const _addRoleToUser = isUserFromParentClient ? addChildRoleToUser : addRoleToUser;
      const _removeRoleFromUser = isUserFromParentClient ? removeChildRoleFromUser : removeRoleFromUser;

      // requests
      const allUserDetailsUpdatesResults = await Promise.allSettled(
        allUserDetailsUpdates.map(({ id, name }) => updateUserInfo({ clientId: activeClientId, userId: id, name })),
      );
      const allUserRolesAdditionsResults = await Promise.allSettled(
        allUserRolesAdditions.map(({ id, roleId }) => _addRoleToUser({ clientId: activeClientId, userId: id, roleId })),
      );
      const allUserRolesRemovalsResults = await Promise.allSettled(
        allUserRolesRemovals.map(({ id, roleId }) => _removeRoleFromUser({ clientId: activeClientId, userId: id, roleId })),
      );
      const allRequests = [...allUserDetailsUpdatesResults, ...allUserRolesAdditionsResults, ...allUserRolesRemovalsResults];

      // failed requests
      const failedDetailsUpdates = allUserDetailsUpdatesResults
        .map((result, index) => ({
          userId: allUserDetailsUpdates[index]?.id,
          ...result,
        }))
        .filter(({ status }) => status === "rejected");

      const failedRoleAdditions = allUserRolesAdditionsResults
        .map((result, index) => ({
          userId: allUserRolesAdditions[index]?.id,
          roleId: allUserRolesAdditions[index]?.roleId,
          ...result,
        }))
        .filter(({ status }) => status === "rejected");

      const failedRoleRemovals = allUserRolesRemovalsResults
        .map((result, index) => ({
          userId: allUserRolesRemovals[index]?.id,
          roleId: allUserRolesRemovals[index]?.roleId,
          ...result,
        }))
        .filter(({ status }) => status === "rejected");

      const allFailedRequests = [...failedDetailsUpdates, ...failedRoleAdditions, ...failedRoleRemovals];

      if (failedDetailsUpdates.length > 0) {
        dispatch(
          newToast({
            variant: "error",
            title:
              failedDetailsUpdates.length > 1
                ? t(`{0} user details update attempts failed`, failedDetailsUpdates.length)
                : t(`{0} user details update attempt failed`, failedDetailsUpdates.length),
            text: t(
              `Affected Users: [{0}]`,
              failedDetailsUpdates.map((update) => users.find((user) => user.id === update.userId)?.name).join(", "),
            ),
            delay: null,
          }),
        );
      }

      if (failedRoleAdditions.length > 0) {
        dispatch(
          newToast({
            variant: "error",
            title:
              failedRoleAdditions.length > 1
                ? t(`{0} user role addition attempts failed`, failedDetailsUpdates.length)
                : t(`{0} user role addition attempt failed`, failedDetailsUpdates.length),

            text: t(
              `Affected Users: [{0}]`,
              failedRoleAdditions.map((update) => `${users.find((user) => user.id === update.userId)?.name}:${update.roleId}`).join(", "),
            ),
            delay: null,
          }),
        );
      }

      if (failedRoleRemovals.length > 0) {
        dispatch(
          newToast({
            variant: "error",
            title:
              failedRoleRemovals.length > 1
                ? t(`{0} user role removal attempts failed`, failedRoleRemovals.length)
                : t(`{0} user role removal attempt failed`, failedRoleRemovals.length),
            text: t(
              `Affected Users: [{0}]`,
              failedRoleRemovals.map((update) => `${users.find((user) => user.id === update.userId)?.name}:${update.roleId}`).join(", "),
            ),
            delay: null,
          }),
        );
      }

      const numSuccessfulRequests = allRequests.length - allFailedRequests.length;
      if (numSuccessfulRequests > 0) {
        dispatch(
          newToast({
            variant: "success",
            title:
              numSuccessfulRequests > 1
                ? t(`{0} user updates successful`, numSuccessfulRequests)
                : t(`{0} user update successful`, numSuccessfulRequests),
            delay: null,
          }),
        );
      }

      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      queryClient.invalidateQueries({ queryKey: ["client-users"] });
      queryClient.invalidateQueries({ queryKey: ["parent-client-users"] });
    }
  }

  const tableData = usersWithChanges.map(({ id, name, email /* roles */ }) => {
    // const rolesDropdownItems = availableRoles.map(({ id: roleId, name: roleName, description }) => ({
    //   id: roleId,
    //   label: roleName,
    //   description,
    //   selected: roles.includes(roleId),
    // }));

    // function handleSelectItem(roleId) {
    //   const isRoleSelected = roles.includes(roleId);
    //   let newRoles;
    //   if (isRoleSelected) newRoles = roles.filter((role) => role !== roleId);
    //   else newRoles = [...roles, roleId];
    //   localDispatch({ type: "update-user", field: "roles", id, value: newRoles });
    // }

    return [
      // NAME
      <Input
        key="name"
        placeholder={t(`Name`) + "..."}
        value={name}
        valid={() => validateField(name, id, "name")}
        invalidMsg={t(`Field cannot be empty`)}
        onChange={(value) => localDispatch({ type: "update-user", field: "name", id, value })}
      />,

      // EMAIL
      <Input key="email" value={email} type="email" disabled />,

      // ROLES
      // ...(isYou ? [] : [<Dropdown checkbox key="roles" items={rolesDropdownItems} onSelect={handleSelectItem} hideSelectAll />]),
    ];
  });

  function validateField(value, id, field) {
    // if invalid, return false (red)
    if (typeof value === "string" && !value) return false;

    // if valid and has changed, return true (green)
    const hasValueChanged = allUserUpdates.some((user) => user.id === id && user[field] === value);
    if (hasValueChanged) return true;

    // if hasn't changed, return null (neutral)
    return null;
  }

  return (
    <StyledModalCard
      open={open}
      modalRoot={modalRoot}
      title={users.length === 1 ? t(`Edit User: {0}`, users[0].name) : t(`Edit Users`)}
      onClose={onClose}
      closeOnClickBackdrop={false}
    >
      {tableData.length ? (
        <>
          <div className="table-wrapper">
            <Table headers={tableHeaders} data={tableData} />
          </div>

          <div className="action-buttons">
            <Button variant="secondary" onClick={onClose}>
              {t(`Cancel`)}
            </Button>
            <Button onClick={handleSaveChanges} disabled={allUserUpdates.length === 0}>
              {t(`Save Changes`)}
            </Button>
          </div>
        </>
      ) : (
        <div>{t(`No users selected`)}</div>
      )}
    </StyledModalCard>
  );
}
