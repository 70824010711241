import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { WelcomeHeader } from "./WelcomeHeader.jsx";
import { WelcomeSidebar } from "./WelcomeSidebar.jsx";

const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--dt-ui-page-bg);

  .main-area {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    flex-grow: 1;
    max-height: calc(100vh - var(--header-height));
    padding: 2.4rem 2.4rem 0 2.4rem;
    margin-bottom: 2.4rem;
    margin-left: 6rem;
    overflow-y: auto;
    background-color: var(--dt-ui-page-bg);

    &.dashboard-only {
      margin-left: 0;
    }
  }
`;

export function Home({ pages, children }) {
  const location = useLocation();

  const availablePages = pages.filter((page) => page.available);

  const currentPathName = location.pathname || "/dashboard";
  const currentPage = pages.find((page) => page.id === currentPathName);

  const productName = t(`ActiveAI Portal`);

  const dashboardOnly = availablePages.length === 1 && availablePages[0].id === "/dashboard";
  const dashboardOnlyClass = dashboardOnly ? " dashboard-only" : "";

  useEffect(() => {
    document.title = `${currentPage?.name ? `${currentPage.name} | ` : ""}Darktrace ${productName}`;
  }, [currentPage]);

  return (
    <StyledHome>
      <WelcomeSidebar selectedLinkId={currentPathName} links={availablePages} dashboardOnly={dashboardOnly} />
      <WelcomeHeader currentPage={currentPage} />
      <div className={`main-area${dashboardOnlyClass}`}>{children}</div>
    </StyledHome>
  );
}
