export const mockComments = [
  {
    id: "9cbfcd9f-e467-4fc0-8cf7-892eecd65a05",
    created_by: "9ca78d63-6be0-4652-97c2-69b79abd6651",
    commentable_type: "breaches",
    commentable_id: "9ca78d63-ca38-4d8a-b41d-76c9a16ccb60",
    content: "Test comment here!",
    created_at: "2024-08-12T15:20:24.000000Z",
    updated_at: "2024-08-12T15:20:24.000000Z",
  },
  {
    id: "9cc78a78-eb15-4477-a531-9ccde2bffa60",
    created_by: "9b9c2510-4361-41c5-8e4e-49ce18b2fd97",
    commentable_type: "breaches",
    commentable_id: "9ca78d63-ca38-4d8a-b41d-76c9a16ccb60",
    content: "Added a comment here",
    created_at: "2024-08-16T11:39:16.000000Z",
    updated_at: "2024-08-16T11:39:16.000000Z",
  },
  {
    id: "9cc78b4f-d6a2-42b3-8455-7abe1127cdb3",
    created_by: "9b9c2510-4361-41c5-8e4e-49ce18b2fd97",
    commentable_type: "breaches",
    commentable_id: "9ca78d63-ca38-4d8a-b41d-76c9a16ccb60",
    content: "Another comment here",
    created_at: "2024-08-16T11:41:36.000000Z",
    updated_at: "2024-08-16T11:41:36.000000Z",
  },
  {
    id: "9cc7acf1-121d-4f81-a163-99da894732b8",
    created_by: "9b9c2510-4361-41c5-8e4e-49ce18b2fd97",
    commentable_type: "breaches",
    commentable_id: "9ca78d63-ca38-4d8a-b41d-76c9a16ccb60",
    content:
      "Testing again Added a comment hereAdded a comment hereAdded a comment hereAdded a comment hereAdded a comme≈nt hereAdded a comment hereAdded a comment hereAdded a comment here",
    created_at: "2024-08-16T13:15:39.000000Z",
    updated_at: "2024-08-16T13:15:39.000000Z",
  },
];

export const mockTags = [
  { id: "9c1c711c-9916-4a38-a2d8-e129cd0a131e", label: "Test Tag", description: "A testy test tag" },
  {
    id: "9c1c711c-a4ed-4f87-892c-7bfc21e865b8",
    label: "Banking Trojan",
    description: "Tag for activity which appears to relate to banking trojan infections",
  },
  {
    id: "9c1c711c-a68f-4c9e-ac8d-1743b95b05d8",
    label: "Confirmed Compromise",
    description:
      "A tag to tag ptns which have uncovered confirmed compromises Note: A tag used by the monthly SOC reports, please do not apply unless certain this is compromise.",
  },
  {
    id: "9c1c711c-a880-4cd6-ad55-73207c3a18ed",
    label: "Insider Threat",
    description: "Tag for scenarios where customer confirms or it is clear that this is an insider attack",
  },
  {
    id: "9c1c711c-aa2a-46a8-80be-350fdd2bec24",
    label: "Account Issues",
    description: "Generic tag to highlight Account Issues - will be used for monthly service reports",
  },
  { id: "9c1c711c-abe6-46f5-89ac-d8de69a6dbf1", label: "SilverSculpture", description: "Tag to track the SilverSculpture project" },
  {
    id: "9c1c711c-ad42-4f8c-afb0-163ec6297bff",
    label: "Slow UI",
    description: "Tag to mark PTNs where access was slow to load the UI or the UI was slow",
  },
  { id: "9c1c711c-aed8-41fc-a30d-ff84ee092131", label: "IOT", description: "Tag to identify PTNs generated by suspected IOT compromises" },
  { id: "9c1c711c-b424-47a8-b6a3-0839c7f8f193", label: "Pentest", description: "Confirmed pentest or red teaming activity" },
  {
    id: "9c1c711c-b578-4ff0-8250-3acccd6c7db1",
    label: "Potential Ransomware",
    description:
      "Potential cases of ransomware that we are unable to confirm during triage - for example, we see one write of a recovery file which could be a backup of an old ransomware",
  },
  { id: "9c1c711c-b6d7-4aa1-a587-5fdea2a4d0db", label: "ICS", description: "A tag for any PTN related to ICS activity or devices" },
  {
    id: "9c1c711c-b82e-46bb-967f-17f9696c19bf",
    label: "Ransomware",
    description: "Tag for activity which appears to relate to ransomware infections",
  },
  { id: "9c1c711c-b982-4db7-825c-2cd7b1d47878", label: "trial", description: "Tag for PTN trial breaches" },
  { id: "9c1c711c-baab-48ca-867f-926f16d25b4f", label: "ShortBirthday", description: "Tag to track the ShortBirthday project" },
  {
    id: "9c1c711c-bbbd-4cc2-9e2d-80decef8f8e2",
    label: "Old Ransomware",
    description:
      "Tag used to track detections of old remnants of ransomware, for example files being moved during a backup that were encrypted in a breach previously, and not relating to live or new ransomware",
  },
  {
    id: "9c1c711c-bcf4-4d39-bfb5-dfa626de0bac",
    label: "Customer Compliment",
    description:
      "Tag to be used when an analyst receives positive feedback or a congratulatory note from a customer as a result of alerting a PTN.",
  },
  {
    id: "9c1c711c-be11-4933-8e99-3afc1e19f3db",
    label: "Spot Report",
    description: "Tag used for PTN that trigger a crisis support investigation resulting in a spot report.",
  },
  {
    id: "9c1c711c-bf50-4c9d-ae04-2a4e1c5dc24c",
    label: "Attack Sim",
    description:
      "Activity that appears to originate from a planned attack simulation from a known  attack sim vendor, such as Cymulate, AttackIQ, SafeBreach, KnowB4, or others.",
  },
  {
    id: "9c1c711c-c0a2-40be-a8a9-11f7f57cc66c",
    label: "Closed Deal",
    description: "Used for POV-PTN that resulted in a closed deal following the initial alert.",
  },
  {
    id: "9c1c711c-c1b3-46ac-b31f-39e66c708fcd",
    label: "Customer Success Issue",
    description:
      "Tracks alerts where the customer has contacted the account team with an issue in relating to this PTN. Might be a delay, inadvertent resolve, etc.",
  },
  {
    id: "9c1c711c-c2dd-4c2a-bbc4-a2b643d83614",
    label: "Out-of-Hours Attack",
    description: "Attack occurring out-of-hours, such as 17:00 local on a Friday.",
  },
  {
    id: "9c1c711c-c41e-4b80-b057-95e832f1ad1a",
    label: "Cryptomining",
    description: "This scenario involved evidence of crypto-mining activity",
  },
  {
    id: "9c1c711c-c556-4e2f-aa2c-edd255a108d0",
    label: "Gitlab Exploit",
    description: "Accounts where CVE-2021-22205 was likely exploited",
  },
  {
    id: "9c1c711c-c677-4220-bf2f-5bca40909a25",
    label: "Log4Shell Exploit",
    description: "Confirmed compromises involving Log4Shell/Log4j vulnerability",
  },
  {
    id: "9c1c711c-c799-418d-9fa9-774ffa16e832",
    label: "Adversary Campaign",
    description: "Tag used when specific indicators are observed across multiple accounts.",
  },
  {
    id: "9c1c711c-c8ce-40e9-9ddd-9f9bfa56545c",
    label: "Device Review",
    description: "Devices that frequently breach the same models. Will be used to identify recurring in SOCStats in order to tune PTN.",
  },
  { id: "9c1c711c-c9f0-46ea-97f3-c4fbe3c894ad", label: "LinenMars", description: "Tag to track the LinenMars project" },
  {
    id: "9c1c711c-cb41-4efd-8793-7bfb2e3ce94b",
    label: "Flag To SOC Lead",
    description: "Indicates that this PTN should be flagged to a SOC lead",
  },
  { id: "9c1c711c-cc74-437d-8c7f-0805d5df9041", label: "RelaxingOutline", description: "Tag to track the RelaxingOutline project" },
  {
    id: "9c1c711c-cda3-4261-bd87-bfea1f3dbc4c",
    label: "Account Team Tipped",
    description: "Tag to imply that the issue has been escalated to account team",
  },
  { id: "9c1c711c-ceee-41e5-89a2-acd45582c908", label: "Interesting Find", description: "Generic tag to track interesting finds on SOC" },
  {
    id: "9c1c711c-d02b-4d24-98e3-93c776baccaa",
    label: "ATE Followup",
    description: "This PTN led to further work done for the customer in Ask the Expert, for example through a Spot report",
  },
  { id: "9c1c711c-d17f-4f31-96c9-34b8ad19ad62", label: "Tracking Issues", description: "This PTN exhibited signs of device tracking" },
  {
    id: "9c1c711c-d2dd-4749-add6-a63282320386",
    label: "Unidirectional",
    description: "This model was raised partly due to issues in visibility causing unidirectional visibility",
  },
  {
    id: "9c1c711c-d43c-4f9c-bbc8-c27c841e9c1a",
    label: "Non Approved Model",
    description: "This PTN was due to a misconfigured customer model  coming into SOC that shouldnt be expected",
  },
  {
    id: "9c1c711c-d55b-4db5-814c-aa310924f17a",
    label: "Custom Model",
    description: "This is the result of a bespoke model that has been customised for a customer and approved through SOC",
  },
];

export const mockBreaches = [
  {
    id: "9ca78d63-ca38-4d8a-b41d-76c9a16ccb60",
    pbid: 6800,
    client_id: "9ca78d62-e2e2-4df7-813c-b7f69cc74081",
    appliance_id: "9ca78d63-8fa8-4fdd-8e43-b2e1907d91a5",
    hostname: "dt-6582-07",
    assignee_id: "9ca78d63-3a02-45db-a4f6-59d6c5a260ed",
    assignee_changed: "2024-07-31 13:41:22",
    terminal_comment_id: null,
    time: "2024-07-31T12:50:15.000000Z",
    score: 0.67,
    status: "resolved",
    status_changed: "2024-08-12 15:20:24",
    model_uuid: "45ceafcf-0e0c-350a-95b1-ca2e2913daa0",
    model_name: "Unusual Activity::Internal Connections",
    model_version: 33,
    model_modified_at: "2024-07-29 14:00:55",
    model_tags: null,
    device: '{"did": 6, "type": "phone", "ip_raw": "113.235.108.183", "hostname": "hyatt.com"}',
    triggered_components: [
      {
        device: { ip: "29.126.251.28", did: 892701675, hostname: "collins61.nader.info", typelabel: "Tablet" },
        triggeredFilters: [
          { trigger: { value: "Device / Anomaly Indicators / RDP Bruteforce Indicator" }, filterType: "New or uncommon occurence" },
          { trigger: { value: "1070244618" }, filterType: "Message" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Internal destination device name" },
          {
            trigger: {
              tag: {
                tid: 68,
                data: { auto: false, color: 177, visibility: "Public", description: "Voluptas consequatur delectus minus est minus." },
                name: "Gateway",
                thid: 68,
                expiry: 0,
                isReferenced: false,
              },
              value: 68,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "21.46.60.231", did: 274085773, hostname: "bartell676.parker.info", typelabel: "Wifi" },
        triggeredFilters: [
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "New or uncommon occurence" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Message" },
          { trigger: { value: "6" }, filterType: "Age of source" },
          { trigger: { value: "Anomalous Connection / SMB Enumeration" }, filterType: "Age of source" },
          {
            trigger: {
              tag: {
                tid: 43,
                data: { auto: false, color: 32, visibility: "Public", description: "Quidem dolorem quasi quia quibusdam sint qui." },
                name: "Re-Activated Device",
                thid: 43,
                expiry: 0,
                isReferenced: true,
              },
              value: 43,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "60.132.34.73", did: 224064457, hostname: "west162.kling.com", typelabel: "ICS PLC" },
        triggeredFilters: [
          { trigger: { value: "Device / Network Scan" }, filterType: "Internal destination device name" },
          { trigger: { value: "Device / Network Scan" }, filterType: "Matching metric list" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Message" },
          { trigger: { value: "Device / Anomaly Indicators / RDP Bruteforce Indicator" }, filterType: "Message" },
          { trigger: { value: "185578923" }, filterType: "Internal destination device name" },
          { trigger: { value: "Device / Network Scan" }, filterType: "Internal destination device name" },
          {
            trigger: {
              tag: {
                tid: 78,
                data: {
                  auto: false,
                  color: 101,
                  visibility: "Public",
                  description: "Dolor exercitationem reprehenderit illo quod possimus sed maiores tempore.",
                },
                name: "Re-Activated Device",
                thid: 78,
                expiry: 0,
                isReferenced: true,
              },
              value: 78,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
    ],
    created_at: "2024-07-31T14:00:56.000000Z",
    updated_at: "2024-08-16T16:52:42.000000Z",
    client: {
      id: "9ca78d62-e2e2-4df7-813c-b7f69cc74081",
      name: "Runte, Bins and Koepp",
      codename: "NecessitatibusDolore",
      country: "LV",
      region: "NA",
      has_ptn_service: 0,
      has_mdr_service: 1,
      has_ate_service: 0,
      created_at: "2023-11-26T20:32:53.000000Z",
      updated_at: "2024-02-01T17:38:26.000000Z",
    },
    appliance: {
      id: "9ca78d63-8fa8-4fdd-8e43-b2e1907d91a5",
      hostname: "dt-6582-07",
      sabre_version: "55899",
      client_id: "9ca78d62-e2e2-4df7-813c-b7f69cc74081",
      created_at: "2023-12-04T02:07:12.000000Z",
      updated_at: "2024-07-25T01:13:39.000000Z",
      boxassigntype: null,
    },
    assignee: {
      id: "9ca78d63-3a02-45db-a4f6-59d6c5a260ed",
      first_name: "Zoila",
      last_name: "Bosco",
      client_id: "9ca78d62-d8f7-43fa-ba95-3c831242a9a5",
      email: "wilkinson.joe@darktrace.com",
      full_name: "Zoila Bosco",
    },
  },
  {
    id: "9ca78d63-d175-4873-a698-913f735fc30b",
    pbid: 4367,
    client_id: "9ca78d62-e551-419e-8ac7-c5a3a0b5aebf",
    appliance_id: "9ca78d63-960d-4ba7-8d2e-b93bc27512d4",
    hostname: "dt-5327-08",
    assignee_id: "9ca78d63-7859-4dea-b395-04fb4003bb6c",
    assignee_changed: "2024-07-31 13:53:15",
    terminal_comment_id: null,
    time: "2024-07-31T12:36:30.000000Z",
    score: 0.26,
    status: "alerted",
    status_changed: null,
    model_uuid: "26a64294-5355-38ec-b609-33e12dcf82fd",
    model_name: "Anomalous Connection::Suspicious Read Write Ratio",
    model_version: 33,
    model_modified_at: "2024-07-29 14:00:56",
    model_tags: null,
    device: '{"did": 6, "type": "sensor", "ip_raw": "51.82.20.180", "hostname": "pollich.com"}',
    triggered_components: [
      {
        device: { ip: "29.89.42.152", did: 1012178326, hostname: "littel821.tremblay.com", typelabel: "Unknown" },
        triggeredFilters: [
          { trigger: { value: "929082484" }, filterType: "Stength" },
          { trigger: { value: "Device / Anomaly Indicators / RDP Bruteforce Indicator" }, filterType: "Matching metric list" },
          { trigger: { value: "91" }, filterType: "Age of source" },
          { trigger: { value: "1119977123" }, filterType: "Matching metric list" },
          {
            trigger: {
              tag: {
                tid: 59,
                data: { auto: true, color: 251, visibility: "Public", description: "Doloribus nihil numquam quia suscipit." },
                name: "Conflicting User-Agents",
                thid: 59,
                expiry: 0,
                isReferenced: true,
              },
              value: 59,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "142.8.121.44", did: 1886269984, hostname: "wehner347.oconnell.info", typelabel: "Laptop" },
        triggeredFilters: [
          { trigger: { value: "Device / Network Scan" }, filterType: "New or uncommon occurence" },
          { trigger: { value: "Device / Network Scan" }, filterType: "New or uncommon occurence" },
          { trigger: { value: "Device / Network Scan" }, filterType: "Stength" },
          {
            trigger: {
              tag: {
                tid: 66,
                data: { auto: false, color: 97, visibility: "Public", description: "Molestias in minima dicta." },
                name: "Conflicting User-Agents",
                thid: 66,
                expiry: 0,
                isReferenced: true,
              },
              value: 66,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "225.212.213.34", did: 489282808, hostname: "kuvalis221.kunde.info", typelabel: "IoT" },
        triggeredFilters: [
          { trigger: { value: "70" }, filterType: "New or uncommon occurence" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Message" },
          { trigger: { value: "Device / Anomaly Indicators / RDP Bruteforce Indicator" }, filterType: "Internal destination device name" },
          {
            trigger: {
              tag: {
                tid: 46,
                data: { auto: false, color: 223, visibility: "Public", description: "Placeat autem accusamus nobis iusto non quibusdam." },
                name: "Gateway",
                thid: 46,
                expiry: 0,
                isReferenced: true,
              },
              value: 46,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
    ],
    created_at: "2024-07-31T14:00:56.000000Z",
    updated_at: "2024-08-16T15:57:14.000000Z",
    client: {
      id: "9ca78d62-e551-419e-8ac7-c5a3a0b5aebf",
      name: "Auer-Ledner",
      codename: "VelAtque",
      country: "KE",
      region: "EMEA",
      has_ptn_service: 0,
      has_mdr_service: 1,
      has_ate_service: 1,
      created_at: "2024-07-24T12:45:16.000000Z",
      updated_at: "2024-06-05T00:01:04.000000Z",
    },
    appliance: {
      id: "9ca78d63-960d-4ba7-8d2e-b93bc27512d4",
      hostname: "dt-5327-08",
      sabre_version: "54627",
      client_id: "9ca78d62-e551-419e-8ac7-c5a3a0b5aebf",
      created_at: "2023-12-31T01:48:28.000000Z",
      updated_at: "2024-03-21T11:26:31.000000Z",
      boxassigntype: null,
    },
    assignee: {
      id: "9ca78d63-7859-4dea-b395-04fb4003bb6c",
      first_name: "Rubie",
      last_name: "Williamson",
      client_id: "9ca78d62-e4b7-454e-be17-d0f7b648bcdf",
      email: "adella.crooks@darktrace.com",
      full_name: "Rubie Williamson",
    },
  },
  {
    id: "9ca78d63-fd6e-4602-bdfa-09b411688550",
    pbid: 7284,
    client_id: "9ca78d62-e245-47e8-8083-68866e7531eb",
    appliance_id: "9ca78d63-8dfd-45f1-9f74-aaef5d80c36a",
    hostname: "aws-7901-66",
    assignee_id: "9ca78d63-376a-42f4-a4d1-8d6b88c9bb75",
    assignee_changed: "2024-07-31 12:14:13",
    terminal_comment_id: null,
    time: "2024-07-31T12:02:53.000000Z",
    score: 0.56,
    status: "alerted",
    status_changed: null,
    model_uuid: "1094bd6f-250c-3991-a702-7f5b43f90b3a",
    model_name: "Unusual Activity::Enhanced Unusual External Data Transfer",
    model_version: 33,
    model_modified_at: "2024-07-29 14:00:56",
    model_tags: null,
    device: '{"did": 9, "type": "desktop", "ip_raw": "151.79.59.171", "hostname": "rippin.com"}',
    triggered_components: [
      {
        device: { ip: "14.142.217.172", did: 740551042, hostname: "keeling157.hoppe.com", typelabel: "IoT" },
        triggeredFilters: [
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Age of source" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Age of source" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Age of source" },
          { trigger: { value: "Anomalous Connection / SMB Enumeration" }, filterType: "Message" },
          { trigger: { value: "4" }, filterType: "Internal destination device name" },
          {
            trigger: {
              tag: {
                tid: 99,
                data: { auto: true, color: 245, visibility: "Public", description: "Ab amet veniam natus placeat." },
                name: "Conflicting User-Agents",
                thid: 99,
                expiry: 0,
                isReferenced: true,
              },
              value: 99,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "69.99.65.75", did: 560461860, hostname: "becker72.littel.com", typelabel: "Unknown" },
        triggeredFilters: [
          { trigger: { value: "Anomalous Connection / SMB Enumeration" }, filterType: "Message" },
          { trigger: { value: "Anomalous Connection / SMB Enumeration" }, filterType: "Internal destination device name" },
          { trigger: { value: "Device / Network Scan" }, filterType: "New or uncommon occurence" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "New or uncommon occurence" },
          {
            trigger: {
              tag: {
                tid: 88,
                data: {
                  auto: true,
                  color: 255,
                  visibility: "Public",
                  description: "Nisi iure cum laboriosam vel doloribus libero non consectetur.",
                },
                name: "Re-Activated Device",
                thid: 88,
                expiry: 0,
                isReferenced: true,
              },
              value: 88,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "240.245.114.126", did: 1300996685, hostname: "swaniawski265.ziemann.com", typelabel: "Key Asset" },
        triggeredFilters: [
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Matching metric list" },
          { trigger: { value: "29" }, filterType: "Internal destination device name" },
          { trigger: { value: "Device / Network Scan" }, filterType: "Age of source" },
          {
            trigger: {
              tag: {
                tid: 91,
                data: { auto: true, color: 10, visibility: "Public", description: "Et est delectus ut dolorem exercitationem." },
                name: "Conflicting User-Agents",
                thid: 91,
                expiry: 0,
                isReferenced: true,
              },
              value: 91,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "47.159.153.153", did: 1359510488, hostname: "braun335.flatley.net", typelabel: "IoT" },
        triggeredFilters: [
          { trigger: { value: "38" }, filterType: "Message" },
          { trigger: { value: "86" }, filterType: "Matching metric list" },
          { trigger: { value: "Anomalous Connection / SMB Enumeration" }, filterType: "New or uncommon occurence" },
          {
            trigger: {
              tag: {
                tid: 4,
                data: { auto: false, color: 11, visibility: "Public", description: "Rerum sit deleniti delectus voluptate aut aperiam." },
                name: "Admin",
                thid: 4,
                expiry: 0,
                isReferenced: true,
              },
              value: 4,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "87.193.228.33", did: 1954985976, hostname: "rippin983.mante.com", typelabel: "Key Asset" },
        triggeredFilters: [
          { trigger: { value: "Device / Network Scan" }, filterType: "Age of source" },
          { trigger: { value: "87" }, filterType: "Internal destination device name" },
          { trigger: { value: "Device / Network Scan" }, filterType: "Internal destination device name" },
          { trigger: { value: "Device / Network Scan" }, filterType: "Message" },
          {
            trigger: {
              tag: {
                tid: 8,
                data: {
                  auto: false,
                  color: 174,
                  visibility: "Public",
                  description: "Nostrum voluptatem qui consequatur ad molestias est.",
                },
                name: "Admin",
                thid: 8,
                expiry: 0,
                isReferenced: false,
              },
              value: 8,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
    ],
    created_at: "2024-07-31T14:00:56.000000Z",
    updated_at: "2024-07-31T14:00:56.000000Z",
    client: {
      id: "9ca78d62-e245-47e8-8083-68866e7531eb",
      name: "Krajcik Group",
      codename: "SimiliqueOmnis",
      country: "GR",
      region: "APAC",
      has_ptn_service: 0,
      has_mdr_service: 1,
      has_ate_service: 0,
      created_at: "2024-05-09T18:27:58.000000Z",
      updated_at: "2023-10-04T23:01:31.000000Z",
    },
    appliance: {
      id: "9ca78d63-8dfd-45f1-9f74-aaef5d80c36a",
      hostname: "aws-7901-66",
      sabre_version: "54662",
      client_id: "9ca78d62-e245-47e8-8083-68866e7531eb",
      created_at: "2023-10-21T03:35:06.000000Z",
      updated_at: "2023-12-10T08:05:46.000000Z",
      boxassigntype: null,
    },
    assignee: {
      id: "9ca78d63-376a-42f4-a4d1-8d6b88c9bb75",
      first_name: "Dallas",
      last_name: "Greenfelder",
      client_id: "9ca78d62-e0f4-4625-b46e-40173c024e3f",
      email: "xbraun@darktrace.com",
      full_name: "Dallas Greenfelder",
    },
  },
  {
    id: "9ca78d64-0329-438a-9a89-8def84f2c101",
    pbid: 8549,
    client_id: "9ca78d62-e0f4-4625-b46e-40173c024e3f",
    appliance_id: "9ca78d63-92df-4c24-8c10-d88063071235",
    hostname: "dt-2357-01",
    assignee_id: "9ca78d63-7859-4dea-b395-04fb4003bb6c",
    assignee_changed: "2024-07-31 12:36:43",
    terminal_comment_id: null,
    time: "2024-07-31T12:16:11.000000Z",
    score: 0.76,
    status: "new",
    status_changed: null,
    model_uuid: "b055b44a-344f-3f28-b084-ece8c031d64b",
    model_name: "Compliance::Possible Cleartext Password in URI - External",
    model_version: 33,
    model_modified_at: "2024-07-29 14:00:56",
    model_tags: null,
    device: '{"did": 2, "type": "laptop", "ip_raw": "96.203.243.91", "hostname": "cruickshank.com"}',
    triggered_components: [
      {
        device: { ip: "199.204.254.13", did: 2056292240, hostname: "mcdermott193.koelpin.com", typelabel: "Laptop" },
        triggeredFilters: [
          { trigger: { value: "Device / Anomaly Indicators / RDP Bruteforce Indicator" }, filterType: "Matching metric list" },
          { trigger: { value: "Device / Network Scan" }, filterType: "Internal destination device name" },
          { trigger: { value: "375710963" }, filterType: "Matching metric list" },
          {
            trigger: {
              tag: {
                tid: 56,
                data: { auto: false, color: 80, visibility: "Public", description: "Voluptatem voluptatem magnam omnis est." },
                name: "Gateway",
                thid: 56,
                expiry: 0,
                isReferenced: true,
              },
              value: 56,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "70.237.137.151", did: 1351841729, hostname: "prohaska862.ondricka.com", typelabel: "File Server" },
        triggeredFilters: [
          { trigger: { value: "79" }, filterType: "Internal destination device name" },
          { trigger: { value: "Device / Anomaly Indicators / RDP Bruteforce Indicator" }, filterType: "Internal destination device name" },
          { trigger: { value: "10" }, filterType: "Age of source" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Matching metric list" },
          { trigger: { value: "1828287361" }, filterType: "Age of source" },
          { trigger: { value: "Anomalous Connection / SMB Enumeration" }, filterType: "Stength" },
          {
            trigger: {
              tag: {
                tid: 97,
                data: { auto: true, color: 103, visibility: "Public", description: "At vero est pariatur doloribus." },
                name: "Security Device",
                thid: 97,
                expiry: 0,
                isReferenced: true,
              },
              value: 97,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "253.34.69.153", did: 1155526570, hostname: "pacocha778.hilpert.com", typelabel: "Mobile" },
        triggeredFilters: [
          { trigger: { value: "Device / Anomaly Indicators / RDP Bruteforce Indicator" }, filterType: "Internal destination device name" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Internal destination device name" },
          { trigger: { value: "705574097" }, filterType: "Stength" },
          {
            trigger: {
              tag: {
                tid: 23,
                data: {
                  auto: true,
                  color: 163,
                  visibility: "Public",
                  description: "Suscipit qui assumenda corporis et magnam dolores voluptatum tempora.",
                },
                name: "Re-Activated Device",
                thid: 23,
                expiry: 0,
                isReferenced: false,
              },
              value: 23,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "190.143.141.255", did: 2114655675, hostname: "spinka277.leffler.com", typelabel: "Wifi" },
        triggeredFilters: [
          { trigger: { value: "Device / Network Scan" }, filterType: "Age of source" },
          { trigger: { value: "Device / Network Scan" }, filterType: "Matching metric list" },
          { trigger: { value: "88409291" }, filterType: "Stength" },
          {
            trigger: {
              tag: {
                tid: 22,
                data: {
                  auto: true,
                  color: 168,
                  visibility: "Public",
                  description: "Dolor minima maiores exercitationem voluptatibus consectetur fugiat ut.",
                },
                name: "Gateway",
                thid: 22,
                expiry: 0,
                isReferenced: true,
              },
              value: 22,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
    ],
    created_at: "2024-07-31T14:00:56.000000Z",
    updated_at: "2024-07-31T14:00:56.000000Z",
    client: {
      id: "9ca78d62-e0f4-4625-b46e-40173c024e3f",
      name: "Walsh LLC",
      codename: "EnimEst",
      country: "TW",
      region: "NA",
      has_ptn_service: 0,
      has_mdr_service: 0,
      has_ate_service: 1,
      created_at: "2024-01-15T20:09:49.000000Z",
      updated_at: "2024-01-10T16:57:51.000000Z",
    },
    appliance: {
      id: "9ca78d63-92df-4c24-8c10-d88063071235",
      hostname: "dt-2357-01",
      sabre_version: "53618",
      client_id: "9ca78d62-e0f4-4625-b46e-40173c024e3f",
      created_at: "2024-07-24T15:30:39.000000Z",
      updated_at: "2023-12-24T09:32:46.000000Z",
      boxassigntype: null,
    },
    assignee: null,
  },
  {
    id: "9ca78d64-08a3-4cf4-80cf-bdba31044af9",
    pbid: 7981,
    client_id: "9ca78d62-e551-419e-8ac7-c5a3a0b5aebf",
    appliance_id: "9ca78d63-960d-4ba7-8d2e-b93bc27512d4",
    hostname: "dt-5327-08",
    assignee_id: "9b9c2510-4361-41c5-8e4e-49ce18b2fd97",
    assignee_changed: "2024-07-31 13:42:08",
    terminal_comment_id: null,
    time: "2024-07-31T13:01:00.000000Z",
    score: 0.28,
    status: "inprogress",
    status_changed: null,
    model_uuid: "4df32938-9777-3f93-a948-dfda9d13401a",
    model_name: "SaaS::Compromise::Login From Rare Following Suspicious Login Attempt(s)",
    model_version: 33,
    model_modified_at: "2024-07-29 14:00:56",
    model_tags: null,
    device: '{"did": 3, "type": "sensor", "ip_raw": "84.217.205.156", "hostname": "mccullough.info"}',
    triggered_components: [
      {
        device: { ip: "190.62.131.122", did: 1744509126, hostname: "lang237.koch.com", typelabel: "Wifi" },
        triggeredFilters: [
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "New or uncommon occurence" },
          { trigger: { value: "Device / Anomaly Indicators / RDP Bruteforce Indicator" }, filterType: "Message" },
          { trigger: { value: "61" }, filterType: "Stength" },
          { trigger: { value: "Anomalous Connection / SMB Enumeration" }, filterType: "Matching metric list" },
          { trigger: { value: "6" }, filterType: "Message" },
          {
            trigger: {
              tag: {
                tid: 80,
                data: { auto: false, color: 238, visibility: "Public", description: "Eius sed totam eum magni enim est consequuntur." },
                name: "Gateway",
                thid: 80,
                expiry: 0,
                isReferenced: false,
              },
              value: 80,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "218.108.104.33", did: 197983401, hostname: "wehner100.zulauf.com", typelabel: "IoT" },
        triggeredFilters: [
          { trigger: { value: "49" }, filterType: "Age of source" },
          { trigger: { value: "90" }, filterType: "Stength" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "New or uncommon occurence" },
          { trigger: { value: "Device / Network Scan" }, filterType: "Internal destination device name" },
          { trigger: { value: "Device / Anomaly Indicators / RDP Bruteforce Indicator" }, filterType: "Internal destination device name" },
          { trigger: { value: "Device / Network Scan" }, filterType: "Internal destination device name" },
          {
            trigger: {
              tag: {
                tid: 36,
                data: { auto: false, color: 88, visibility: "Public", description: "Ratione aliquam officia aut facere quia." },
                name: "Conflicting User-Agents",
                thid: 36,
                expiry: 0,
                isReferenced: true,
              },
              value: 36,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
      {
        device: { ip: "62.219.245.38", did: 1244139697, hostname: "fisher537.collier.com", typelabel: "Unknown" },
        triggeredFilters: [
          { trigger: { value: "5" }, filterType: "New or uncommon occurence" },
          { trigger: { value: "Device / Attack and Recon Tools" }, filterType: "Stength" },
          { trigger: { value: "1467294927" }, filterType: "Stength" },
          {
            trigger: {
              tag: {
                tid: 26,
                data: { auto: true, color: 243, visibility: "Public", description: "Minima dolorem commodi possimus velit eaque aut." },
                name: "Re-Activated Device",
                thid: 26,
                expiry: 0,
                isReferenced: false,
              },
              value: 26,
            },
            filterType: "Tagged internal source",
          },
        ],
      },
    ],
    created_at: "2024-07-31T14:00:56.000000Z",
    updated_at: "2024-07-31T14:00:56.000000Z",
    client: {
      id: "9ca78d62-e551-419e-8ac7-c5a3a0b5aebf",
      name: "Auer-Ledner",
      codename: "VelAtque",
      country: "KE",
      region: "EMEA",
      has_ptn_service: 0,
      has_mdr_service: 1,
      has_ate_service: 1,
      created_at: "2024-07-24T12:45:16.000000Z",
      updated_at: "2024-06-05T00:01:04.000000Z",
    },
    appliance: {
      id: "9ca78d63-960d-4ba7-8d2e-b93bc27512d4",
      hostname: "dt-5327-08",
      sabre_version: "54627",
      client_id: "9ca78d62-e551-419e-8ac7-c5a3a0b5aebf",
      created_at: "2023-12-31T01:48:28.000000Z",
      updated_at: "2024-03-21T11:26:31.000000Z",
      boxassigntype: null,
    },
    assignee: {
      id: "9b9c2510-4361-41c5-8e4e-49ce18b2fd97",
      first_name: "Super",
      last_name: null,
      client_id: null,
      email: "super.cloud-soc@darktrace.com",
      full_name: "Super",
    },
  },
];

export const mockComponents = [
  {
    device: {
      ip: "192.168.0.1",
      did: 892701675,
      hostname: "test.domain.info",
      typelabel: "Tablet",
    },
    triggeredFilters: [
      {
        trigger: {
          value: "Device / Anomaly Indicators / RDP Bruteforce Indicator",
        },
        filterType: "New or uncommon occurence",
      },
      {
        trigger: {
          value: "1070244618",
        },
        filterType: "Message",
      },
      {
        trigger: {
          value: "Device / Attack and Recon Tools",
        },
        filterType: "Internal destination device name",
      },
      {
        trigger: {
          tag: {
            tid: 68,
            data: {
              auto: false,
              color: 177,
              visibility: "Public",
              description: "Voluptas consequatur delectus minus est minus.",
            },
            name: "Gateway",
            thid: 68,
            expiry: 0,
            isReferenced: false,
          },
          value: 68,
        },
        filterType: "Tagged internal source",
      },
    ],
  },
  {
    device: {
      ip: "192.168.0.1",
      did: 274085773,
      hostname: "test.another.info",
      typelabel: "Wifi",
    },
    triggeredFilters: [
      {
        trigger: {
          value: "Device / Attack and Recon Tools",
        },
        filterType: "New or uncommon occurence",
      },
      {
        trigger: {
          value: "Device / Attack and Recon Tools",
        },
        filterType: "Message",
      },
      {
        trigger: {
          value: "6",
        },
        filterType: "Age of source",
      },
      {
        trigger: {
          value: "Anomalous Connection / SMB Enumeration",
        },
        filterType: "Age of source",
      },
      {
        trigger: {
          tag: {
            tid: 43,
            data: {
              auto: false,
              color: 32,
              visibility: "Public",
              description: "Quidem dolorem quasi quia quibusdam sint qui.",
            },
            name: "Re-Activated Device",
            thid: 43,
            expiry: 0,
            isReferenced: true,
          },
          value: 43,
        },
        filterType: "Tagged internal source",
      },
    ],
  },
  {
    device: {
      ip: "192.0.168.1",
      did: 224064457,
      hostname: "example.host.com",
      typelabel: "ICS PLC",
    },
    triggeredFilters: [
      {
        trigger: {
          value: "Device / Network Scan",
        },
        filterType: "Internal destination device name",
      },
      {
        trigger: {
          value: "Device / Network Scan",
        },
        filterType: "Matching metric list",
      },
      {
        trigger: {
          value: "Device / Attack and Recon Tools",
        },
        filterType: "Message",
      },
      {
        trigger: {
          value: "Device / Anomaly Indicators / RDP Bruteforce Indicator",
        },
        filterType: "Message",
      },
      {
        trigger: {
          value: "185578923",
        },
        filterType: "Internal destination device name",
      },
      {
        trigger: {
          value: "Device / Network Scan",
        },
        filterType: "Internal destination device name",
      },
      {
        trigger: {
          tag: {
            tid: 78,
            data: {
              auto: false,
              color: 101,
              visibility: "Public",
              description: "Dolor exercitationem reprehenderit illo quod possimus sed maiores tempore.",
            },
            name: "Re-Activated Device",
            thid: 78,
            expiry: 0,
            isReferenced: true,
          },
          value: 78,
        },
        filterType: "Tagged internal source",
      },
    ],
  },
];

const mockProductGroups = [
  {
    id: "customer-portal-group-access",
    scope: "access",
    instanceId: "e2192868-62af-4941-b166-c56fb689f97f-customerportal-global",
  },
  {
    id: "client-with-only-own-ticket-access",
    scope: "client-with-only-own-ticket-access",
    instanceId: "e2192868-62af-4941-b166-c56fb689f97f-customerportal-global",
  },
  {
    id: "client-with-ticket-access",
    scope: "client-with-ticket-access",
    instanceId: "e2192868-62af-4941-b166-c56fb689f97f-customerportal-global",
  },
  {
    id: "client-with-education-access",
    scope: "client-with-education-access",
    instanceId: "e2192868-62af-4941-b166-c56fb689f97f-customerportal-global",
  },
  {
    id: "client-with-analyst-access",
    scope: "client-with-analyst-access",
    instanceId: "e2192868-62af-4941-b166-c56fb689f97f-customerportal-global",
  },
  {
    id: "client-with-download-access",
    scope: "client-with-download-access",
    instanceId: "e2192868-62af-4941-b166-c56fb689f97f-customerportal-global",
  },
  {
    id: "primary-user",
    scope: "primary-user",
    instanceId: "e2192868-62af-4941-b166-c56fb689f97f-customerportal-global",
  },
  {
    id: "dcrs-group-basic",
    scope: "basic",
    instanceId: "e2192868-62af-4941-b166-c56fb689f97f-dcrs-global",
  },
  {
    id: "dcrs-group-admin",
    scope: "admin",
    instanceId: "e2192868-62af-4941-b166-c56fb689f97f-dcrs-global",
  },
  {
    id: "prevent-asm-group-normal",
    scope: "normal",
    instanceId: "cSmMTlq7p29KJWDkekl2Y45h5s5ElmPA-prevent-asm-instance",
  },
  {
    id: "prevent-asm-group-admin",
    scope: "admin",
    instanceId: "cSmMTlq7p29KJWDkekl2Y45h5s5ElmPA-prevent-asm-instance",
  },
  {
    id: "dmarc-group-basic",
    scope: "basic",
    instanceId: "s7VGCGxn4Vc2sLSQp4OWg3YGCEHYzVG5-dmarc-instance",
  },
  {
    id: "dmarc-group-admin",
    scope: "admin",
    instanceId: "s7VGCGxn4Vc2sLSQp4OWg3YGCEHYzVG5-dmarc-instance",
  },
];

export const mockCCAs = Array.from({ length: 4 }).map((_, index) => {
  const start = Math.floor(Math.random() * mockProductGroups.length);
  const end = start + Math.floor(Math.random() * (mockProductGroups.length - start));

  return {
    id: `cca_${index + 1}`,
    name: `Example CCA ${index + 1}`,
    groups: mockProductGroups.slice(start, end),
  };
});

export const mockIntel = [
  {
    id: "144d9aef-ca7e-44c7-8254-3a6fd1d20155",
    rtline_id: 1820860274,
    client_id: "9ca78d62-e2e2-4df7-813c-b7f69cc74081",
    content:
      "Omnis quisquam aut quia. Et repellat et consequuntur error. Dolores amet laborum reprehenderit. Eos blanditiis et ipsum et aut nobis voluptas voluptatem.",
    type: "Technical",
    author_name: "Example User",
    created_at: "2022-12-07 09:22:48",
  },
  {
    id: "1b3fe4ab-ee30-4a10-87f4-bf61e7efcf7c",
    rtline_id: 530488052,
    client_id: "9ca78d62-e2e2-4df7-813c-b7f69cc74081",
    content:
      "Et deleniti corporis voluptatem ea sit. Cum earum non saepe illum id voluptatum ut nihil. Facilis sit rerum dolorum. Et doloremque rerum praesentium a eveniet.",
    type: "SOC",
    author_name: "Example User",
    created_at: "2023-06-10 19:24:37",
  },
  {
    id: "c1c12aa8-400d-4dfd-bb25-e908c0433615",
    rtline_id: 1685691544,
    client_id: "9ca78d62-e2e2-4df7-813c-b7f69cc74081",
    content: "Necessitatibus itaque vel voluptatem id vitae. Perferendis atque tempore tempore voluptatem.",
    type: "General",
    author_name: "Test User",
    created_at: "2024-04-03 13:47:28",
  },
];
