import React from "react";
import styled from "styled-components";
import { Chat, GhostElement, Modal } from "@darktrace/ui-components";
import { postPTNAlertsComment, usePTNAlertsComments } from "../../../logic/api";
import dayjs from "dayjs";
import { newToast } from "../../../logic/store";
import { useDispatch, useSelector } from "react-redux";

const StyledModalCard = styled(Modal)`
  .dt-ui-modal {
    width: 40vw;
    display: flex;
    flex-direction: column;
    .dt-ui-modal__contents {
      .empty {
        padding: 0.8rem;
      }
      .dt-ui-chat {
        .dt-ui-chat__messages {
          min-height: 35rem;
          padding-top: 0.8rem;
          .dt-ui-chat__bubble {
            background-color: var(--dt-ui-card-header-bg);
            margin: 0 0.8rem;
          }
        }
        .dt-ui-chat__textarea__wrapper {
          padding: 0.8rem;
          background-color: var(--dt-ui-card-header-bg);
        }
      }
    }
  }
`;

export function CommentsModal({ onClose = () => {}, open, breachId, ...attrs }) {
  const defaultClientId = useSelector((state) => state.app.defaultClientId);

  const modalRoot = document.querySelector("#modal-root");
  const dispatch = useDispatch();

  const { data: comments, isLoading, isError, isSuccess } = usePTNAlertsComments({ breachId, clientId: defaultClientId });

  function formatComments(comments) {
    const formattedComments = comments?.map(({ id, content, created_at }) => ({
      id,
      text: content,
      start: dayjs(created_at).valueOf(),
    }));
    return formattedComments;
  }

  function handleClose() {
    onClose();
  }

  async function handleSendMessage(content) {
    try {
      await postPTNAlertsComment({ breachId, content, clientId: defaultClientId });
    } catch (error) {
      dispatch(newToast({ variant: "error", title: t(`Unable to post comment`) }));
    }
  }

  return (
    <StyledModalCard modalRoot={modalRoot} onClose={handleClose} open={open} title={t(`Comments for alert #${breachId}`)} {...attrs}>
      {(() => {
        if (isLoading) {
          return <GhostElement />;
        }
        if (isError) {
          return <div>Failed to load comments.</div>;
        }
        if (isSuccess && comments?.length === 0) {
          return <div className="empty">No comments to show.</div>;
        }
        if (isSuccess) {
          return <Chat onSendMessage={handleSendMessage} messages={formatComments(comments)} />;
        }
        return null;
      })()}
    </StyledModalCard>
  );
}
