import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useSelector } from "react-redux";
import { TimeoutOverlay } from "./TimeoutOverlay.jsx";
import { Home } from "./Home.jsx";
import { loginToProduct, useMyMultiClientProductAccessInstancesStream } from "../logic/api.js";
import { ModalCard } from "@darktrace/ui-components";
import { ProductTile } from "./dashboard/ProductTile.jsx";

const StyledApp = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
  background-color: var(--dt-ui-page-bg);
`;

export function App({ pages }) {
  const location = useLocation();
  const navigate = useNavigate();
  if (location.pathname === "/") navigate("/dashboard");

  const showTimeoutOverlay = useSelector((state) => state.app.showTimeoutOverlay);
  const [showProductRedirectModal, setShowProductRedirectModal] = useState(false);

  // product autoredirect
  const localStorageProduct = useMemo(() => localStorage.getItem("product"), []);
  const clients = useSelector((state) => state.app.clients);

  const {
    data: multiClientProductAccessInstances = [],
    isFetching: isLoadingMultiClientProductAccessInstances,
    isFetched,
    isError,
  } = useMyMultiClientProductAccessInstancesStream({ clientIds: clients.map((client) => client.id), enabled: !!localStorageProduct });

  const instancesUserCanRedirectTo = multiClientProductAccessInstances.filter((instance) => {
    if (instance.instanceId === localStorageProduct) return true;
    if (instance.serviceType === localStorageProduct) return true;
    if (instance.serviceType?.split("-service-type")?.[0]?.toLowerCase() === localStorageProduct.toLowerCase()) return true;
    if (instance.serviceType?.split("sabre-")?.[1]?.toLowerCase() === localStorageProduct.toLowerCase()) return true;
    if (instance.instanceName?.toLowerCase() === localStorageProduct.toLowerCase()) return true;
    if (
      instance.instanceName.endsWith("Threat Visualiser") &&
      ["threat visualiser", "threat visualizer"].includes(localStorageProduct.toLowerCase())
    )
      return true;

    return false;
  });

  const isAutoredirecting = isFetched && !isLoadingMultiClientProductAccessInstances && instancesUserCanRedirectTo.length === 1 && !isError;
  const instancesHash = instancesUserCanRedirectTo.map((instance) => `${instance.instanceId}:${instance.clientId}`).join(",");

  useEffect(() => {
    const _isAutoredirecting =
      isFetched && !isLoadingMultiClientProductAccessInstances && instancesUserCanRedirectTo.length === 1 && !isError;
    if (_isAutoredirecting) {
      const instance = instancesUserCanRedirectTo[0];
      loginToProduct({ clientId: instance.clientId, instanceId: instance.instanceId, serviceId: instance.serviceId, newTab: false });
    }

    if (instancesUserCanRedirectTo.length > 1) setShowProductRedirectModal(true);
  }, [(instancesHash, isFetched, isLoadingMultiClientProductAccessInstances, instancesUserCanRedirectTo.length)]);

  if (isLoadingMultiClientProductAccessInstances) return null;
  if (isAutoredirecting) return null;
  else {
    return (
      <StyledApp>
        <Home pages={pages}>
          <Outlet />
          <TimeoutOverlay open={showTimeoutOverlay} />
          <ProductRedirectModal
            open={showProductRedirectModal}
            onClose={() => setShowProductRedirectModal(false)}
            instances={instancesUserCanRedirectTo}
          />
        </Home>
      </StyledApp>
    );
  }
}

const StyledProductRedirectModal = styled(ModalCard)`
  .content {
    display: grid;
    grid-template-columns: repeat(${(props) => Math.min(props.$instanceCount, 4) ?? 4}, 1fr);
    gap: 1.6rem;

    .product-tile-wrapper {
      display: grid;
      grid-template-rows: 1fr auto;
      max-width: 28rem;
    }
  }
`;

export function ProductRedirectModal({ open, onClose, instances }) {
  const modalRoot = document.querySelector("#modal-root");
  const clients = useSelector((state) => state.app.clients);

  // useEffect(() => {
  //   localStorage.removeItem("product");
  // }, []);

  return (
    <StyledProductRedirectModal
      title={t(`Go to Product`)}
      open={open}
      modalRoot={modalRoot}
      onClose={onClose}
      $instanceCount={instances?.length ?? 0}
    >
      <div className="content">
        {instances.map((instance) => (
          <div className="product-tile-wrapper" key={instance.instanceId}>
            <ProductTile {...instance} instances={[instance]} />
            <span>{clients.find((client) => client.id === instance.clientId).label}</span>
          </div>
        ))}
      </div>
    </StyledProductRedirectModal>
  );
}
