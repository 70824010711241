import React, { useEffect, useState, useTransition } from "react";
import { UsersTableCard } from "./UsersTableCard.jsx";

import {
  useActiveClientId,
  useQueryParams,
  useClientUsers,
  useParentClientUsers,
  useMutliClientUserAccessFlags,
} from "../../logic/hooks.js";
import { Button, Card, CollapsableCard, Dropdown, Tile /* TileContainer, */, Toast } from "@darktrace/ui-components";
import {
  useClientAvailableRoles,
  useClientSAMLProviders,
  useParentClientAvailableRoles,
  useUserOwnRoles,
  useUserProfileInfo,
} from "../../logic/api.js";
import { getPaginationStats, registrationStatuses } from "../../logic/util.js";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setIsSidepanelCollapsed } from "../../logic/store.js";
import { UserManagementSearch } from "./UserManagementSearch.jsx";
import { useNavigate } from "react-router-dom";
const StyledUserManagement = styled.div`
  display: flex;
  gap: 1.2rem;
  height: 100%;

  .sidepanel {
    .content {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .inner-card {
        width: 36.8rem;

        .tenant-card-contents {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 0.8rem;

          .dt-ui-dropdown-button:disabled {
            opacity: 100%;
            cursor: default;

            &:after {
              content: none;
            }
          }
        }

        .quick-filters-card-contents {
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
        }
      }

      .quick-filter-tile {
        .dt-ui-tile__header {
          padding: 0.8rem 1.2rem;
        }
      }
    }
  }

  .right-area {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: stretch;
    width: 0;
    flex-grow: 1;
    min-width: 44rem;

    .saml-enabled-toast {
      box-shadow: none;
      width: 100%;

      .toast-content {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        button {
          align-self: flex-end;
        }
      }
    }
  }
`;

export function UserManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.app.clients) ?? [];
  const [isPendingTransition, startTransition] = useTransition(); // eslint-disable-line

  const defaultClientId = useSelector((state) => state.app.defaultClientId); // user's own client (aka default client, parent client)
  const defaultClientLabel = clients.find((client) => client.id === defaultClientId)?.label;

  const activeClientId = useActiveClientId(); // globally active client
  // const activeClientLabel = _clients.find((client) => client.id === activeClientId)?.label;

  const { data: multiClientUserAccessFlags } = useMutliClientUserAccessFlags();

  const availableUserManagementClientIds = multiClientUserAccessFlags
    .filter(({ flags }) => flags.includes("user-management"))
    .map(({ clientId }) => clientId);
  const availableSSOConfigClientIds = multiClientUserAccessFlags
    .filter(({ flags }) => flags.includes("client-management:single-sign-on"))
    .map(({ clientId }) => clientId);

  const clientDropdownItems = clients.map((client) => ({
    id: client.id,
    label: client.label,
    selected: client.id === activeClientId,
    disabled: !availableUserManagementClientIds.includes(client.id),
  }));
  const canUserViewSSOConfigForActiveClient = availableSSOConfigClientIds.includes(activeClientId);
  const canUserViewUserManagementForActiveClient = availableUserManagementClientIds.includes(activeClientId);
  const canUserViewUserManagementForDefaultClient = availableUserManagementClientIds.includes(defaultClientId);

  const isViewingDefaultClient = defaultClientId === activeClientId;
  const isViewingChildClient = !isViewingDefaultClient;

  const { data: activeClientAvailableRoles = [] } = useClientAvailableRoles({
    clientId: activeClientId,
    enabled: canUserViewUserManagementForActiveClient,
  });
  const { data: parentClientAvailableRoles = [] } = useParentClientAvailableRoles({
    clientId: activeClientId,
    enabled: isViewingChildClient && canUserViewUserManagementForActiveClient && canUserViewUserManagementForDefaultClient,
  });

  const availableRoles = [...activeClientAvailableRoles, ...parentClientAvailableRoles];

  const { data = {} } = useClientSAMLProviders({ clientId: activeClientId });
  const isSAMLAuthenticationEnabled = data.providers?.length > 0;

  const groupedAvailableRoles = availableRoles.reduce((prevGroupedRoles, role) => {
    const roleGroupWithSameName = prevGroupedRoles.find((groupedRole) => groupedRole.name === role.name);
    if (roleGroupWithSameName) {
      roleGroupWithSameName.roleIds.push(role.id);
      return prevGroupedRoles;
    } else {
      prevGroupedRoles.push({ ...role, roleIds: [role.id] });
      return prevGroupedRoles;
    }
  }, []);

  const availableRoleIds = groupedAvailableRoles.map((role) => role.id);
  const allRoleIds = ["none", ...availableRoleIds];
  const availableStatusIds = Object.keys(registrationStatuses);
  const nonDisabledStatusIds = availableStatusIds.filter((statusId) => statusId !== "disabled");

  const defaultQueryParams = {
    page: 1,
    length: 100,
    roles: allRoleIds.join(","),
    registrationStatus: nonDisabledStatusIds.join(","),
    clientId: defaultClientId,
    orderDir: "asc",
  };

  const { queryParams, updateQueryParams } = useQueryParams({ defaultQueryParams });
  const currentPage = Number(queryParams["page"]);
  const pageSize = Number(queryParams["length"]);
  const orderDir = queryParams["orderDir"];

  /* FILTERS */
  // search
  const searchQueryParam = queryParams["search"];
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchQueryParam);

  // registrationStatus
  let selectedStatusFilterIds = queryParams["registrationStatus"] ? queryParams["registrationStatus"].split(",") : [];
  selectedStatusFilterIds = selectedStatusFilterIds.filter((statusId) => Object.keys(registrationStatuses).includes(statusId));
  if (selectedStatusFilterIds.length === 0) selectedStatusFilterIds = Object.keys(registrationStatuses);

  // roles
  let groupedSelectedRoleFilterIds = queryParams["roles"] ? queryParams["roles"].split(",") : [];
  groupedSelectedRoleFilterIds = groupedSelectedRoleFilterIds.filter((roleId) => allRoleIds.includes(roleId));
  let selectedRoleFilterIds = groupedSelectedRoleFilterIds.reduce((prevGroupedIds, id) => {
    if (id === "none") {
      prevGroupedIds.push(id);
      return prevGroupedIds;
    }

    const roleIdsToAdd = groupedAvailableRoles.find((groupedRole) => groupedRole.id === id).roleIds;
    prevGroupedIds.push(...roleIdsToAdd);
    return prevGroupedIds;
  }, []);
  if (selectedRoleFilterIds.length === 0) selectedRoleFilterIds = allRoleIds;
  const selectedActiveClientRoleFilterIds = [
    "none",
    ...selectedRoleFilterIds.filter((roleId) => activeClientAvailableRoles.some((role) => role.id === roleId)),
  ];
  // TODO: this filtering is not yet enabled on API side
  // eslint-disable-next-line
  const selectedParentClientRoleFilterIds = [
    "none",
    ...selectedRoleFilterIds.filter((roleId) => parentClientAvailableRoles.some((role) => role.id === roleId)),
  ];

  // users
  // active client users
  const {
    users: activeClientUsers = [],
    total: totalActiveClientUsers = 0,
    isLoading: isLoadingActiveClientUsers = false,
    isError: isErrorLoadingActiveClientUsers = false,
  } = useClientUsers({
    clientId: activeClientId,
    search: debouncedSearchValue,
    roles: selectedActiveClientRoleFilterIds,
    registrationStatus: selectedStatusFilterIds,
    orderDir: orderDir,
    disabled: !canUserViewUserManagementForActiveClient,
  });

  // parent client users
  const {
    users: _parentClientUsers = [],
    // total: _totalParentClientUsers = 0,
    isLoading: isLoadingParentClientUsers = false,
    isError: isErrorLoadingParentClientUsers = false,
  } = useParentClientUsers({
    clientId: activeClientId,
    search: debouncedSearchValue,
    roles: ["all"],
    registrationStatus: selectedStatusFilterIds,
    orderDir: orderDir,
    disabled: isViewingDefaultClient || !canUserViewUserManagementForActiveClient || !canUserViewUserManagementForDefaultClient,
  });

  // my own parent client user
  const shouldFetchOwnUserInfo =
    !isViewingDefaultClient && canUserViewUserManagementForActiveClient && !canUserViewUserManagementForDefaultClient;
  const { data: userProfileInfo = {} } = useUserProfileInfo();
  const { user = {}, alias: SAMLAlias } = userProfileInfo ?? {};
  const { data: userOwnRoles } = useUserOwnRoles({ clientId: activeClientId });

  const formattedOwnUser = shouldFetchOwnUserInfo
    ? {
        id: user.id,
        clientId: defaultClientId,
        clients: clients,
        email: user.email,
        jwksEnabled: true, // true since user is logged in they must be enabled 🤷🏾‍♂️
        name: user.name,
        registrationStatus: SAMLAlias ? "SAML" : "completed", // TODO: make SAML mean something
        roles: userOwnRoles,
      }
    : null;

  // clientId, clients, email, id, jwksEnabled, name, registrationStatus, roles;

  // if (totalActiveClientUsers !== activeClientUsers.length) console.error("NOT GETTING ALL ACTIVE CLIENT USERS");
  // if (_totalParentClientUsers !== _parentClientUsers.length) console.error("NOT GETTING ALL PARENT CLIENT USERS");

  // hide users that have no roles on the child client
  // TODO: do this via endpoint rather than filter on frontend
  const parentClientUsers = _parentClientUsers.filter((user) => user.childRoles.length > 0);
  const totalParentClientUsers = parentClientUsers.length;
  const total = totalParentClientUsers + totalActiveClientUsers;

  const { showingEntriesFrom, showingEntriesTo } = getPaginationStats(currentPage, total, pageSize);
  const combinedUsers = [...(formattedOwnUser ? [formattedOwnUser] : []), ...parentClientUsers, ...activeClientUsers];
  const visibleUsers = combinedUsers
    .toSorted((userA, userB) => {
      let position = 0;
      if (userA.email < userB.email) position = -1;
      if (userA.email > userB.email) position = 1;
      return position * (orderDir === "asc" ? 1 : -1);
    })
    .slice(showingEntriesFrom - 1, showingEntriesTo);

  const isLoading = isLoadingParentClientUsers || isLoadingActiveClientUsers;
  const isError = isErrorLoadingParentClientUsers || isErrorLoadingActiveClientUsers;

  // reset page when changing any filters
  useEffect(() => {
    startTransition(() => updateQueryParams({ page: 1 }));
  }, [debouncedSearchValue, activeClientId, selectedRoleFilterIds.join(","), selectedStatusFilterIds.join(",")]);

  const quickFilters = groupedAvailableRoles.map((role) => ({
    ...role,
    quickFilterId: `role-${role.id}`,
    // only selected if other filters are default and this one is exact match
    selected: queryParams["roles"] === role.id && queryParams["registrationStatus"] === defaultQueryParams["registrationStatus"],
  }));
  const selectedQuickFilterIds = quickFilters.filter((quickFilter) => quickFilter.selected).map((quickFilter) => quickFilter.quickFilterId);

  // function handleSelectQuickFilter(quickFilterId) {
  //   // roles
  //   if (quickFilterId.startsWith("role-")) {
  //     if (selectedQuickFilterIds.includes(quickFilterId)) {
  //       updateQueryParams({ roles: null });
  //     } else {
  //       const roleId = quickFilterId.substring("role-".length);
  //       updateQueryParams({ registrationStatus: null, roles: roleId });
  //     }
  //   }
  // }

  const isSidepanelCollapsed = useSelector((state) => state.app.isSidepanelCollapsed);

  // STATUS FILTERS
  const statusFilterDropdownItems = Object.entries(registrationStatuses).map(([statusId, { text }]) => ({
    id: statusId,
    label: text,
    selected: selectedStatusFilterIds.includes(statusId),
    disabled: selectedStatusFilterIds.includes(statusId) && selectedStatusFilterIds.length === 1,
  }));

  const disableSelectAllStatusesCheckbox = selectedStatusFilterIds.length === statusFilterDropdownItems.length;

  function handleSelectStatusFilter(id) {
    const newStatusFilterIds = selectedStatusFilterIds.includes(id)
      ? selectedStatusFilterIds.filter((_id) => _id !== id)
      : [...selectedStatusFilterIds, id];

    newStatusFilterIds.sort((a, b) => {
      return Object.keys(registrationStatuses).indexOf(a) < Object.keys(registrationStatuses).indexOf(b) ? -1 : 1;
    });

    updateQueryParams({ registrationStatus: newStatusFilterIds.join(",") });
  }
  function handleSelectAllStatusFilters() {
    const newStatusFilterIds =
      selectedStatusFilterIds.length === statusFilterDropdownItems.length ? [] : statusFilterDropdownItems.map((item) => item.id);

    updateQueryParams({ registrationStatus: newStatusFilterIds.join(",") });
  }

  // ROLE FILTERS
  // const allAvailableRoles = [{ id: "none", name: "None" }, ...(groupedAvailableRoles ?? [])];
  // const selectedGroupedRoleIds = allAvailableRoles.filter(({ id }) => selectedRoleFilterIds.includes(id)).map(({ id }) => id);

  // const rolesFilterDropdownItems = allAvailableRoles.map(({ id, name }) => ({
  //   id,
  //   label: name,
  //   selected: selectedGroupedRoleIds.includes(id),
  //   disabled: selectedGroupedRoleIds.includes(id) && selectedGroupedRoleIds.length === 1,
  // }));

  // const disableSelectAllRolesCheckbox = selectedRoleFilterIds.length === rolesFilterDropdownItems.length;

  // function handleSelectRoleFilter(id) {
  //   const newRoleFilterIds = selectedRoleFilterIds.includes(id)
  //     ? selectedRoleFilterIds.filter((_id) => _id !== id)
  //     : [...selectedRoleFilterIds, id];

  //   newRoleFilterIds.sort((a, b) => {
  //     return groupedAvailableRoles.findIndex((roleGroup) => roleGroup.id === a) <
  //       groupedAvailableRoles.findIndex((roleGroup) => roleGroup.id === b)
  //       ? -1
  //       : 1;
  //   });

  //   updateQueryParams({ roles: newRoleFilterIds.join(",") });
  // }
  // function handleSelectAllRoleFilters() {
  //   const newRoleFilterIds =
  //     selectedRoleFilterIds.length === rolesFilterDropdownItems.length ? [] : rolesFilterDropdownItems.map((item) => item.id);

  //   updateQueryParams({ roles: newRoleFilterIds.join(",") });
  // }

  // TODO: products access filters, requires new API endpoint updates

  return (
    <StyledUserManagement>
      <CollapsableCard
        className="sidepanel"
        title={t(`Darktrace ActiveAI Security Portal`)}
        width={"40rem"}
        style={{ flexShrink: "0" }}
        container={"strong"}
        controlled
        collapsed={isSidepanelCollapsed}
        onClickCollapsedToggle={() => dispatch(setIsSidepanelCollapsed(!isSidepanelCollapsed))}
      >
        <div className="content">
          {isSidepanelCollapsed ? (
            <Tile className="collapsed-filter-tile" icon="buildings" onClick={() => dispatch(setIsSidepanelCollapsed(false))} noColorBar />
          ) : (
            <Card className="inner-card" title={t(`Tenant`)} icon={"buildings"}>
              <div className="tenant-card-contents">
                <Dropdown displayValue={defaultClientLabel} disabled />
                {clientDropdownItems.some((item) => item.id !== defaultClientId) && (
                  <Dropdown
                    label="View Users for:"
                    items={clientDropdownItems}
                    onSelect={(id) => updateQueryParams({ clientId: id })}
                    hasSearch
                  />
                )}
              </div>
            </Card>
          )}

          {canUserViewUserManagementForActiveClient &&
            (isSidepanelCollapsed ? (
              <Tile
                className="collapsed-filter-tile"
                icon="users"
                onClick={() => dispatch(setIsSidepanelCollapsed(false))}
                noColorBar
                active={selectedQuickFilterIds.length > 0}
              />
            ) : (
              <Card className="inner-card" icon="users" title={t(`Quick Filters`)}>
                <div className="quick-filters-card-contents">
                  {/* search */}
                  <UserManagementSearch onSearchValueChange={setDebouncedSearchValue} />
                  {/* registration status */}
                  <Dropdown
                    checkbox
                    label="Registration Status:"
                    placeholder="Not filtering by Status"
                    items={statusFilterDropdownItems}
                    onSelect={handleSelectStatusFilter}
                    onSelectAll={handleSelectAllStatusFilters}
                    disableSelectAll={disableSelectAllStatusesCheckbox}
                  />

                  {/* role */}
                  {/* <Dropdown
                  checkbox
                  disabled
                  placeholder="Not filtering by Role"
                  items={rolesFilterDropdownItems}
                  onSelect={handleSelectRoleFilter}
                  onSelectAll={handleSelectAllRoleFilters}
                  disableSelectAll={disableSelectAllRolesCheckbox}
                /> */}

                  {/* product access */}
                  {/* <Dropdown checkbox disabled placeholder="Not filtering by Product Access" onSelect={() => {}} hideSelectAll /> */}

                  {/* quick filters */}
                  {/* <TileContainer>
                  {quickFilters.map((quickFilter) => {
                    return (
                      <Tile
                        key={quickFilter.quickFilterId}
                        className="quick-filter-tile"
                        noColorBar
                        icon="filter"
                        header={`User role = ${quickFilter.name}`}
                        active={quickFilter.selected}
                        onClick={() => handleSelectQuickFilter(quickFilter.quickFilterId)}
                      />
                    );
                  })}
                </TileContainer> */}
                </div>
              </Card>
            ))}
        </div>
      </CollapsableCard>

      <div className="right-area">
        {isSAMLAuthenticationEnabled && canUserViewSSOConfigForActiveClient && (
          <Toast
            className="saml-enabled-toast"
            title={
              clients.length > 1 ? t(`Note: SSO Authentication is enabled for this tenant.`) : t(`Note: SAML Authentication is enabled.`)
            }
            text={
              <div className="toast-content">
                <span>{t(`To view and manage access for users logging in with SSO, go to the SSO Configuration page.`)}</span>
                <Button
                  onClick={() => navigate(activeClientId ? `/sso-config?clientId=${activeClientId}` : `/sso-config`)}
                  icon="solid-key-gear fak"
                  variant="secondary-info"
                >
                  {t(`View SSO Configuration`)}
                </Button>
              </div>
            }
          />
        )}

        {canUserViewUserManagementForActiveClient ? (
          <UsersTableCard
            users={isLoading ? [] : visibleUsers}
            orderDir={orderDir}
            groupedAvailableRoles={groupedAvailableRoles}
            activeClientAvailableRoles={activeClientAvailableRoles}
            parentClientAvailableRoles={parentClientAvailableRoles}
            numTotalUsers={total}
            isLoading={isLoading}
            isError={isError}
            currentPage={currentPage}
            pageSize={pageSize}
            searchValue={debouncedSearchValue}
            selectedStatusFilterIds={selectedStatusFilterIds}
            selectedRoleFilterIds={selectedRoleFilterIds}
            updateQueryParams={updateQueryParams}
          />
        ) : (
          <div>You cannot view User Management for this client. Choose another client.</div>
        )}
      </div>
    </StyledUserManagement>
  );
}
