import { Button, Card, CollapsableCard, Dropdown, IconButton, ModalCard, Tile, TileContainer, Tooltip } from "@darktrace/ui-components";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Confirmation } from "../shared/Confirmation.jsx";
import styled from "styled-components";
import { SAMLProvider } from "./SAMLProvider.jsx";
import { useDeleteSAMLProviderMutation } from "../../logic/api.js";
import { setIsSidepanelCollapsed } from "../../logic/store.js";

const StyledSSOConfigSidepanel = styled(CollapsableCard)`
  .content {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .inner-card {
      width: 36.8rem;
    }

    .tenant-card-contents {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 0.8rem;

      .dt-ui-dropdown-button:disabled {
        opacity: 100%;
        cursor: default;

        &:after {
          content: none;
        }
      }
    }

    .saml-card-content {
      display: flex;
      flex-direction: column;

      .dt-ui-tile-container {
        gap: 1.2rem;
        margin-right: 0.8rem;
      }

      .add-saml-provider-button {
        width: 100%;
      }

      .saml-provider-actions {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
      }

      .saml-providers {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        .saml-provider-tile {
          .dt-ui-tile__header {
            padding-block: 0.4rem;
          }
        }
        .saml-provider-dropdown {
          flex-grow: 1;
        }

        .provider-actions {
          display: flex;
          gap: 0.8rem;
        }
      }
    }
  }
`;

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    width: 90rem;
  }
`;

export function SSOConfigSidepanel({
  clients,
  availableClients,
  activeClientId,
  updateQueryParams,
  clientSamlProviders,
  selectedSamlProviderId,
  setSelectedSamlProviderId,
  selectedSamlProviderInfo,
  isEditingProvider,
  isFetchingProviders,
  setIsEditingProvider,
}) {
  const dispatch = useDispatch();
  const isSidepanelCollapsed = useSelector((state) => state.app.isSidepanelCollapsed);

  const defaultClientId = useSelector((state) => state.app.defaultClientId); // user's own client (aka default client, parent client)
  const defaultClientLabel = clients.find((client) => client.id === defaultClientId)?.label;

  const canUserViewSSOConfigForActiveClient = availableClients.some((client) => client.id === activeClientId);

  const samlProviderDropdownItems = clientSamlProviders?.map(({ id, alias }) => ({
    id,
    label: alias,
    selected: selectedSamlProviderId === id,
  }));

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isCreatingProvider, setIsCreatingProvider] = useState(false);

  const { mutate: deleteSAMLProviderMutation = [], isPending: isDeletingSAMLProvider } = useDeleteSAMLProviderMutation({
    id: selectedSamlProviderId,
    onSuccess: () => {
      setShowDeleteConfirmation(false);
      setIsEditingProvider(false);
    },
  });

  function resetModalContent() {
    setIsEditingProvider(false);
    setIsCreatingProvider(false);
  }

  const deleteButton = (
    <IconButton
      icon="trash"
      variant="tertiary"
      onClick={() => setShowDeleteConfirmation((prev) => !prev)}
      active={showDeleteConfirmation}
    />
  );

  return (
    <>
      <StyledSSOConfigSidepanel
        className="sidepanel"
        title={t(`Darktrace ActiveAI Portal`)}
        width={"40rem"}
        style={{ flexShrink: "0" }}
        container={"strong"}
        controlled
        collapsed={isSidepanelCollapsed}
        onClickCollapsedToggle={() => dispatch(setIsSidepanelCollapsed(!isSidepanelCollapsed))}
      >
        <div className="content">
          {isSidepanelCollapsed ? (
            <Tile className="collapsed-filter-tile" icon="buildings" onClick={() => dispatch(setIsSidepanelCollapsed(false))} noColorBar />
          ) : (
            <Card className="inner-card" title={t(`Tenant`)} icon={"buildings"}>
              <div className="tenant-card-contents">
                <Dropdown displayValue={defaultClientLabel} disabled />
                {availableClients.some((client) => client.id !== defaultClientId) && (
                  <Dropdown
                    label="SSO Providers for:"
                    items={availableClients}
                    onSelect={(id) => updateQueryParams({ clientId: id })}
                    hasSearch
                  />
                )}
              </div>
            </Card>
          )}

          {canUserViewSSOConfigForActiveClient &&
            (isSidepanelCollapsed ? (
              <Tile className="collapsed-filter-tile" icon="users" onClick={() => dispatch(setIsSidepanelCollapsed(false))} noColorBar />
            ) : (
              <Card className="inner-card" icon="users" title={t(`SAML Provider`)}>
                <div className="saml-card-content">
                  <div className="saml-provider-actions">
                    {samlProviderDropdownItems.length > 0 && (
                      <div className="saml-providers">
                        <TileContainer>
                          {samlProviderDropdownItems.map((samlProvider) => {
                            return (
                              <Tile
                                as="div"
                                className="saml-provider-tile"
                                key={samlProvider.id}
                                header={samlProvider.label}
                                noColorBar
                                headerRight={
                                  <div className="provider-actions">
                                    <Tooltip text={t(`Edit Configuration`)}>
                                      <IconButton
                                        icon="pencil"
                                        onClick={() => setIsEditingProvider(true)}
                                        variant="tertiary"
                                        active={isEditingProvider}
                                      />
                                    </Tooltip>
                                    <Tooltip text={t(`Delete Configuration`)}>{deleteButton}</Tooltip>
                                  </div>
                                }
                              />
                            );
                          })}
                        </TileContainer>
                      </div>
                    )}

                    {!isFetchingProviders && samlProviderDropdownItems.length === 0 && (
                      <Button
                        size="small"
                        className={`add-saml-provider-button${selectedSamlProviderId ? " provider-selected" : ""}`}
                        icon="plus"
                        onClick={() => setIsCreatingProvider(true)}
                        active={isCreatingProvider}
                      >
                        {t(`Add SAML Provider`)}
                      </Button>
                    )}
                  </div>
                </div>
              </Card>
            ))}
        </div>
      </StyledSSOConfigSidepanel>

      {/* MODALS */}
      {/* Add SAML Provider */}
      <StyledModalCard open={isCreatingProvider} onClose={resetModalContent} title={t(`Add SAML Provider`)}>
        <SAMLProvider isNew onClose={resetModalContent} setSelectedSamlProviderId={setSelectedSamlProviderId} />
      </StyledModalCard>

      {/* Edit SAML Provider */}
      <StyledModalCard
        open={isEditingProvider}
        onClose={resetModalContent}
        title={t(`Edit SAML Provider`)}
        titleRight={<Tooltip text={t(`Delete SAML Configuration`)}>{deleteButton}</Tooltip>}
      >
        <SAMLProvider id={selectedSamlProviderId} isEditing isDeletingSAMLProvider={isDeletingSAMLProvider} onClose={resetModalContent} />
      </StyledModalCard>

      {/* Confirm Custom Mappi ng for SAML Provider */}
      <Confirmation
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title={t(`Are you sure?`)}
        message={t(`Are you sure you want to skip custom mapping?`)}
        confirmButtonText={t(`I'm sure`)}
      />

      {/* Confirm Delete SAML Provider */}
      <Confirmation
        title={t(`Delete Provider`)}
        message={t(`Are you sure you want to delete provider {0}`, selectedSamlProviderInfo.alias) + "?"}
        onClose={() => setShowDeleteConfirmation(false)}
        open={showDeleteConfirmation}
        onConfirm={deleteSAMLProviderMutation}
      />
    </>
  );
}
