import { Input, Switch, Textarea, Tooltip } from "@darktrace/ui-components";
import React from "react";
import styled from "styled-components";

import { CopyInput } from "../shared/CopyInput.jsx";

const StyledSAMLProviderConfiguration = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .sso-field {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    /* label */
    > :nth-child(odd) {
      font-weight: bold;
      display: flex;
      gap: 0.8rem;
      align-self: start;
      align-items: center;
    }

    /* field */
    > :nth-child(even) {
      align-self: center;
      min-height: 2.8rem;
      width: 100%;

      &.text {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      textarea {
        height: 18.8rem;
      }

      &.switch {
        .dt-ui-switch-container {
          padding-inline: 0;
        }
      }
    }
  }
`;

export function SAMLProviderConfiguration({
  isNew = false,
  isEditing = false,
  samlProviderConfig = {},
  accessTypes = [],
  logoutUrl,
  loginUrl,
  onChangeField = () => {},
}) {
  const isViewing = !isEditing && !isNew;

  const ssoFields = [
    ...(!isViewing
      ? []
      : [
          {
            label: t(`SAML Login URL`),
            value: loginUrl,
            type: "input",
            tooltip: t(
              `This url is also used as the Assertion Consumer Service (ACS) URL. This value needs to be provided to your ID Provider`,
            ),
          },
          {
            label: t(`SAML Logout URL`),
            value: logoutUrl,
            type: "input",
            tooltip: t(`This value needs to be provided to your ID Provider`),
          },
        ]),
    {
      label: t(`SAML Configuration XML`),
      placeholder: t(`Enter SAML Configuration XML`),
      fieldName: "samlXmlMetadata",
      type: "textarea",
      tooltip: t(`Insert the SAML metadata XML from your ID Provider`),
    },
    {
      label: t(`SAML Username Attribute Name`),
      placeholder: t(`Enter SAML Username Attribute Name`),
      fieldName: "usernameAttributeName",
      type: "input",
      tooltip: t(
        `The attribute in the SAML response that should be used as the username within Darktrace. The NameID format expected is urn:oasis:names:tc:SAML:1.0:nameid-format:unspecified.`,
      ),
    },
    {
      label: t(`SAML Email Attribute Name`),
      placeholder: t(`Enter SAML Email Attribute Name`),
      fieldName: "emailAttributeName",
      type: "input",
      tooltip: t(
        `The attribute in the SAML response that should be used as the email within Darktrace. The NameID format expected is urn:oasis:names:tc:SAML:1.0:nameid-format:unspecified.`,
      ),
    },
    {
      label: t(`SAML Group Attribute Name`),
      placeholder: t(`Enter SAML Group Attribute Name`),
      fieldName: "groupAttributeName",
      type: "input",
      tooltip: t(`The attribute in the SAML response which contains the group value.`),
    },
    {
      label: t(`SAML Authentication Group(s)`),
      placeholder: t(`Enter SAML Authentication Group(s)`),
      fieldName: "authenticationGroups",
      type: "input",
      tooltip: t(
        `CSV of Groups which should be permitted to access the ActiveAI Security Portal. Wildcards are supported. Values are case sensitive.`,
      ),
    },
    {
      label: t(`Alias`),
      placeholder: t(`Enter Alias`),
      fieldName: "alias",
      type: "input",
      // tooltip: "???",
    },
  ];

  return (
    <StyledSAMLProviderConfiguration>
      {accessTypes.length > 0 && <TransparentModeSSOField accessTypes={accessTypes} onChangeField={onChangeField} />}

      {ssoFields.map((field) => (
        <SSOField
          {...field}
          value={field.value ?? samlProviderConfig[field.fieldName]}
          onChange={(value) => onChangeField(field.fieldName, value)}
          key={field.label}
          isViewing={isViewing}
        />
      ))}
    </StyledSAMLProviderConfiguration>
  );
}

function SSOField({ label, placeholder, value, onChange = () => {}, type, tooltip, isViewing }) {
  return (
    <div className="sso-field">
      <div className="key">
        <span>{label}</span>
        {tooltip && <Tooltip text={tooltip} />}
      </div>
      {type === "input" && (
        <CopyInput
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          inputAttrs={{ readOnly: isViewing }}
          isViewing={isViewing}
          Component={Input}
        />
      )}
      {type === "textarea" && (
        <CopyInput
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          resize="none"
          readOnly={isViewing}
          textareaAttrs={{ readOnly: isViewing }}
          isViewing={isViewing}
          Component={Textarea}
        />
      )}
      {type === "string" && (
        <div className="text">
          <span>{value}</span>
        </div>
      )}
    </div>
  );
}

function TransparentModeSSOField({ accessTypes, onChangeField }) {
  const sortedAccessTypes = accessTypes.toSorted((accessTypeA, accessTypeB) =>
    accessTypeA.availableMethods.includes("saml-whitelist-groups")
      ? -1
      : accessTypeB.availableMethods.includes("saml-whitelist-groups")
        ? 1
        : 0,
  );

  const baseFieldName = "samlGroupTransparentMode";
  return (
    <div className="sso-field">
      <div className="key">
        <span>{t(`Pass SAML Groups Transparently`)}</span>
        {/* {tooltip && <Tooltip text={tooltip} />} */}
      </div>

      <div className="switches">
        {/* <span>{text}</span> */}
        {sortedAccessTypes.map((accessType) => (
          <Switch
            key={accessType.accessType}
            checked={accessType.method === "saml-whitelist-groups"}
            disabled={!accessType.availableMethods.includes("saml-whitelist-groups")}
            label={accessType.displayName}
            onChange={(value) =>
              onChangeField(
                `${baseFieldName}::${accessType.accessType}`,
                value
                  ? "saml-whitelist-groups"
                  : accessType.availableMethods.find((availableMethod) => availableMethod !== "saml-whitelist-groups"),
              )
            }
          />
        ))}
      </div>
    </div>
  );
}
