import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { Button, Dropdown, GhostElement, SearchField, TileContainer } from "@darktrace/ui-components";
import { Alert } from "./AlertTile.jsx";
import { usePTNAlerts } from "../../../logic/api.js";
import { useSelector } from "react-redux";

const StyledPTNAlerts = styled.div`
  overflow: hidden;
  height: 100%;
  #alerts-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .dt-ui-tile-container,
  .alerts-wrapper {
    padding: 1.6rem;
    height: calc(100% - 8.6rem);
    overflow: scroll;
    .dt-ui-ghost-element {
      height: 100%;
    }
  }
`;

const StyledFilters = styled.div`
  height: fit-content;
  border-bottom: 1px solid var(--dt-ui-card-border);
  display: grid;
  align-items: end;
  padding: 1.6rem;
  padding-bottom: 2.2rem;
  gap: 0.8rem;
  grid-template-columns: repeat(4, 1fr) auto;
  p {
    font-size: 1rem;
    font-weight: 600;
  }
`;

export function PTNAlerts() {
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const dropdownConfigs = [
    {
      id: "Type",
      items: [],
      onSelect: (value) => handleSelect("Type", value),
    },
    {
      id: "Status",
      items: [],
      onSelect: (value) => handleSelect("Status", value),
    },
    {
      id: "Region",
      items: [],
      onSelect: (value) => handleSelect("Region", value),
    },
  ];

  const handleSelect = (dropdownId, selectedValue) => {
    console.log(`Selected value from ${dropdownId}: ${selectedValue}`);
  };

  const {
    data: alerts,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePTNAlerts({ clientId: defaultClientId });

  // Infinite scrolling logic
  const observerRef = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading || isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            // TO DO debounce this?
            fetchNextPage();
          }
        },
        { threshold: 1.0 },
      );

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage],
  );

  return (
    <StyledPTNAlerts id="ptn-alerts">
      <StyledFilters>
        {dropdownConfigs.map(({ id, items, onSelect }) => (
          <span key={id}>
            <p>{id}</p>
            <Dropdown checkbox {...{ items, onSelect }} />
          </span>
        ))}
        <SearchField placeholder="Hostname / Client Code / #ID" />
        <Button variant="secondary" icon="sync">
          Reset
        </Button>
      </StyledFilters>
      <TileContainer>
        {isLoading ? (
          <GhostElement />
        ) : isError ? (
          <div>Failed to load PTN alerts.</div>
        ) : (
          <>
            {alerts.pages.map((page, pageIndex) => (
              <div id="alerts-wrapper" key={pageIndex}>
                {page.data.map((alert) => (
                  <Alert key={alert.id} {...{ alert }} />
                ))}
              </div>
            ))}
            <div ref={lastElementRef}>{hasNextPage ? "" : "No more results."}</div>
          </>
        )}
        {isFetchingNextPage && <div>Loading more alerts...</div>}
      </TileContainer>
    </StyledPTNAlerts>
  );
}
