import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import dayjs_relativeTime from "dayjs/plugin/relativeTime";
import { t } from "../../logic/translator.js";
import { registrationStatuses } from "../../logic/util.js";

dayjs.extend(dayjs_relativeTime);

const StyledStatus = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 0.8rem;

  .icon {
    font-size: 1.6rem;
    color: var(--dt-ui-critical);

    &.pending,
    &.expired,
    &.from-parent-client {
      color: grey;
    }

    &.synced,
    &.completed,
    &.not-required {
      color: var(--dt-ui-resolved);
    }
  }

  .message {
    white-space: nowrap;
  }

  .bold {
    font-weight: 700;
  }
`;

export function Status({ registrationStatus = "unknown", expiry, jwksEnabled, fromParentClient, parentClientName }) {
  if (!jwksEnabled) registrationStatus = "disabled";

  let statusClass = registrationStatus.replace(/\s/g, "-");
  let text = registrationStatuses[registrationStatus]?.text ?? registrationStatus;
  let icon = registrationStatuses[registrationStatus]?.icon ?? "circle-xmark far";
  let extraText =
    registrationStatus === "pending"
      ? t(`{0} left`, dayjs().to(dayjs(expiry), true))
      : registrationStatus === "expired"
        ? t(`{0}`, dayjs(expiry).fromNow())
        : null;

  if (fromParentClient && jwksEnabled) {
    statusClass = "from-parent-client";
    text = (
      <span>
        {t(`User from`)} <span className="bold">{parentClientName ?? "Parent Client"}</span>
      </span>
    );
    icon = "merge";
    extraText = null;
  }

  return (
    <StyledStatus>
      <i className={`icon fa fa-${icon} ${statusClass}`} />
      <span className="message">
        {text}
        {extraText ? `: ${extraText}` : ""}
      </span>
    </StyledStatus>
  );
}
