import { Button, Dropdown, IconButton, StyledTile, Tag, Tooltip } from "@darktrace/ui-components";
import React, { useState } from "react";
import styled from "styled-components";
import { alertStatus } from "../utils";
import dayjs from "dayjs";
import { TagsModal } from "./TagsModal.jsx";
import { selfAssign } from "../../../logic/api.js";
import { CommentsModal } from "./CommentsModal.jsx";
import { NotifiedModal } from "./NotifiedModal.jsx";
import { useDispatch, useSelector } from "react-redux";
import { IntelModal } from "./IntelModal.jsx";
import { newToast } from "../../../logic/store.js";
import { ALertDataModal } from "./AlertDataModal.jsx";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const StyledAlert = styled(StyledTile)`
  padding: 1.6rem;
  height: 27rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  font-weight: 500;
  min-width: fit-content;
  &:before {
    background-color: ${(props) => props.accent || ""};
  }
  .bold {
    font-weight: 700;
  }
  .label {
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0.15em;
    text-align: left;
    color: var(--dt-ui-header-text-color);
  }
  > div:first-of-type {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    > div:first-of-type {
      display: flex;
      flex-direction: column;
      span:first-of-type {
        font-size: 1.6rem;
        font-weight: 500;
        padding-bottom: 0.8rem;
        color: var(--dt-ui-tag-text-color);
      }
      span:last-of-type {
        padding-top: 1.2rem;
      }
    }
    > div:last-of-type {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      > span:first-of-type {
        display: flex;
        gap: 0.8rem;
        justify-content: end;
      }
      > span:last-of-type {
        display: flex;
        justify-content: flex-end;
        gap: 0.8rem;
      }
    }
  }
  > div:nth-of-type(2n) {
    display: flex;
    gap: 7rem;
  }
  > div:last-of-type {
    margin-top: auto;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    gap: 5rem;
    align-items: end;
    > span:first-of-type {
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
    > span:nth-of-type(2n) {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }
    > span:nth-of-type(3n) {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      > span:first-of-type {
        padding-bottom: 1rem;
      }
      > span:last-of-type {
        .fa-user {
          padding-right: 0.8rem;
        }
      }
    }
    > span:last-of-type {
      display: flex;
      align-items: flex-end;
    }
  }
`;

const StyledLabel = styled.div`
  .status-dot {
    width: 0.8rem;
    height: 0.8rem;
    background-color: ${(props) => props.color || ""};
    display: inline-block;
    border-radius: 50%;
    margin-right: 0.8rem;
  }
`;

export function Alert({ alert }) {
  const dispatch = useDispatch();
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [isNotifiedModalOpen, setIsNotifiedModalOpen] = useState(false);
  const [isIntelModalOpen, setIsIntelModalOpen] = useState(false);
  const [isAlertDataModalOpen, setIsAlertDataModalOpen] = useState(false);
  function handleClaimAlert() {
    selfAssign({ alertId: alert.id, clientId: defaultClientId });
  }

  function copyACE() {
    // TO DO this should be client code
    const ACEText = `ace ${alert.client_name} -m ${alert.pbid}`;
    navigator.clipboard.writeText(ACEText);
    dispatch(newToast({ variant: "success", title: t(`Copied to clipboard`) }));
  }

  return (
    <>
      <StyledAlert accent={alertStatus[alert.status]?.color || ""}>
        <div>
          <div>
            <span>
              {alert.hostname || ""} | {alert.client?.name || ""}
            </span>
            <span>#{alert.pbid || ""}</span>
            <span>{alert.model_name || ""}</span>
          </div>
          <div>
            <span>
              <IconButton variant="secondary" icon="warning" />
              <IconButton variant="secondary" icon="comment" onClick={() => setIsCommentsModalOpen((prev) => !prev)} />
              <IconButton variant="secondary" icon="bell-exclamation" onClick={() => setIsNotifiedModalOpen((prev) => !prev)} />
              <IconButton variant="secondary" icon="tags" onClick={() => setIsTagsModalOpen((prev) => !prev)} />
            </span>
            {/* TO DO */}
            <span>{alert.soc_tags ? alert.soc_tags.map((tag) => <Tag key={tag.id} text={tag.display_name} />) : null}</span>
          </div>
        </div>
        <div>
          <span>
            <p>
              <span className="bold">Event Time: </span> {dayjs(alert.time).utc().format("ddd DD YYYY, HH:mm:ss")} UTC
            </p>
            <p>
              <span className="bold">Alert Created: </span> {dayjs(alert.created_at).utc().format("ddd DD YYYY, HH:mm:ss")} UTC
            </p>
          </span>
          <span>
            <p>
              <span className="bold">PBID: </span>
              {alert.pbid}
            </p>
            <p>
              <span className="bold">Score: </span>
              {alert.score}
            </p>
          </span>
        </div>
        <div>
          <span>
            <Button variant="secondary" icon="file-contract">
              Alert Data
            </Button>
            <Button variant="secondary" icon="building" onClick={() => setIsIntelModalOpen((prev) => !prev)}>
              Account Intel
            </Button>
            {/* TO DO */}
            <Tooltip text="Copy ACE to Clipboard">
              <IconButton variant="secondary" icon="copy far" onClick={copyACE} />
            </Tooltip>
          </span>
          <span>
            <span className="label">STATUS</span>
            <Dropdown
              items={Object.keys(alertStatus).map((s) => ({
                id: s,
                label: (
                  <StyledLabel color={alertStatus[s].color}>
                    <span className="status-dot"></span>
                    {alertStatus[s].label}
                  </StyledLabel>
                ),
                selected: alert.status === s,
              }))}
            />
          </span>
          <span>
            <span className="label">ASSIGNED TO</span>
            <span>
              <i className="fas fa-user"></i>
              {alert.assignee?.full_name || alert.assignee_id || "Not Assigned"}
            </span>
          </span>
          <span>
            <Button onClick={handleClaimAlert} icon="user" variant={alert.status === "new" ? "primary" : "secondary"}>
              Claim
            </Button>
          </span>
        </div>
      </StyledAlert>
      <TagsModal
        existingTags={alert.soc_tags ? alert.soc_tags : []}
        alertId={alert.id}
        open={isTagsModalOpen}
        onClose={() => setIsTagsModalOpen(false)}
      />
      {isCommentsModalOpen ? (
        <CommentsModal breachId={alert.id} open={isCommentsModalOpen} onClose={() => setIsCommentsModalOpen(false)} />
      ) : null}
      {isNotifiedModalOpen ? (
        <NotifiedModal breachId={alert.id} open={isNotifiedModalOpen} onClose={() => setIsNotifiedModalOpen(false)} />
      ) : null}
      {isIntelModalOpen ? <IntelModal breachId={alert.id} open={isIntelModalOpen} onClose={() => setIsIntelModalOpen(false)} /> : null}
      {isAlertDataModalOpen ? (
        <ALertDataModal breachId={alert.id} open={isAlertDataModalOpen} onClose={() => setIsAlertDataModalOpen(false)} />
      ) : null}
    </>
  );
}
