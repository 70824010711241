// * NOTE: `/api-docs` has the OpenAPI Docs for the API
import axios from "axios";
import { newToast, setShowTimeoutOverlay, store } from "./store.js";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { queryClient } from "./index.jsx";
import { useEffect, useState } from "react";

// used by API to indentify requests from Welcome Web
axios.defaults.headers.common["X-REQUEST-ORIGIN"] = "welcome-web";

let timeoutId;

function checkUserStillLoggedIn() {
  if (timeoutId) clearTimeout(timeoutId);

  timeoutId = setTimeout(async () => {
    try {
      await verifyUser();
    } catch {
      store.dispatch(setShowTimeoutOverlay(true));
    }
  }, 300);
}

// used to check if a user is still verified after a failed request, if not, show timeout overlay
axios.interceptors.response.use(
  // on success => do nothing
  (response) => response,
  // on error => check user is still verified, if not, show timeout overlay
  async (error) => {
    if (error.config.url !== "/api/verify" && !store.getState().app.showTimeoutOverlay && store.getState().app.isUserVerified) {
      checkUserStillLoggedIn();
    }

    return Promise.reject(error);
  },
);

// General
export async function verifyUser() {
  return axios.get(`/api/verify`).then((res) => {
    store.dispatch(setShowTimeoutOverlay(false));
    return res;
  });
}

export function lookupEmailAddress({ email }) {
  return axios.post(`/api/lookup`, { email });
}

export async function logoutUser() {
  return axios.get(`/api/logout`, { withCredentials: true });
}

// User
export async function createNewUser({ clientId, name, email, jwksEnabled }) {
  return axios
    .post("/api/user/registration", { clientId, name, email, jwksEnabled })
    .then(() => {
      store.dispatch(newToast({ title: t(`User created successfully`), variant: "success" }));
      queryClient.invalidateQueries({ queryKey: ["client-users"] });
    })
    .catch((err) => store.dispatch(newToast({ title: t(`Unable to create user`), variant: "error", text: err.response?.data?.message })));
}

export async function renewUserRegistration({ userId }) {
  return axios
    .put("/api/user/registration", { userId })
    .then(() => {
      store.dispatch(newToast({ title: t(`Registration reset successfully`), variant: "success" }));
      queryClient.invalidateQueries({ queryKey: ["client-users"] });
    })
    .catch((err) =>
      store.dispatch(newToast({ title: t(`Unable to reset registration`), variant: "error", text: err.response?.data?.message })),
    );
}

export async function toggleUserEnabled({ userId, email, jwksEnabled }) {
  return axios.patch("/api/user", { userId, jwksEnabled }).catch(() => {
    store.dispatch(newToast({ variant: "error", title: t(`Unable to enable user: {0}`, email) }));
  });
}
export function useToggleUserEnabledMutation({ userId, email, jwksEnabled }) {
  return useMutation({
    mutationFn: async () =>
      toggleUserEnabled({ userId, email, jwksEnabled }).then(() => {
        queryClient.invalidateQueries({ queryKey: ["client-users"] });
      }),
  });
}

export async function updateUserInfo({ clientId, userId, name }) {
  return axios.patch("/api/user/profile", { clientId, userId, name }).catch(() => {
    store.dispatch(newToast({ variant: "error", title: t(`Unable to udpate user information: {0}`, name) }));
  });
}
export function useUpdateUserInfoMutation({ clientId, userId, name }) {
  return useMutation({
    mutationFn: async () =>
      updateUserInfo({ clientId, userId, name }).then(() => {
        queryClient.invalidateQueries({ queryKey: ["client-users"] });
      }),
  });
}

export async function fetchUserInfo({ userId } = {}) {
  return axios
    .get("/api/user", { params: { userId } })
    .then((res) => res.data.user)
    .catch(() => {
      store.dispatch(newToast({ variant: "error", title: "Unable to fetch user info" }));
      return null;
    });
}
export function useUserInfo({ userId } = {}) {
  return useQuery({ queryKey: ["user-info", { userId }], queryFn: async () => fetchUserInfo({ userId }) });
}

export async function fetchUserProfileInfo() {
  return axios
    .get("/api/user/profile")
    .then((res) => res.data)
    .catch(() => {
      store.dispatch(newToast({ variant: "error", title: "Unable to fetch user profile info" }));
      return null;
    });
}
export function useUserProfileInfo() {
  return useQuery({ queryKey: ["user-profile-info"], queryFn: async () => fetchUserProfileInfo() });
}

export async function fetchUserOwnRoles({ clientId } = {}) {
  return axios
    .get("/api/user/profile/role", { params: { clientId } })
    .then((res) => res.data.roles)
    .catch(() => {
      store.dispatch(newToast({ variant: "error", title: "Unable to fetch user's own roles" }));
      return null;
    });
}
export function useUserOwnRoles({ clientId, enabled } = {}) {
  return useQuery({
    enabled: !!clientId && enabled !== false,
    queryKey: ["user-profile-roles", { clientId }],
    queryFn: async () => fetchUserOwnRoles({ clientId }),
  });
}

// TODO: this should be an actual endpoint
export async function fetchUserRoles({ userId, clientId, email } = {}) {
  return axios
    .get("/api/client/users", { params: { search: email, clientId } })
    .then((res) => res.data.users.find((user) => user.id === userId)?.roles ?? [])
    .catch(() => {
      store.dispatch(newToast({ variant: "error", title: "Unable to fetch user's own roles" }));
      return null;
    });
}
export function useUserRoles({ userId, clientId, email, enabled } = {}) {
  return useQuery({
    enabled: !!clientId && !!email && !!userId && enabled !== false,
    queryKey: ["user-roles", { userId, clientId, email }],
    queryFn: async () => fetchUserRoles({ userId, clientId, email }),
  });
}

// TODO: this should be an actual endpoint
export async function fetchParentUserRoles({ userId, clientId, email } = {}) {
  return axios
    .get("/api/client/parent/users", { params: { search: email, clientId } })
    .then((res) => res.data.users.find((user) => user.id === userId)?.childRoles ?? [])
    .catch(() => {
      store.dispatch(newToast({ variant: "error", title: "Unable to fetch user's own roles" }));
      return null;
    });
}
export function useParentUserRoles({ userId, clientId, email, enabled } = {}) {
  return useQuery({
    enabled: !!clientId && !!email && !!userId && enabled !== false,
    queryKey: ["parent-user-roles", { userId, clientId, email }],
    queryFn: async () => fetchParentUserRoles({ userId, clientId, email }),
  });
}

export async function addRoleToUser({ userId, clientId, roleId }) {
  return axios.post("/api/user/role", { userId, clientId, roleId });
}
export async function removeRoleFromUser({ userId, clientId, roleId }) {
  return axios.delete("/api/user/role", { data: { userId, clientId, roleId } });
}

export async function addChildRoleToUser({ userId, clientId, roleId }) {
  return axios.post("/api/user/child/role", { userId, clientId, roleId });
}
export async function removeChildRoleFromUser({ userId, clientId, roleId }) {
  return axios.delete("/api/user/child/role", { data: { userId, clientId, roleId } });
}

export async function fetchUserClients() {
  return axios.get(`/api/user/access/client`).then((res) => res.data.clients);
}

export async function fetchUserAccessFlags({ clientId } = {}) {
  return axios
    .get(`/api/user/access/flags`, { params: { clientId: clientId } })
    .then((res) => res.data)
    .catch(() => []);
}
export function useUserAccessFlags({ clientId }) {
  return useQuery({
    enabled: !!clientId,
    queryKey: ["user-access-flags", { clientId }],
    queryFn: async () => fetchUserAccessFlags({ clientId }),
  });
}

export async function resetPassword({ oldPassword, newPassword }) {
  return axios.patch("/api/user/profile/password_reset", { oldPassword, newPassword });
}

export async function resetUserPassword(userId) {
  return axios.patch("/api/user/password_reset", { userId });
}

export async function resetMyMfa(userId) {
  const body = userId ? { userId } : null;
  return axios.delete("/api/user/profile/mfas", {
    body,
  });
}

export async function resetUserMfa(userId) {
  const body = userId ? { userId } : null;
  return axios.delete("/api/user/mfas", { data: body });
}

// Products
export async function fetchProductGroups({ instanceId, onComplete }) {
  let err;
  return axios
    .get(`/api/product/groups`, { params: { instanceId } })
    .then((res) => res.data)
    .catch((error) => {
      if (onComplete) {
        err = error;
        return null;
      }
      store.dispatch(newToast({ title: t(`Unable to fetch product groups`), variant: "error" }));
      return null;
    })
    .finally(() => {
      if (onComplete) onComplete(err);
    });
}
export function useProductGroups({ instanceId, onComplete, enabled }) {
  return useQuery({
    queryKey: ["product-groups", { instanceId }],
    queryFn: async () => fetchProductGroups({ instanceId, onComplete }),
    enabled: enabled,
  });
}

// TODO: this should be an actual endpoint
export async function fetchProductUser({ email, userId, instanceId, onComplete }) {
  let err;
  return (
    axios
      // .get(`/api/product/users`, { params: { instanceId, search: email } }) // TODO: should be able to search by email but then the rest of the fields are removed (e.g. linkedName)
      .get(`/api/product/users`, { params: { instanceId } })
      .then((res) => {
        const user = res.data.users.find((user) => user.userId === userId) ?? null;
        return user;
      })
      .catch((error) => {
        if (onComplete) {
          err = error;
          return;
        }
        store.dispatch(newToast({ title: t(`Unable to fetch user product groups for {0}`, email), variant: "error" }));
        return {};
      })
      .finally(() => {
        if (onComplete) onComplete(err);
      })
  );
}
export function useProductUser({ email, userId, instanceId, onComplete, enabled }) {
  return useQuery({
    queryKey: ["product-user", { email, userId, instanceId, onComplete }],
    queryFn: async () => fetchProductUser({ email, userId, instanceId, onComplete }),
    enabled: enabled,
  });
}

async function fetchProductUsers({ instanceId, search, page, length, onComplete }) {
  let err;
  return axios
    .get(`/api/product/users`, { params: { instanceId, search, page, length } })
    .then((res) => res.data)
    .catch((error) => {
      if (onComplete) {
        err = error;
        return;
      }
      store.dispatch(newToast({ title: t(`Unable to fetch product users`), variant: "error" }));
      return {};
    })
    .finally(() => {
      if (onComplete) onComplete(err);
    });
}

export function useProductUsers({ instanceId, search, page, length, enabled }) {
  return useQuery({
    queryKey: ["product-users", { instanceId, search, page, length }],
    queryFn: async () => fetchProductUsers({ instanceId, search, page, length }),
    enabled: enabled,
  });
}

export function useProductAvailableGroups({ instanceId, enabled }) {
  return useQuery({
    queryKey: ["product-available-groups", { instanceId }],
    queryFn: async () => fetchProductUsers({ instanceId, length: 1, onComplete: () => {} }).then((res) => res.availableGroups),
    enabled: enabled,
  });
}

export async function editUserGroups({ username, userId, instanceId, add, remove }) {
  return axios
    .put(`/api/product/user/groups`, { username, userId, instanceId, add, remove })
    .then((res) => {
      if (!res.data?.changedRoles) throw new Error(t(`Edit user groups failed`));
      store.dispatch(newToast({ title: t(`Users's groups updated successfully`), variant: "success" }));
    })
    .catch(() => {
      store.dispatch(newToast({ title: t(`Unable to edit user's groups on instance`), variant: "error" }));
      return null;
    })
    .finally(() => {
      queryClient.invalidateQueries({ queryKey: ["product-users"] });
      queryClient.invalidateQueries({ queryKey: ["products"] });
      queryClient.invalidateQueries({ queryKey: ["user-UP-product-groups"] });
      queryClient.invalidateQueries({ queryKey: ["user-product-access-instances-stream"] });
      queryClient.invalidateQueries({ queryKey: ["my-product-access-instances-stream"] });
    });
}
export function useEditUserGroupsMutation() {
  return useMutation({
    mutationKey: ["edit-user-groups"],
    mutationFn: async ({ username, userId, instanceId, add, remove }) => editUserGroups({ username, userId, instanceId, add, remove }),
  });
}

export async function editParentClientUserGroups({ username, userId, instanceId, add, remove }) {
  return axios
    .put(`/api/product/parent/user/groups`, { username, userId, instanceId, add, remove })
    .then((res) => {
      if (!res.data?.changedRoles) throw new Error(t(`Edit user groups failed`));
      store.dispatch(newToast({ title: t(`Users's groups updated successfully`), variant: "success" }));
    })
    .catch(() => {
      store.dispatch(newToast({ title: t(`Unable to edit user's groups on instance`), variant: "error" }));
      return null;
    })
    .finally(() => {
      queryClient.invalidateQueries({ queryKey: ["product-users"] });
      queryClient.invalidateQueries({ queryKey: ["products"] });
      queryClient.invalidateQueries({ queryKey: ["user-UP-product-groups"] });
      queryClient.invalidateQueries({ queryKey: ["user-product-access-instances-stream"] });
      queryClient.invalidateQueries({ queryKey: ["my-product-access-instances-stream"] });
    });
}
export function useEditParentClientUserGroupsMutation() {
  return useMutation({
    mutationKey: ["edit-parent-client-user-groups"],
    mutationFn: async ({ username, userId, instanceId, add, remove }) =>
      editParentClientUserGroups({ username, userId, instanceId, add, remove }),
  });
}

export async function createSabreUser({ userId, instanceId, groups }) {
  return axios
    .post(`/api/product/user`, { userId, instanceId, groups })
    .then((res) => {
      if (!res.data?.created) throw new Error(t(`Creation failed`));
      store.dispatch(newToast({ title: t(`Threat Visualiser user created successfully`), variant: "success" }));
      queryClient.invalidateQueries({ queryKey: ["product-users", { instanceId }] });
      queryClient.invalidateQueries({ queryKey: ["user-UP-product-groups", { instanceId }] });
    })
    .catch(() => {
      store.dispatch(newToast({ title: t(`Unable to create Threat Visualiser user`), variant: "error" }));
      return null;
    });
}
export function useCreateSabreUserMutation({ userId, instanceId, groups }) {
  return useMutation({
    mutationKey: ["create-sabre-user"],
    mutationFn: async () => createSabreUser({ userId, instanceId, groups }),
  });
}

export async function createParentClientSabreUser({ userId, instanceId, groups }) {
  return axios
    .post(`/api/product/parent/user`, { userId, instanceId, groups })
    .then((res) => {
      if (!res.data?.created) throw new Error(t(`Creation failed`));
      store.dispatch(newToast({ title: t(`Threat Visualiser user created successfully`), variant: "success" }));
      queryClient.invalidateQueries({ queryKey: ["product-users", { instanceId }] });
      queryClient.invalidateQueries({ queryKey: ["user-UP-product-groups", { instanceId }] });
    })
    .catch(() => {
      store.dispatch(newToast({ title: t(`Unable to create Threat Visualiser user`), variant: "error" }));
      return null;
    });
}
export function useCreateParentClientSabreUserMutation({ userId, instanceId, groups }) {
  return useMutation({
    mutationKey: ["create-parent-client-sabre-user"],
    mutationFn: async () => createSabreUser({ userId, instanceId, groups }),
  });
}

export async function linkUser({ userId, instanceId, sabreUsername }) {
  return axios
    .put(`/api/product/user/link`, { userId, instanceId, sabreUsername })
    .then((res) => {
      if (!res.data?.linked) throw new Error(t(`Link failed`));
      store.dispatch(newToast({ title: t(`Threat Visualiser user linked successfully`), variant: "success" }));
      queryClient.invalidateQueries({ queryKey: ["product-users", { instanceId }] });
      queryClient.invalidateQueries({ queryKey: ["user-UP-product-groups", { instanceId }] });
    })
    .catch(() => {
      store.dispatch(newToast({ title: t(`Unable to link user`), variant: "error" }));
      return null;
    });
}
export function useLinkUserMutation({ instanceId, userId, sabreUsername }) {
  return useMutation({
    mutationKey: ["link-user"],
    mutationFn: async () => linkUser({ instanceId, userId, sabreUsername }),
  });
}

export async function unlinkUser({ userId, instanceId, sabreUsername }) {
  return axios
    .put(`/api/product/user/unlink`, { userId, instanceId, sabreUsername })
    .then((res) => {
      if (!res.data?.unlinked) throw new Error("Link failed");
      store.dispatch(newToast({ title: t(`Threat Visualiser user unlinked successfully`), variant: "success" }));
      queryClient.invalidateQueries({ queryKey: ["product-users", { instanceId }] });
      queryClient.invalidateQueries({ queryKey: ["user-UP-product-groups", { instanceId }] });
    })
    .catch(() => {
      store.dispatch(newToast({ title: t(`Unable to unlink user`), variant: "error" }));
      return null;
    });
}
export function useUnlinkUserMutation({ instanceId, userId, sabreUsername }) {
  return useMutation({
    mutationKey: ["unlink-user"],
    mutationFn: async () => unlinkUser({ instanceId, userId, sabreUsername }),
  });
}

async function fetchProductAccessInstances({ clientId } = {}) {
  return axios
    .get(`/api/product/instances`, { params: { clientId } })
    .then((res) => res.data.instances)
    .catch(() => {
      store.dispatch(newToast({ title: t(`Unable to fetch products`), variant: "error" }));
      return null;
    });
}
export function useProductAccessInstances({ clientId, enabled = true }) {
  return useQuery({
    enabled: !!clientId && !!enabled,
    queryKey: ["product-access-instances", { clientId }],
    queryFn: async () => fetchProductAccessInstances({ clientId }),
  });
}

export async function fetchMyProductAccessInstancesStream({ clientId } = {}) {
  try {
    const paramsObj = {};
    if (clientId) paramsObj.clientId = clientId;

    const params = Object.keys(paramsObj).length > 0 ? `?${new URLSearchParams(paramsObj)}` : "";

    return fetch(`/api/product/access${params}`);
  } catch {
    store.dispatch(newToast({ title: t(`Unable to fetch products`), variant: "error" }));
  }
}
export async function fetchUserProductAccessInstancesStream({ userId, clientId } = {}) {
  try {
    const paramsObj = {};
    if (userId) paramsObj.userId = userId;
    if (clientId) paramsObj.clientId = clientId;

    const params = Object.keys(paramsObj).length > 0 ? `?${new URLSearchParams(paramsObj)}` : "";

    return fetch(`/api/user/product/access${params}`);
  } catch {
    store.dispatch(newToast({ title: t(`Unable to fetch products`), variant: "error" }));
  }
}
function handleProductAccessInstancesStream({ res, onReceive, onDone }) {
  if (!res?.body || res.body.locked) return;

  const reader = res.clone().body.getReader();
  const decoder = new TextDecoder("utf8");

  reader.read().then(function processChunk(result) {
    if (result.done) {
      onDone();
      return;
    }

    const chunk = decoder.decode(result.value);
    onReceive({ done: result.done, chunk });

    reader.read().then(processChunk);
  });
}
export function useMyProductAccessInstancesStream({ clientId, key, enabled = false }) {
  const { data: response, ...query } = useQuery({
    enabled: !!clientId && enabled,
    queryKey: ["my-product-access-instances-stream", { clientId, key }],
    queryFn: async () => fetchMyProductAccessInstancesStream({ clientId }),
    refetchOnMount: true,
  });

  const [availableProducts, setAvailableProducts] = useState([]);
  const [isStreamingProducts, setIsStreamingProducts] = useState(true);

  useEffect(() => {
    if (query.isFetching) setIsStreamingProducts(true);
  }, [query.isFetching]);

  useEffect(() => {
    setAvailableProducts([]);
    handleProductAccessInstancesStream({ res: response, onReceive, onDone });
  }, [response, key]);

  function onReceive({ done, chunk }) {
    try {
      const splits = chunk.split("\n").filter((x) => x);
      const instances = splits.map((split) => JSON.parse(split));

      setAvailableProducts((prevInstances) => {
        const instancesToAdd =
          instances?.filter((instance) => !prevInstances.some((prevInstance) => prevInstance.instanceId === instance.instanceId)) ?? [];
        const updatedInstances = [...prevInstances, ...instancesToAdd];
        const sortedUpdatedInstances = updatedInstances.toSorted((instanceA, instanceB) =>
          instanceA.instanceName < instanceB.instanceName ? -1 : 1,
        );
        return sortedUpdatedInstances;
      });
    } catch {
      store.dispatch(newToast({ title: "Unable to parse product information", variant: "error" }));
    }

    if (done) return setIsStreamingProducts(false);
  }

  function onDone() {
    setIsStreamingProducts(false);
  }

  return { ...query, data: availableProducts, isLoading: isStreamingProducts };
}

export function useMyMultiClientProductAccessInstancesStream({ clientIds, key, enabled = false }) {
  const {
    data: responses = [],
    isFetching,
    isFetched,
    isError,
  } = useQueries({
    queries: clientIds.map((clientId) => ({
      enabled: !!clientId && enabled,
      queryKey: ["my-multi-client-product-access-instances-stream", { clientId, key }],
      queryFn: async () => fetchMyProductAccessInstancesStream({ clientId }),
      refetchOnWindowFocus: false,
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        isFetching: results.some((result) => result.isFetching),
        isFetched: results.every((result) => result.isFetched),
        isError: results.some((result) => result.isError),
      };
    },
  });

  const [availableProducts, setAvailableProducts] = useState([]);
  const [isParsingError, setIsParsingError] = useState(false);

  useEffect(() => {
    responses.forEach((response, index) => {
      const instanceClientId = clientIds[index]; // TODO
      handleProductAccessInstancesStream({
        res: response,
        onReceive: ({ chunk }) => onReceive({ chunk, clientId: instanceClientId }),
        onDone: () => {},
      });
    });
  }, [responses, key]);

  function onReceive({ chunk, clientId }) {
    try {
      const splits = chunk.split("\n").filter((x) => x);
      const instances = splits.map((split) => JSON.parse(split));
      const filteredInstances = instances.reduce((prevInstances, instance) => {
        if (
          !prevInstances.some(
            (prevInstance) =>
              instance.instanceId === prevInstance.instanceId &&
              clientId === prevInstance.clientId &&
              instance.serviceType === prevInstance.serviceType,
          )
        )
          prevInstances.push({ ...instance, clientId });
        return prevInstances;
      }, []);

      setAvailableProducts((prevInstances) => {
        const instancesToAdd = filteredInstances.filter(
          (instance) =>
            !prevInstances.some(
              (prevInstance) =>
                prevInstance.instanceId === instance.instanceId &&
                prevInstance.clientId === instance.clientId &&
                instance.serviceType === prevInstance.serviceType,
            ),
        );

        const updatedInstances = [...prevInstances, ...instancesToAdd];
        const sortedUpdatedInstances = updatedInstances.toSorted((instanceA, instanceB) =>
          instanceA.instanceName < instanceB.instanceName ? -1 : 1,
        );
        return sortedUpdatedInstances;
      });
    } catch {
      store.dispatch(newToast({ title: "Unable to parse product information", variant: "error" }));
      setIsParsingError(true);
    }
  }

  return { data: availableProducts, isFetching, isFetched, isError: isError || isParsingError };
}

export function useUserProductAccessInstancesStream({ userId, clientId, key, enabled = false }) {
  const { data: response, ...query } = useQuery({
    enabled: !!clientId && enabled,
    queryKey: ["user-product-access-instances-stream", { userId, clientId, key }],
    queryFn: async () => fetchUserProductAccessInstancesStream({ userId, clientId }),
    refetchOnMount: true,
  });

  const [availableProducts, setAvailableProducts] = useState([]);
  const [isStreamingProducts, setIsStreamingProducts] = useState(true);

  useEffect(() => {
    setAvailableProducts([]);
    handleProductAccessInstancesStream({ res: response, onReceive, onDone });
  }, [response, key]);

  function onReceive({ done, chunk }) {
    try {
      const splits = chunk.split("\n").filter((x) => x);
      const instances = splits.map((split) => JSON.parse(split));

      setAvailableProducts((prevInstances) => {
        const instancesToAdd =
          instances?.filter((instance) => !prevInstances.some((prevInstance) => prevInstance.instanceId === instance.instanceId)) ?? [];
        const updatedInstances = [...prevInstances, ...instancesToAdd];
        const sortedUpdatedInstances = updatedInstances.toSorted((instanceA, instanceB) =>
          instanceA.instanceName < instanceB.instanceName ? -1 : 1,
        );
        return sortedUpdatedInstances;
      });
    } catch {
      store.dispatch(newToast({ title: "Unable to parse product information", variant: "error" }));
    }

    if (done) return setIsStreamingProducts(false);
  }

  function onDone() {
    setIsStreamingProducts(false);
  }

  return { ...query, data: availableProducts, isLoading: isStreamingProducts };
}

async function fetchAvailableProducts({ clientId } = {}) {
  return axios
    .get(`/api/product/access`, { params: { clientId } })
    .then((res) => res.data)
    .catch(() => {
      store.dispatch(newToast({ title: t(`Unable to fetch products`), variant: "error" }));
      return null;
    });
}
export function useAvailableProducts({ clientId }) {
  return useQuery({
    enabled: !!clientId,
    queryKey: ["products", { clientId }],
    queryFn: async () => fetchAvailableProducts({ clientId }),
  });
}

export async function loginToProduct({ clientId, instanceId, serviceId, newTab = true }) {
  let errorMessage;
  return axios
    .get(`/api/product/login`, { params: { clientId, instanceId, serviceId } })
    .then((res) => {
      if (res?.data?.url) {
        const popup = window.open(res?.data?.url, newTab ? "_blank" : "_self");
        if (popup) popup.focus();
        else {
          errorMessage = t(`Please disable your pop-up blocker and try again`);
          throw new Error();
        }
      }
    })
    .catch(() => {
      store.dispatch(newToast({ title: errorMessage ?? t(`Unable to login to product`), variant: "error" }));
      return null;
    })
    .finally(() => localStorage.removeItem("product"));
}
export function useProductLoginMutation({ clientId, serviceId }) {
  return useMutation({
    mutationKey: ["product-login"],
    mutationFn: async ({ instanceId }) => loginToProduct({ clientId, instanceId, serviceId }),
  });
}

// Client
export async function fetchClientInfo() {
  return axios.get(`/api/client`).then((res) => res.data.client);
}
export function useClientInfo({ clientId }) {
  return useQuery({
    queryKey: ["client-info", { clientId }],
    queryFn: async () => fetchClientInfo({ clientId }),
  });
}

export async function fetchClientUsers({ search, page = 1, length, orderDir = "asc", roles, registrationStatus, clientId }) {
  return axios
    .get(`/api/client/users`, {
      params: { search, page, length, orderDir, orderCol: "name", roles, registrationStatus: registrationStatus, clientId },
    })
    .then((res) => res.data);
}
export function _useClientUsers({ search, page, length, orderDir, roles, registrationStatus, clientId, disabled }) {
  return useQuery({
    enabled: !!clientId && !disabled,
    queryKey: ["client-users", { search, page, length, orderDir, roles, registrationStatus, clientId }],
    queryFn: async () => fetchClientUsers({ search, page, length, orderDir, roles, registrationStatus, clientId }),
  });
}

export async function fetchParentClientUsers({ search, page = 1, length, orderDir = "asc", roles, registrationStatus, clientId }) {
  return axios
    .get(`/api/client/parent/users`, {
      params: { search, page, length, orderDir, orderCol: "name", roles, ...(registrationStatus ? { registrationStatus } : {}), clientId },
    })
    .then((res) => res.data)
    .catch(() => null);
}
export function _useParentClientUsers({ search, page, length, orderDir, roles, registrationStatus, clientId, disabled }) {
  return useQuery({
    enabled: !!clientId && !disabled,
    queryKey: ["parent-client-users", { search, page, length, orderDir, roles, registrationStatus, clientId }],
    queryFn: async () => fetchParentClientUsers({ search, page, length, orderDir, roles, registrationStatus, clientId }),
  });
}

export async function fetchClientAvailableRoles({ clientId }) {
  return axios.get(`/api/roles`, { params: { clientId } }).then((res) => res.data.availableRoles);
}
export function useClientAvailableRoles({ clientId, enabled }) {
  return useQuery({
    enabled: !!clientId && enabled !== false,
    queryKey: ["client-available-roles", { clientId }],
    queryFn: async () => fetchClientAvailableRoles({ clientId }),
  });
}

export async function fetchParentClientAvailableRoles({ clientId }) {
  return axios.get(`/api/client/parent/users`, { params: { length: 1, clientId } }).then((res) => res.data.availableChildRoles);
}
export function useParentClientAvailableRoles({ clientId, enabled }) {
  return useQuery({
    enabled: !!clientId && !!enabled,
    queryKey: ["parent-client-available-roles", { clientId }],
    queryFn: async () => fetchParentClientAvailableRoles({ clientId }),
  });
}

export function useClientSAMLProviderInfo({ clientSamlProviderId, clientId }) {
  const { data: samlProviders } = useClientSAMLProviders({ clientId });
  const samlProvider = samlProviders?.providers?.find((provider) => provider.id === clientSamlProviderId) ?? null;

  return useQuery({
    queryKey: ["client-saml-provider-info", { clientSamlProviderId, clientId }],
    queryFn: async () => samlProvider,
    enabled: !!clientSamlProviderId,
  });
}

export async function fetchClientSAMLProviders({ clientId }) {
  return axios.get(`/api/client/saml/providers`, { params: { clientId } }).then((res) => {
    setTimeout(() => queryClient.invalidateQueries({ queryKey: ["client-saml-provider-info"] }), 100);
    return res.data;
  });
}

export function useClientSAMLProviders({ clientId, enabled = false }) {
  return useQuery({
    queryKey: ["client-saml-providers", { clientId }],
    queryFn: async () => fetchClientSAMLProviders({ clientId }),
    enabled,
  });
}

// OLD SAML Provider API ENDPOINTS

export async function fetchSAMLProviderGroupMappings({ clientSamlProviderId }) {
  return axios
    .get(`/api/client/saml/provider/groups`, { params: { clientSamlProviderId } })
    .then((res) => res.data.samlAccessGroupMappings);
}
export function useSAMLProviderGroupMappings({ clientSamlProviderId }) {
  return useQuery({
    enabled: !!clientSamlProviderId,
    queryKey: ["client-saml-provider-groups", { clientSamlProviderId }],
    queryFn: async () => fetchSAMLProviderGroupMappings({ clientSamlProviderId }),
  });
}

export async function createSAMLProviderGroupMapping({ clientSamlProviderId, samlGroup, instanceId, groupId }) {
  return axios.post(`/api/client/saml/provider/group`, { clientSamlProviderId, samlGroup, instanceId, groupId }).then(() => {
    queryClient.invalidateQueries({ queryKey: ["client-saml-provider-groups"] });
    store.dispatch(newToast({ title: t(`New SAML Group Mapping created successfully`), variant: "success" }));
  });
}
export function useCreateSAMLProviderGroupMappingMutation() {
  return useMutation({
    mutationKey: ["create-saml-provider-group-mapping"],
    mutationFn: ({ clientSamlProviderId, samlGroup, instanceId, groupId }) =>
      createSAMLProviderGroupMapping({ clientSamlProviderId, samlGroup, instanceId, groupId }),
  });
}

export async function createSAMLProviderRoleMapping({ clientSamlProviderId, samlGroup, roleId, onSuccess }) {
  return axios.post(`/api/client/saml/provider/role`, { clientSamlProviderId, samlGroup, roleId }).then(() => {
    queryClient.invalidateQueries({ queryKey: ["client-saml-provider-roles"] });
    store.dispatch(newToast({ title: t(`New SAML Group Mapping created successfully`), variant: "success" }));
    if (onSuccess) onSuccess();
  });
}
export function useCreateSAMLProviderRoleMappingMutation() {
  return useMutation({
    mutationKey: ["create-saml-provider-role-mapping"],
    mutationFn: ({ clientSamlProviderId, samlGroup, roleId, onSuccess }) =>
      createSAMLProviderRoleMapping({ clientSamlProviderId, samlGroup, roleId, onSuccess }),
  });
}

export async function deleteSAMLProviderGroupMapping({ clientSamlProviderId, samlGroup, instanceId, groupId }) {
  return axios.delete(`/api/client/saml/provider/group`, { data: { clientSamlProviderId, samlGroup, instanceId, groupId } }).then(() => {
    queryClient.invalidateQueries({ queryKey: ["client-saml-provider-groups"] });
    store.dispatch(newToast({ title: t(`SAML Group Mapping deleted successfully`), variant: "success" }));
  });
}
export function useDeleteSAMLProviderGroupMappingMutation() {
  return useMutation({
    mutationKey: ["delete-saml-provider-group-mapping"],
    mutationFn: ({ clientSamlProviderId, samlGroup, instanceId, groupId }) =>
      deleteSAMLProviderGroupMapping({ clientSamlProviderId, samlGroup, instanceId, groupId }),
  });
}

export async function deleteSAMLProviderRoleMapping({ clientSamlProviderId, samlGroup, roleId }) {
  return axios.delete(`/api/client/saml/provider/role`, { data: { clientSamlProviderId, samlGroup, roleId } }).then(() => {
    queryClient.invalidateQueries({ queryKey: ["client-saml-provider-roles"] });
    store.dispatch(newToast({ title: t(`SAML Group Mapping deleted successfully`), variant: "success" }));
  });
}
export function useDeleteSAMLProviderRoleMappingMutation() {
  return useMutation({
    mutationKey: ["delete-saml-provider-role-mapping"],
    mutationFn: ({ clientSamlProviderId, samlGroup, roleId }) => deleteSAMLProviderRoleMapping({ clientSamlProviderId, samlGroup, roleId }),
  });
}

export async function fetchSAMLProviderRoleMappings({ clientSamlProviderId }) {
  return axios.get(`/api/client/saml/provider/roles`, { params: { clientSamlProviderId } }).then((res) => res.data.samlUserRoleMappings);
}
export function useSAMLProviderRoleMappings({ clientSamlProviderId }) {
  return useQuery({
    enabled: !!clientSamlProviderId,
    queryKey: ["client-saml-provider-roles", { clientSamlProviderId }],
    queryFn: async () => fetchSAMLProviderRoleMappings({ clientSamlProviderId }),
  });
}

// NEW SAML Provider API ENDPOINTS

export async function fetchClientSAMLProviderAccessMethods() {
  return axios.get(`/api/client/saml/provider/access-methods`, { params: {} });
}
export function useClientSAMLProviderAccessMethods() {
  return useQuery({
    queryKey: ["client-saml-provider-access-methods"],
    queryFn: async () => fetchClientSAMLProviderAccessMethods(),
  });
}

export async function createClientSAMLProviderAccessMethod() {
  return axios.post(`/api/client/saml/provider/access-methods`, {});
}
export function useCreateClientSAMLProviderAccessMethodMutation() {
  return useMutation({
    mutationKey: ["create-saml-provider-access-method"],
    mutationFn: () => createClientSAMLProviderAccessMethod(),
  });
}

export async function fetchClientSAMLProviderProfiles({ clientSamlProviderId }) {
  return axios.get(`/api/client/saml/provider/profiles`, { params: { clientSamlProviderId } }).then((res) => res.data.profiles);
}
export function useClientSAMLProviderProfiles({ clientSamlProviderId }) {
  return useQuery({
    queryKey: ["client-saml-provider-profiles", { clientSamlProviderId }],
    queryFn: async () => fetchClientSAMLProviderProfiles({ clientSamlProviderId }),
  });
}

export async function createClientSAMLProviderProfile({ clientSamlProviderId, group }) {
  return axios.post(`/api/client/saml/provider/profile`, { clientSamlProviderId, group }).then((res) => {
    queryClient.invalidateQueries({ queryKey: ["client-saml-provider-profiles"] });
    return res;
  });
}
export function useCreateClientSAMLProviderProfileMutation({ clientSamlProviderId }) {
  return useMutation({
    mutationKey: ["create-saml-provider-profile"],
    mutationFn: (group) => createClientSAMLProviderProfile({ clientSamlProviderId, group }),
  });
}

export async function deleteClientSAMLProviderProfile({ profileId }) {
  return axios.delete(`/api/client/saml/provider/profile`, { data: { profileId } }).then(() => {
    queryClient.invalidateQueries({ queryKey: ["client-saml-provider-profiles"] });
  });
}
export function useDeleteClientSAMLProviderProfileMutation({ profileId }) {
  return useMutation({
    mutationKey: ["delete-saml-provider-profile"],
    mutationFn: () => deleteClientSAMLProviderProfile({ profileId }),
  });
}

export async function fetchClientSAMLProviderRoleMappings({ profileId }) {
  return axios.get(`/api/client/saml/provider/role-mappings`, { params: { profileId } }).then((res) => res.data.roleMappings);
}
export function useClientSAMLProviderRoleMappings({ profileId }) {
  return useQuery({
    queryKey: ["client-saml-provider-role-mappings", { profileId }],
    queryFn: async () => fetchClientSAMLProviderRoleMappings({ profileId }),
    enabled: !!profileId,
  });
}

export async function editClientSAMLProviderRoleMappings({ profileId, add = [], remove = [] }) {
  return axios.patch(`/api/client/saml/provider/role-mapping`, { profileId, add, remove }).then(() => {
    queryClient.invalidateQueries({ queryKey: ["client-saml-provider-role-mappings"] });
  });
}
export function useEditClientSAMLProviderRoleMappingsMutation({ profileId }) {
  return useMutation({
    mutationKey: ["edit-saml-provider-role-mapping"],
    mutationFn: ({ profileId: _profileId, add, remove }) =>
      editClientSAMLProviderRoleMappings({ profileId: _profileId ?? profileId, add, remove }),
  });
}

export async function fetchClientSAMLProviderGroupMappings({ profileId }) {
  return axios.get(`/api/client/saml/provider/group-mappings`, { params: { profileId } }).then((res) => res.data.groupMappings);
}
export function useClientSAMLProviderGroupMappings({ profileId }) {
  return useQuery({
    queryKey: ["client-saml-provider-group-mappings", { profileId }],
    queryFn: async () => fetchClientSAMLProviderGroupMappings({ profileId }),
    enabled: !!profileId,
  });
}

export async function editClientSAMLProviderGroupMappings({ profileId, accessInstanceId, add = [], remove = [] }) {
  return axios.patch(`/api/client/saml/provider/group-mappings`, { profileId, accessInstanceId, add, remove }).then(() => {
    queryClient.invalidateQueries({ queryKey: ["client-saml-provider-group-mappings"] });
  });
}
export function useEditClientSAMLProviderGroupMappingsMutation({ profileId }) {
  return useMutation({
    mutationKey: ["edit-saml-provider-group-mappings"],
    mutationFn: ({ profileId: _profileId, accessInstanceId, add, remove }) =>
      editClientSAMLProviderGroupMappings({ profileId: _profileId ?? profileId, accessInstanceId, add, remove }),
  });
}

// SSO
export async function createSAMLProvider({
  clientId,
  usernameAttributeName,
  emailAttributeName,
  groupAttributeName,
  authenticationGroups,
  samlXmlMetadata,
  fqdn,
  alias,
  onSuccess,
}) {
  return axios
    .post(`api/client/saml/provider`, {
      clientId,
      usernameAttributeName,
      emailAttributeName,
      groupAttributeName,
      authenticationGroups,
      samlXmlMetadata,
      fqdn,
      alias,
    })
    .then((res) => {
      queryClient.invalidateQueries({ queryKey: ["client-saml-providers"] });
      store.dispatch(newToast({ title: t(`New SAML Provider set up successfully`), variant: "success" }));
      onSuccess(res.data);
    });
}
export function useCreateSAMLProviderMutation({ clientId, samlProviderConfig, onSuccess }) {
  return useMutation({
    mutationKey: ["create-saml-provider"],
    mutationFn: () => createSAMLProvider({ clientId, ...samlProviderConfig, onSuccess }),
  });
}

export async function editSAMLProvider({
  usernameAttributeName,
  emailAttributeName,
  groupAttributeName,
  authenticationGroups,
  samlXmlMetadata,
  clientSamlProviderId,
  fqdn,
  alias,
  onSuccess,
}) {
  return axios
    .put(`api/client/saml/provider`, {
      usernameAttributeName,
      emailAttributeName,
      groupAttributeName,
      authenticationGroups,
      samlXmlMetadata,
      clientSamlProviderId,
      fqdn,
      alias,
    })
    .then((res) => {
      queryClient.invalidateQueries({ queryKey: ["client-saml-providers"] });
      store.dispatch(newToast({ title: t(`SAML Provider Configuration updated successfully`), variant: "success" }));
      onSuccess(res.data);
    });
}
export function useEditSAMLProviderMutation({ id, samlProviderConfig, onSuccess }) {
  return useMutation({
    mutationKey: ["edit-saml-provider"],
    mutationFn: () => editSAMLProvider({ clientSamlProviderId: id, ...samlProviderConfig, onSuccess }),
  });
}

export async function deleteSAMLProvider({ clientSamlProviderId }) {
  return axios.delete(`api/client/saml/provider`, { data: { clientSamlProviderId } }).then(() => {
    queryClient.invalidateQueries({ queryKey: ["client-saml-providers"] });
    store.dispatch(newToast({ title: t(`SAML Provider deleted successfully`), variant: "success" }));
  });
}
export function useDeleteSAMLProviderMutation({ id, onSuccess }) {
  return useMutation({
    mutationKey: ["delete-saml-provider"],
    mutationFn: () => deleteSAMLProvider({ clientSamlProviderId: id }),
    onSuccess: onSuccess,
  });
}
