import React, { useState } from "react";
import { Button, Popup } from "@darktrace/ui-components";
import styled from "styled-components";
import { useUnlinkUserMutation } from "../../logic/api.js";

const StyledUnlinkUserPopup = styled(Popup)`
  .dt-ui-popup__contents {
    padding: 0;
  }

  .tooltip-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .linked-username {
      padding: 0.8rem 1.6rem;
    }

    .action-buttons {
      padding: 1.2rem 1.6rem;
      border-top: 1px solid var(--dt-ui-card-border);
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.8rem;

      > * {
        width: 100%;
      }
    }

    .bold {
      font-weight: 700;
    }
  }
`;

export function UnlinkUserButton({ instanceId, userId, linkedSabreUsername }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { mutate: unlinkUserMutation } = useUnlinkUserMutation({
    instanceId,
    userId,
    sabreUsername: linkedSabreUsername,
  });
  return (
    <StyledUnlinkUserPopup
      controlled
      open={isPopupOpen}
      onClickAway={() => setIsPopupOpen(false)}
      size="small"
      title={t(`Linked User`)}
      content={
        <div className="tooltip-content">
          <div className="linked-username">
            <span>{linkedSabreUsername}</span>
          </div>

          <div className="action-buttons">
            <Button size="small" variant="secondary" onClick={() => setIsPopupOpen(false)}>
              {t(`Cancel`)}
            </Button>

            <Button size="small" onClick={() => unlinkUserMutation()}>
              {t(`Unlink User`)}
            </Button>
          </div>
        </div>
      }
      placement={"right"}
    >
      <Button icon="link" size="small" variant="secondary" onClick={() => setIsPopupOpen((prev) => !prev)}>
        {t(`Linked User`)}
      </Button>
    </StyledUnlinkUserPopup>
  );
}
