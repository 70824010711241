import { Button, ClickAwayListener, DropdownDropper, IconButton } from "@darktrace/ui-components";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { newToast } from "../../logic/store.js";
import {
  resetUserMfa,
  resetMyMfa,
  renewUserRegistration,
  resetUserPassword,
  useClientSAMLProviders,
  useToggleUserEnabledMutation,
} from "../../logic/api.js";
import { EditUsersModal } from "./EditUsersModal.jsx";
import { AssignProductsModal } from "./AssignProductsModal.jsx";
import styled from "styled-components";
import { useActiveClientId } from "../../logic/hooks.js";
import { useNavigate } from "react-router-dom";
import { AssignRolesModal } from "./AssignRolesModal.jsx";

const StyledDropdownDropper = styled(DropdownDropper)`
  .disable-user,
  .disable-user * {
    color: var(--dt-ui-critical);
  }
`;

const StyledToastText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  .dt-ui-button {
    align-self: flex-end;
    color: var(--dt-ui-text-color);
    font-size: 1rem;
  }
`;

export function UserOptionsButton({
  name,
  email,
  id,
  clientId,
  roles,
  jwksEnabled,
  registrationStatus,
  availableRoles,
  isUserFromParentClient,
  isYou,
}) {
  const dispatch = useDispatch();
  const buttonRef = useRef();
  const dropperRef = useRef(null);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const activeClientId = useActiveClientId();

  const [showEditUsersModal, setShowEditUsersModal] = useState(false);
  const [showAssignProductsModal, setShowAssignProductsModal] = useState(false);
  const [showAssignRolesModal, setShowAssignRolesModal] = useState(false);

  const { mutate: toggleUserEnabledMutation, isPending } = useToggleUserEnabledMutation({ userId: id, email, jwksEnabled: !jwksEnabled });
  const { data } = useClientSAMLProviders({ clientId: activeClientId, enabled: true });
  const clientSamlProviders = data?.providers;

  function showWarningToast() {
    if (clientSamlProviders?.length) {
      dispatch(
        newToast({
          delay: 5000,
          variant: "info",
          title: t(`You are editing a Darktrace User`),
          text: (
            <StyledToastText>
              <span>{t(`To control the access that SAML users have, use SSO Configuration.`)}</span>
              <Button onClick={() => navigate("/sso-config")} icon="user-gear" variant="tertiary">
                View SSO Configuration
              </Button>
            </StyledToastText>
          ),
        }),
      );
    }
  }

  function handleSelectItem(selectedItemId, e) {
    e.stopPropagation();
    setIsDropdownOpen(false);

    switch (selectedItemId) {
      case "enable-user":
        toggleUserEnabledMutation();
        return;
      case "disable-user":
        toggleUserEnabledMutation();
        return;
      case "edit-user":
        setShowEditUsersModal(true);
        return;
      case "assign-roles-to-user":
        showWarningToast();
        setShowAssignRolesModal(true);
        return;
      case "assign-products-to-user":
        showWarningToast();
        setShowAssignProductsModal(true);
        return;
      case "renew-registration":
        renewUserRegistration({ userId: id });
        return;
      case "reset-password":
        resetUserPassword(id)
          .then(() => dispatch(newToast({ variant: "success", title: t(`Password reset email sent to {0}`, email) })))
          .catch(() => dispatch(newToast({ variant: "error", title: t(`Unable to send password reset link`) })));
        return;
      case "reset-mfa": {
        const resetMfa = isYou ? () => resetMyMfa() : () => resetUserMfa(id);

        resetMfa()
          .then(() => dispatch(newToast({ variant: "success", title: t(`MFA reset for {0}`, email) })))
          .catch(() => dispatch(newToast({ variant: "error", title: t(`Unable to reset MFA`) })));
        return;
      }
    }
  }

  const items = [
    // enable user
    ...(isYou || jwksEnabled
      ? []
      : [{ id: "enable-user", icon: "check", className: "enable-user", label: t(`Enable User`), disabled: isPending }]),
    // edit user
    { id: "edit-user", icon: "user-pen", label: t(`Edit User Details`) },
    // assign roles to user
    { id: "assign-roles-to-user", icon: "hammer", label: t(`Assign ActiveAI Portal Roles to User`) },
    // assign products to user
    { id: "assign-products-to-user", icon: "pen-to-square", label: t(`Assign Products to User`) },
    // resend invite email
    ...(["pending", "expired", "error"].includes(registrationStatus)
      ? [{ id: "renew-registration", icon: "repeat", label: t(`Resend Invite Email`) }]
      : []),
    // reset password
    ...(["completed", "synced", "error"].includes(registrationStatus)
      ? [{ id: "reset-password", icon: "key", label: t(`Send Password Reset Email`) }]
      : []),
    // reset mfa
    ...(["completed", "synced"].includes(registrationStatus) ? [{ id: "reset-mfa", icon: "rotate-right", label: t(`Reset MFA`) }] : []),
    // disable user
    ...(isYou || !jwksEnabled
      ? []
      : [{ id: "disable-user", icon: "ban", className: "disable-user", label: t(`Disable User`), disabled: isPending }]),
  ];

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
        <div>
          <IconButton ref={buttonRef} variant="secondary" icon="ellipsis-vertical" onClick={() => setIsDropdownOpen((prev) => !prev)} />

          <StyledDropdownDropper
            ref={dropperRef}
            open={isDropdownOpen}
            anchorEl={buttonRef.current}
            title={name}
            items={items}
            gap={4}
            dropdownArrow
            placement="left"
            onSelect={handleSelectItem}
          />
        </div>
      </ClickAwayListener>

      <EditUsersModal
        open={showEditUsersModal}
        users={[{ name, email, id, roles }]}
        availableRoles={availableRoles}
        onClose={() => setShowEditUsersModal(false)}
        isYou={isYou}
        isUserFromParentClient={isUserFromParentClient}
      />

      <AssignProductsModal
        open={showAssignProductsModal}
        user={{ name, email, id, clientId }}
        onClose={() => setShowAssignProductsModal(false)}
        isYou={isYou}
        isUserFromParentClient={isUserFromParentClient}
      />

      <AssignRolesModal
        open={showAssignRolesModal}
        user={{ name, email, id, clientId }}
        onClose={() => setShowAssignRolesModal(false)}
        isYou={isYou}
      />
    </>
  );
}
