import React, { useEffect, useState } from "react";
import { Tabs, Card } from "@darktrace/ui-components";
import styled from "styled-components";
import { OverviewTab } from "./Sidebar/OverviewTab.jsx";
import { PTNAlerts } from "./PTNAlerts/PTNAlerts.jsx";

const StyledSOCDashboard = styled.div`
  display: flex;
  gap: 1.2rem;
  height: 100%;
  .dt-ui-tabs-wrapper {
    flex-shrink: 0;
  }
  #right {
    width: 100%;
    .dt-ui-card__contents:has(#ptn-alerts) {
      padding: 0;
    }
  }
`;

const contentTabs = {
  overview: [
    { id: "ptn-alerts", icon: "warning", title: "PTN Alerts" },
    { id: "tickets", icon: "ticket", title: "Tickets" },
  ],
  settings: [{ id: "option1", title: "Option" }],
};

export function SOCDashboard() {
  const [activeTabIdSide, setActiveTabIdSide] = useState("overview");
  const [activeTabIdContent, setActiveTabIdContent] = useState();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  useEffect(() => {
    if (activeTabIdSide === "overview") setActiveTabIdContent("ptn-alerts");
    if (activeTabIdSide === "settings") setActiveTabIdContent("option1");
  }, [activeTabIdSide]);

  return (
    <StyledSOCDashboard>
      <Tabs
        tabs={[
          {
            id: "overview",
            tooltip: "SOC Overview",
            icon: "radar",
            contentTitle: "SOC Overview",
            content: <OverviewTab />,
          },
          { id: "settings", tooltip: "Admin Settings", icon: "cog", contentTitle: "Admin Settings" },
        ]}
        title={t(`SOC Overview`)}
        width="40rem"
        controlled
        activeTabId={activeTabIdSide}
        onSelectTab={(id) => setActiveTabIdSide(id)}
        collapsable
        onClickCollapsedToggle={() => setSidebarCollapsed((prev) => !prev)}
        collapsed={sidebarCollapsed}
      ></Tabs>
      <Card
        id="right"
        title={
          <Tabs
            className="tabs-section"
            controlled
            activeTabId={activeTabIdContent}
            onSelectTab={(id) => setActiveTabIdContent(id)}
            tabs={contentTabs[activeTabIdSide]}
          />
        }
      >
        {activeTabIdContent === "ptn-alerts" && <PTNAlerts />}
        {activeTabIdContent === "tickets" && <div>tickets</div>}
        {activeTabIdContent === "option1" && <div>Pending Designs</div>}
      </Card>
    </StyledSOCDashboard>
  );
}
