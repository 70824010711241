import React, { useEffect, useState } from "react";
import { ButtonGroup, Card, Dropdown, ExpandableCard, Tile, Tooltip } from "@darktrace/ui-components";
import styled from "styled-components";
import dayjs from "dayjs";
import { alertStatus } from "../utils";
import { usePTNAlerts } from "../../../logic/api";
import { useSelector } from "react-redux";
import { useActiveClientId, useQueryParams } from "../../../logic/hooks";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const StyledOverviewTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  height: 100%;
  .time-bar {
    height: 2.8rem;
    .dt-ui-tile__header-text {
      align-items: center;
      display: flex;
      gap: 0.8rem;
    }
  }
  > .dt-ui-card:last-of-type {
    flex-grow: 1;
  }
`;

const StyledTile = styled(Tile)`
  height: 3.6rem;
  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
  .dt-ui-tile__header-right {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    .count {
      background-color: var(--dt-ui-header-icon-color);
      padding: 0.4rem 0.8rem;
      border-radius: 0.4rem;
      i {
        color: var(--color);
        padding-right: 0.4rem;
      }
    }
  }
`;

export function OverviewTab() {
  const activeClientId = useActiveClientId();
  const _clients = useSelector((state) => state.app.clients);
  const clients = _clients.map((client) => ({ id: client.id, label: client.label, selected: client.id === activeClientId }));
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const defaultClientLabel = _clients.find((client) => client.id === defaultClientId)?.label;
  const { updateQueryParams } = useQueryParams({ defaultQueryParams: { clientId: defaultClientId } });

  const { data: alerts } = usePTNAlerts({ clientId: defaultClientId });
  const [PTNAlertsView, setPTNAlertsView] = useState("Status");
  const [ticketView, setTicketView] = useState("Status");
  const items = {
    Status: ["Not Assigned", "In Progress"],
    Type: ["SOC", "Trial", "POV"],
  };
  const [PTNItems, setPNTItems] = useState(items[PTNAlertsView]);
  const [ticketItems, setTicketItems] = useState(items[ticketView]);
  const [alertsInfo, setAlertsInfo] = useState({});

  const handleBtnGroupSelect = (btnId, e, setter, itemSetter) => {
    e.stopPropagation();
    setter(btnId);
    itemSetter(items[btnId]);
  };

  useEffect(() => {
    // we can make requests for this query param and get the total count which is returned
    if (!alerts?.pages) return;
    const inProgress = [];
    const notAssigned = [];
    alerts.pages.forEach((page) => {
      page.data.forEach((alert) => {
        if (alert.status === "inprogress") inProgress.push(alert);
        if (alert.status === "new") notAssigned.push(alert);
      });
    });
    setAlertsInfo({ inProgress, notAssigned });
  }, [alerts?.pages]);

  return (
    <StyledOverviewTab>
      <Card className="inner-card" title={t(`Tenant`)} icon={"buildings"}>
        <div className="tenant-card-contents">
          <Dropdown displayValue={defaultClientLabel} disabled />
          {clients.length > 1 && (
            <Dropdown label={`${t(`View as: `)}:`} items={clients} onSelect={(id) => updateQueryParams({ clientId: id })} hasSearch />
          )}
        </div>
      </Card>
      <Tile
        className="time-bar"
        noColorBar
        header={
          <>
            <i className="fas fa-calendar-days"></i>
            {dayjs().utc().format("DD-MM-YYYY")}
            <i className="fas fa-clock"></i>
            {dayjs().utc().format("HH:mm:ss")} (UTC)
          </>
        }
      />
      <ExpandableCard
        expanded
        icon="warning far"
        title="PTN Alerts"
        titleRight={
          <ButtonGroup
            activeButtonId={PTNAlertsView}
            onClick={(btnId, e) => handleBtnGroupSelect(btnId, e, setPTNAlertsView, setPNTItems)}
            buttons={["Status", "Type"].map((x) => ({ id: x, text: x }))}
          />
        }
      >
        {PTNItems.map((item) => {
          return (
            <StyledTile
              noColorBar
              key={item}
              header={item}
              headerRight={
                <>
                  {(item === "Not Assigned" || PTNAlertsView === "Type") && alertsInfo.notAssigned ? (
                    <Tooltip text="Not Assigned">
                      <div className="count" style={{ "--color": alertStatus.new.color }}>
                        <i className="fas fa-warning"></i>
                        {/* TODO: Add additional layer of filter for type is ptnview is === 'Type' */}
                        <span>{alertsInfo.notAssigned.length || 0}</span>
                      </div>
                    </Tooltip>
                  ) : null}
                  {(item === "In Progress" || PTNAlertsView === "Type") && alertsInfo.inProgress ? (
                    <Tooltip text="In Progress">
                      <div className="count" style={{ "--color": alertStatus.inprogress.color }}>
                        <i className="fas fa-warning"></i>
                        <span>{alertsInfo.inProgress.length || 0}</span>
                      </div>
                    </Tooltip>
                  ) : null}
                  <i className="fas fa-arrow-right"></i>
                </>
              }
            />
          );
        })}
      </ExpandableCard>
      <ExpandableCard
        expanded
        title="Tickets"
        icon="ticket far"
        titleRight={
          <ButtonGroup
            activeButtonId={ticketView}
            onClick={(btnId, e) => handleBtnGroupSelect(btnId, e, setTicketView, setTicketItems)}
            buttons={["Status", "Type"].map((x) => ({ id: x, text: x }))}
          />
        }
      >
        {ticketItems.map((item) => {
          return (
            <StyledTile
              noColorBar
              key={item}
              header={item}
              headerRight={
                <>
                  <i className="fas fa-arrow-right"></i>
                </>
              }
            />
          );
        })}
      </ExpandableCard>
      <Card
        title="SOC Analysts"
        icon="user-shield far"
        titleRight={<ButtonGroup buttons={["Online Now", "Next"].map((x) => ({ id: x, text: x }))} />}
      >
        {/* TODO */}
        Not yet implemented
      </Card>
    </StyledOverviewTab>
  );
}
