import { Button } from "@darktrace/ui-components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useClientSAMLProviderInfo, useCreateSAMLProviderMutation, useEditSAMLProviderMutation } from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { SAMLProviderConfiguration } from "./SAMLProviderConfiguration.jsx";

const StyledSAMLProvider = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .name {
    font-size: 2.4rem;
    font-weight: 700;
  }

  .action-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1.2rem;
  }
`;

export function SAMLProvider({
  id,
  isNew = false,
  isEditing = false,
  isDeletingSAMLProvider,
  onClose = () => {},
  setSelectedSamlProviderId,
}) {
  const activeClientId = useActiveClientId();

  // queries
  const { data: samlProviderConfig = {} } = useClientSAMLProviderInfo({ clientId: activeClientId, clientSamlProviderId: id });

  // saved data
  const usernameAttributeName = samlProviderConfig.usernameAttributeName;
  const emailAttributeName = samlProviderConfig.emailAttributeName;
  const groupAttributeName = samlProviderConfig.groupAttributeName;
  const authenticationGroups = samlProviderConfig.authenticationGroups;
  const samlXmlMetadata = samlProviderConfig.samlXmlMetadata;
  const fqdn = samlProviderConfig.fqdn;
  const alias = samlProviderConfig.alias;

  // local data
  const [localUsernameAttributeName, setLocalUsernameAttributeName] = useState();
  const [localEmailAttributeName, setLocalEmailAttributeName] = useState();
  const [localGroupAttributeName, setLocalGroupAttributeName] = useState();
  const [localAuthenticationGroups, setLocalAuthenticationGroups] = useState();
  const [localSamlXmlMetadata, setLocalSamlXmlMetadata] = useState();
  const [localFqdn, setLocalFqdn] = useState();
  const [localAlias, setLocalAlias] = useState();

  function resetFields() {
    setLocalUsernameAttributeName(usernameAttributeName);
    setLocalEmailAttributeName(emailAttributeName);
    setLocalGroupAttributeName(groupAttributeName);
    setLocalAuthenticationGroups(authenticationGroups);
    setLocalSamlXmlMetadata(samlXmlMetadata);
    setLocalFqdn(fqdn);
    setLocalAlias(alias);
  }

  function handleClose() {
    onClose();
    resetFields();
  }

  useEffect(() => {
    resetFields();
  }, [JSON.stringify(samlProviderConfig)]);

  const localSamlProviderConfig = {
    usernameAttributeName: localUsernameAttributeName,
    emailAttributeName: localEmailAttributeName,
    groupAttributeName: localGroupAttributeName,
    authenticationGroups: localAuthenticationGroups,
    samlXmlMetadata: localSamlXmlMetadata,
    fqdn: localFqdn,
    alias: localAlias,
  };

  const hasChanges =
    usernameAttributeName !== localUsernameAttributeName ||
    emailAttributeName !== localEmailAttributeName ||
    groupAttributeName !== localGroupAttributeName ||
    authenticationGroups !== localAuthenticationGroups ||
    samlXmlMetadata !== localSamlXmlMetadata ||
    fqdn !== localFqdn ||
    alias !== localAlias;

  function onChangeField(fieldName, value) {
    if (fieldName === "usernameAttributeName") setLocalUsernameAttributeName(value);
    if (fieldName === "emailAttributeName") setLocalEmailAttributeName(value);
    if (fieldName === "groupAttributeName") setLocalGroupAttributeName(value);
    if (fieldName === "authenticationGroups") setLocalAuthenticationGroups(value);
    if (fieldName === "samlXmlMetadata") setLocalSamlXmlMetadata(value);
    if (fieldName === "fqdn") setLocalFqdn(value);
    if (fieldName === "alias") setLocalAlias(value);
  }

  // mutations
  const { mutate: createSAMLProviderMutation = [], isPending: isCreatingSAMLProvider } = useCreateSAMLProviderMutation({
    clientId: activeClientId,
    samlProviderConfig: localSamlProviderConfig,
    onSuccess: (res) => {
      onClose();
      setSelectedSamlProviderId(res.id);
    },
  });

  const { mutate: editSAMLProviderMutation = [], isPending: isEditingSAMLProvider } = useEditSAMLProviderMutation({
    id,
    samlProviderConfig: localSamlProviderConfig,
    onSuccess: onClose,
  });

  const disabled = isCreatingSAMLProvider || isEditingSAMLProvider || isDeletingSAMLProvider || !hasChanges;

  return (
    <StyledSAMLProvider>
      <SAMLProviderConfiguration
        clientSamlProviderId={id}
        isNew={isNew}
        isEditing={isEditing}
        samlProviderConfig={localSamlProviderConfig}
        onChangeField={onChangeField}
      />

      {isNew ? (
        <div className="action-buttons">
          <Button icon="times" variant="secondary" className="remove-btn" onClick={handleClose}>
            {t(`Cancel`)}
          </Button>
          <Button icon="plus" disabled={disabled} onClick={() => createSAMLProviderMutation()}>
            {t(`Add SAML Provider`)}
          </Button>
        </div>
      ) : isEditing ? (
        <div className="action-buttons">
          <Button icon="times" variant="secondary" className="remove-btn" onClick={handleClose}>
            {t(`Cancel`)}
          </Button>
          <Button icon="save" disabled={disabled} onClick={() => editSAMLProviderMutation()}>
            {t(`Save`)}
          </Button>
        </div>
      ) : null}
    </StyledSAMLProvider>
  );
}
